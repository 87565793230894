import React, { useState, useEffect, useRef } from 'react'
import { Table, Icon, Tooltip, Modal, notification, Select, Popconfirm } from 'antd'
import _ from 'lodash'
import { useDispatch, connect } from 'react-redux'
import { addCollaborationAction, travelPlanListFxn, updateCollaborationAction } from './actions'
import moment from 'moment'
import { filterOption, statusColors } from '../../components/_utils/appUtils'
import TextArea from 'antd/es/input/TextArea'
import { push } from 'connected-react-router'
import InputBox from '../../components/_utils/InputBox'
import EditTravelPlanDrawer from './editTravelPlanDrawer'
import { CheckUserRight } from '../dashboard/views/DashboardUserWise'

const dateFormat = 'DD/MM/YYYY'
const { Option } = Select

const AllTravelPlanList = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const tableRef = useRef()
  const dispatch = useDispatch()
  const [total, setTotal] = useState(0)
  const [pageNo, setPage] = useState(1)
  const [travelPlanData, setTravelPlanData] = useState([])
  const [dateArray, setDateArray] = useState([])
  const [startDate, setStartDate] = useState(moment())
  const [columns, setColumns] = useState([])
  const [collaborationModal, setAddCollaborationModal] = useState({
    visible: false,
    id: '',
    userId: '',
    travelPlanData: ''
  })
  const [userData, setUserData] = useState([])
  const initialState = {
    visible: false,
    travelPlanId: '',
    user: '',
    status: ''
  }
  const initialTravelPlanState = {
    visible: false,
    travelPlanId: '',
    record: ''
  }
  const [detailsModal, setDetailsModal] = useState(initialState)
  const [cancelModal, setCancelModal] = useState(initialState)
  const [details, setDetails] = useState('')
  const [reason, setReason] = useState('')
  const [requestStatus, setRequestStatus] = useState('all')
  const [filteredData, setFilteredData] = useState('')
  const [editTravelPlanDrawer, setEditTravelPlanModal] = useState(initialTravelPlanState)

  const showEditTravelPlanDrawer = (record) => {
    setEditTravelPlanModal({
      visible: true,
      travelPlanId: record._id,
      record: record
    })
  }
  const hideEditTravelPlanDrawer = () => {
    setEditTravelPlanModal({
      visible: false,
      travelPlanId: '',
      record: ''
    })
    apiRequest()
  }
  useEffect(() => {
    if (requestStatus === null || requestStatus == 'all') {
      setFilteredData(userData)
    } else {
      const newData = userData.filter(item => item.status == requestStatus)
      setFilteredData(newData)
    }
  }, [requestStatus, userData])

  const collaborationComponent = (data, statusCounts) => {
    return (
     data.status == 'working' ?
      <div>
        <div className={'close-circle-icon1'}>
          {
            user._id != data.userId._id ?
              <Tooltip title='Collaborating Requests'>
                <Popconfirm
                  title={'Are your sure, you want to Collaborate with this user?'}
                  onConfirm={() => {
                    addCollaborationFxn(data._id)
                  }}
                  okText='Yes'
                  cancelText='No'>
                  <a className='close-circle-icon1'>
                    <Icon type='plus-circle' theme='filled' />
                  </a>
                </Popconfirm>
              </Tooltip> : null
          }
          {
            user._id == data.userId._id ?
              <Tooltip title='Edit Travel Plan'>
                <a className='close-circle-icon1'>
                  <Icon
                    type='edit'
                    theme='filled'
                    onClick={() => {
                      showEditTravelPlanDrawer(data)
                    }}
                  />
                </a>
              </Tooltip> : null
          }
        </div>
        <div className={'mt5'}>
          <b>From: <br /></b>
          {data.fromPlace ? `${data.fromPlace}, ` : ''}{data.city}, {data.state}
        </div>
        <div className={'mt5'}>
          {data.stops.length > 0 && <b>In Between Stops:<br /></b>}

          {data.stops.length > 0 && data.stops.map((stop, index) => (
            <span key={index}>
          {stop}{index !== data.stops.length - 1 ? ', ' : ''}
          </span>
          ))}
        </div>
        <div className={'mt5'}>
          <b>To: <br /></b>
          {data.toPlace ? `${data.toPlace}, ` : ''}{data.toCity}, {data.toState}
        </div>
        <div>
          <b>Collaborations:</b>
          <div className={'mb5'}>
            {data.users.filter(user => user.status == 'approved')
              .map((user, index) => (
                <div key={index} className={'btn btn-primary roundNew xs mr5'}>{user.user.name}</div>
              ))}
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div className={'alignCenter mb-2'}
               style={{
                 backgroundColor: 'lightgrey',
                 fontWeight: 'bold',
                 fontSize: '15px',
                 height: '25px',
                 width: '100%'
               }}>
            Collaboration
          </div>
          <div className={'jcic'}>
            <div className={'jcic'}>
              <div
                onClick={() => {
                  setAddCollaborationModal({ visible: true, id: data._id, userId: data.userId, travelPlanData: data })
                  setUserData(data.users)
                  setRequestStatus('approved')
                }}
                className={'d-flex'}>
                <label className={'label labelNew label-success-green collaborationsBtn'}>
                  Approved - {statusCounts.approved}
                </label>
              </div>
              <div
                onClick={() => {
                  setAddCollaborationModal({ visible: true, id: data._id, userId: data.userId, travelPlanData: data })
                  setUserData(data.users)
                  setRequestStatus('pending')
                }}
                className={'d-flex'}>
                <label
                  className={'label labelNew label-warning collaborationsBtn ml-2'}>
                  Pending - {statusCounts.pending}
                </label>
              </div>
              <div
                onClick={() => {
                  setAddCollaborationModal({ visible: true, id: data._id, userId: data.userId, travelPlanData: data })
                  setUserData(data.users)
                  setRequestStatus('rejected')
                }}
                className={'d-flex'}>
                <label
                  className={'label labelNew label-danger collaborationsBtn ml-2'}>
                  Rejected - {statusCounts.rejected}
                </label>
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {/*<div
                onClick={() => {
                  setAddCollaborationModal({ visible: true, id: data._id, userId: data.userId, travelPlanData: data })
                  setUserData(data.users)
                  setRequestStatus('all')
                }}
                className={'d-flex'}>
                <div
                  className={'label label1 collaborationsBtn ml-2'}
                  style={{
                    background: statusColors('approved')
                  }}>
                  All
                </div>
                <div className={'label label1 collaborationsBtn'}
                     style={{
                       background: statusColors('count'),
                       width: '40px'
                     }}>3
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div> :
    <div>
      {user.userType == 'marketingManager' || user.userType == 'admin' || data.userId._id == user._id ?
        <>
          <div className={'mt5'}>
            <b>Status: </b>
            {data.status && data.status == 'skip' ? 'Skip' : ''}
          </div>
          <br/>
          <div>
            <b>Reason: </b>
            {data.reason ? data.reason : ''}
          </div>
        </> :  null
      }
    </div>
    )
  }

  const addCollaborationFxn = async (id) => {
    let resp = await dispatch(addCollaborationAction({ travelPlanId: id }))
    if (resp && resp.success) {
      apiRequest()
    }
  }

  const getStatusCounts = (users) => {
    const counts = { approved: 0, pending: 0, rejected: 0 }

    users.forEach(user => {
      if (user.status === 'approved') {
        counts.approved++
      } else if (user.status === 'pending') {
        counts.pending++
      } else if (user.status === 'rejected') {
        counts.rejected++
      }
    })

    return counts
  }

  useEffect(() => {
    const generateColumns = () => {
      const baseColumns = [
        {
          title: '#',
          dataIndex: '#',
          key: '#',
          width: 50,
          render: (item, record, index) => {
            return index + 1
          }
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          searchTextName: 'name',
          width: '16%',
          render: (val) => {
            return (
              <>
                {val}
              </>
            )
          }
        },
        {
          title: dateArray[0],
          dataIndex: 'plans',
          key: 'plans',
          width: '28%',
          render: (val) => {
            if (val) {
              const filteredData = Object.values(val).find(item => item.dateString == dateArray[0])
              if (filteredData) {
                const statusCounts = getStatusCounts(filteredData.users)
                return (
                  collaborationComponent(filteredData, statusCounts)
                )
              }
            }
            return null
          }
        },
        {
          title: dateArray[1],
          dataIndex: 'plans',
          key: 'plans',
          width: '28%',
          render: (val) => {
            if (val) {
              const filteredData = Object.values(val).find(item => item.dateString == dateArray[1])
              if (filteredData) {
                const statusCounts = getStatusCounts(filteredData.users)
                return (
                  collaborationComponent(filteredData, statusCounts)
                )
              }
            }
            return null
          }
        },
        {
          title: dateArray[2],
          dataIndex: 'plans',
          key: 'plans',
          width: '28%',
          render: (val) => {
            if (val) {
              const filteredData = Object.values(val).find(item => item.dateString == dateArray[2])
              if (filteredData) {
                const statusCounts = getStatusCounts(filteredData.users)
                return (
                  collaborationComponent(filteredData, statusCounts)
                )
              }
            }
            return null
          }
        }
      ]
      setColumns(baseColumns)
    }

    generateColumns()
  }, [dateArray])

  useEffect(() => {
    apiRequest()
  }, [pageNo])

  const apiRequest = async (params = {}) => {

    params = { ...params }
    let { fromDate, toDate, user: currentUser } = params
    if (fromDate) {
      let date = {}
      date.$gte = new Date(fromDate)
      date.$lte = new Date(toDate)
      params.date = date
      delete params.fromDate
      delete params.toDate
    }
    params.page = pageNo
    let data = await dispatch(travelPlanListFxn({ ...params }))
    setTotal(data.total)
    setTravelPlanData(data.data)
    setDateArray(data.dateArr)
  }


  const handleDateShift = (days, page) => {
    setStartDate(prevDate => moment(prevDate).add(days, 'days'))
    if (pageNo > 0) {
      setPage(pageNo + page)
    } else {
      setPage(1)
    }
  }


  const transformData = (data) => {
    const groupedData = _.groupBy(data, 'userId._id')
    return _.map(groupedData, (plans, userId) => {
      const userPlans = {
        name: `${plans[0].userId.name} (Total plans : ${plans.length})`,
        totalPlans: plans.length
      }
      plans.forEach(plan => {
        const planDate = moment(plan.date).format(dateFormat)
        if (planDate === moment(startDate).format(dateFormat)) {
          userPlans.dateToday = plan
        } else if (planDate === moment(startDate).add(1, 'days').format(dateFormat)) {
          userPlans.dateTomorrow = plan
        } else if (planDate === moment(startDate).add(2, 'days').format(dateFormat)) {
          userPlans.dateDayAfter = plan
        }
      })
      return userPlans
    })
  }

  const columns2 = [

    {
      title: 'Users',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      render: item => {
        return item ? item.name : ''
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            <div style={{ width: 150 }}>
              <div
                className={'label label1'}
                style={{
                  background: statusColors(item),
                  width: 80,
                  padding: 3
                }}>
                {item &&
                item == 'approved' ? 'Approved' :
                  item == 'pending' ? 'Pending' :
                    item == 'rejected' ? 'Rejected' :
                      null}
              </div>
            </div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 80,
      render: (text, record) => {
        return (
          <React.Fragment>
            {(record.status == 'pending' && collaborationModal.userId._id == user._id) &&
            <>
              <Tooltip title='Approve Collaboration'>
                <button className='btn'
                        onClick={() => {
                          setDetailsModal({
                            visible: true,
                            travelPlanId: collaborationModal.id,
                            user: record.user._id,
                            status: 'approved'
                          })
                        }}
                >
                  <Icon
                    type='check-circle'
                    theme='filled'
                    style={{ color: '#048204', fontSize: '20px', fontWeight: '10px' }}
                  />
                </button>
              </Tooltip>

              <Tooltip title='Reject Collaboration'>
                <button className='btn'
                        onClick={() => {
                          setCancelModal({
                            visible: true,
                            travelPlanId: collaborationModal.id,
                            user: record.user._id,
                            status: 'rejected'
                          })
                        }}>
                  <Icon
                    type='close-circle'
                    theme='filled'
                    style={{ color: '#b80909', fontSize: '20px', fontWeight: '10px' }}
                  />
                </button>
              </Tooltip>
            </>

            }
          </React.Fragment>
        )
      }
    }
  ]

  const approveCollaborationFxn = async (travelPlanId, userId, status) => {
    const valaData = {
      travelPlanId: travelPlanId,
      user: userId,
      status: status
    }
    if (!details) {
      notification.warning({
        message: 'Please Enter Details'
      })
      return
    }
    if (details) {
      valaData.details = details
    }
    const x = await dispatch(updateCollaborationAction(valaData))
    if (x.success) {
      setAddCollaborationModal({
        visible: false,
        id: ''
      })
      setCancelModal(initialState)
      setDetailsModal(initialState)
      apiRequest()
    }
  }

  const rejectCollaborationFxn = async (travelPlanId, userId, status) => {
    const valaData = {
      travelPlanId: travelPlanId,
      user: userId,
      status: status
    }
    if (!reason) {
      notification.warning({
        message: 'Please Enter Reason'
      })
      return
    }
    if (reason) {
      valaData.reason = reason
    }
    const x = await dispatch(updateCollaborationAction(valaData))
    if (x.success) {
      setAddCollaborationModal({
        visible: false,
        id: ''
      })
      setCancelModal(initialState)
      setDetailsModal(initialState)
      apiRequest()
    }
  }


  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>Travel Plan</h5>
            <div className='search-box-table round' />
            <div className='sort-box-table mark-btn'>
              <CheckUserRight user={props.currentUser} rightUserType={['admin', 'branchUser', 'gicMarketing']}>
                <a className='btn' onClick={() => dispatch(push('/travelPlan/add-travel-plan'))}>
                  <img src={'../dist/icons/plus.png'} className={'plus'} /> Add Travel Plan</a>
              </CheckUserRight>
            </div>
          </div>
          <div className='card-body table-responsive'>
            <div className={'d-flex justify-content-end'}>
              {pageNo !== 1 &&
              <div className={'close-circle-icon1 mr-1 mb-2'}>
                <Icon onClick={() => handleDateShift(-3, -1)} type='left-circle' theme='filled' />
              </div>
              }
              <div className={'close-circle-icon1'}>
                <Icon style={{ border: 'none' }} onClick={() => handleDateShift(3, 1)} type='right-circle'
                      theme='filled' />
              </div>
            </div>
            <div className={'travel-plan-table'}>
              <Table
                columns={columns}
                dataSource={travelPlanData}
                pagination={false}
                bordered={true}
              />
            </div>
          </div>
          {
            collaborationModal.visible ? (
              <Modal
                width={'60%'}
                title='Collaboration Requests'
                visible={collaborationModal.visible}
                onCancel={() => {
                  setAddCollaborationModal({ ...collaborationModal, visible: false })
                }}
              >
                <div className='cornerstone-card-body cornerstone-student-data mb10'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <strong>Name : </strong>
                      <span>{collaborationModal.travelPlanData.userId.name}</span>
                    </div>
                    <div className='col-md-6'>
                      <strong>Date : </strong>
                      <span>{collaborationModal.travelPlanData.dateString}</span>
                    </div>

                  </div>
                  <hr />
                  <div className='row'>
                    <div className='col-md-6'>
                      <strong>From : </strong>
                      <span>
                        {collaborationModal.travelPlanData.fromPlace ? `${collaborationModal.travelPlanData.fromPlace}, ` : ''}
                        {collaborationModal.travelPlanData.city},
                        {collaborationModal.travelPlanData.state}
                      </span>
                    </div>
                    <div className='col-md-6'>
                      <strong>To : </strong>
                      <span>
                        {collaborationModal.travelPlanData.toPlace ? `${collaborationModal.travelPlanData.toPlace}, ` : null}
                        {collaborationModal.travelPlanData.toCity},
                        {collaborationModal.travelPlanData.toState}
                      </span>
                    </div>
                  </div>
                </div>
                <InputBox title={' Choose Status'} className={'col-md-4'}>
                  <Select
                    name='SearchByMarketingUser'
                    filterOption={filterOption}
                    allowClear={true}
                    placeholder=' Choose Status'
                    onChange={(value) => setRequestStatus(value)}
                    value={requestStatus || undefined}
                  >
                    <Option value='pending'>Pending</Option>
                    <Option value='approved'>Approved</Option>
                    <Option value='rejected'>Rejected</Option>
                    <Option value='all'>All</Option>
                  </Select>
                </InputBox>

                <Table
                  columns={columns2}
                  size={'small'}
                  bordered
                  dataSource={requestStatus == 'all' ? userData : filteredData}
                  pagination={false}
                />

              </Modal>
            ) : null
          }
          {
            detailsModal.visible ? (
              <Modal
                title='Enter Detail'
                visible={detailsModal.visible}
                onOk={() => {
                  approveCollaborationFxn(detailsModal.travelPlanId, detailsModal.user, detailsModal.status)
                }}
                onCancel={() => {
                  setDetailsModal({ ...detailsModal, visible: false })
                }}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  placeholder='Enter Details..'
                  required={true}
                />
              </Modal>
            ) : null
          }
          {
            cancelModal.visible ? (
              <Modal
                title='Enter Reason'
                visible={cancelModal.visible}
                onOk={() => {
                  rejectCollaborationFxn(cancelModal.travelPlanId, cancelModal.user, cancelModal.status)
                }}
                onCancel={() => {
                  setCancelModal({ ...cancelModal, visible: false })
                }}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder='Enter reason for Cancel'
                  required={true}
                />
              </Modal>
            ) : null
          }
          {editTravelPlanDrawer.visible ?
            <EditTravelPlanDrawer
              {...editTravelPlanDrawer}
              onClose={() => {
                hideEditTravelPlanDrawer()
              }}
              onSubmit={() => {
                hideEditTravelPlanDrawer()
              }}
            />
            : null}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(AllTravelPlanList)
