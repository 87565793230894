import { push } from 'connected-react-router'
import _ from 'lodash'
import CheckoutComponent from '../../unizHome/checkout/checkout'
import PaymentResponse from '../../unizHome/checkout/paymentResponse'
// import CheckoutRoomComponent from '../../unizHome/checkout/checkoutRoom'
import Home from '../../unizHomeOld/home'
import PropertiesList from '../../unizHomeOld/propertiesList'
import RoomsList from '../../unizHome/roomList'
import SingleProperty from '../../unizHomeOld/singleProperty'
import SingleRoom from '../../unizHome/singleProperty/singleRoom'
import TrainingRequest from '../FrontendComponents/trainingRequest'
import PhoneValidationCheck from '../../unizHome/phoneValidationCheck'

const UnizHomeRoutesList = [
    { name: 'Home', path: '/unizhome', component: Home, key: 'home' },
    {
        name: 'Properties List',
        path: '/properties-list/:city',
        component: PropertiesList,
        key: 'property-list'
    },
    {
        name: 'Rooms List',
        path: '/rooms-list/:city',
        component: RoomsList,
        key: 'RoomsList'
    },
    {
        name: 'Student Accommodation',
        path: `/student-accommodation/:property_slug`,
        component: SingleProperty,
        key: 'single-property'
    },
    {
        name: 'Accommodation',
        path: `/accommodation/:room_slug`,
        component: SingleRoom,
        key: 'single-property'
    },
    {
        name: 'TrainingRequest',
        path: '/trainingRequest/:agentId',
        component: TrainingRequest,
        key: 'trainingRequest'
    },
    {
        name: 'Checkout',
        path: '/checkout/:session_token',
        component: CheckoutComponent,
        key: 'checkout'
    },
    /*{
      name: 'CheckoutRoom',
      path: '/checkoutRoom/:room_slug',
      component: CheckoutRoomComponent,
      key: 'checkoutRoom'
    },*/

    {
        name: 'Payment',
        path: '/pay/:id',
        component: PaymentResponse,
        key: 'paymentResponse'
    },

    {
        name: 'Phone Validation Check',
        path: '/phoneValidation',
        component: PhoneValidationCheck,
        key: 'phoneValidationCheck'
    }
]
export const getUrlPushWrapperHome = (keyString, query) => {
    return push(getUrlPathHome(keyString, query))
}
export const getUrlPathHome = (keyString, params) => {
    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(UnizHomeRoutesList, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&')

    return `${val.path}?${queryString}`
}
export default UnizHomeRoutesList
