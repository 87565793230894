import React, {useState, useEffect} from 'react'
import {Select, Modal} from 'antd'
import {
    BroadcastingFxn,
    checkBroadcastingStatusFxn,
} from '../../users/actions/user'
import {useDispatch} from 'react-redux'
import {addTrainingRequest} from "./actions";
import { countryIds } from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'

const {confirm} = Modal;
const {Option} = Select;

const fields = {
    name: '',
    country: '',
    university: '',
    shareYourExperience: '',
    rating: null
}
const countryList = [
    'Canada',
    'United States',
    'Australia',
    'United Kingdom'
]
const TrainingRequestComponent = (props) => {
    const dispatch = useDispatch()
    let [message, setMessage] = useState('')
    let [isModalVisible, setIsModalVisible] = useState(false)
    let [countryId, setCountryId] = useState([])
    let [allCountry, setAllCountry] = useState([])
    const loadCountry = async () => {
        let filters = {
            results: 100,
            count: 100,
            }
        let { data } = await dispatch(listAllCountries(filters))
        setAllCountry(data)
    }

    useEffect(() => {
        loadCountry()
        // checkFeedbackExists()
    }, [])


    const submitHandleFxn = async () => {
        let {pathname} = window.location
        let agentId = pathname.split('/').pop()
        let resp = await dispatch(addTrainingRequest({agentId,countryId}));
        if (resp && resp.success) {
            setMessage(resp.message)
            setCountryId([])
            setIsModalVisible(false)
        } else {
            setMessage(resp.message)
        }
    }

    const confirmMeetingFxn = async (data) => {
        confirm({
            width: "35%",
            title: 'Are you sure, you want to confirm your Online Portal Training ?',
            // content: 'Confirm Online Portal Meeting',
            okText: "Confirm",
            onOk() {
                submitHandleFxn()
            },
            onCancel() {

            },
        });
    }

    return (
        <React.Fragment>
            <div className="sub-banner-training">
                <div className="overlay">

                </div>
            </div>
            <div className="properties-section-body content-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">

                            <div className={'trainingBox'}>
                                <img src={'../newImages/zoom-logo.png'}/>

                                {message ?
                                    <div className={'textBox normal'}>
                                        <h3>{message}</h3>
                                    </div> :
                                    <div className={'textBox'}>
                                        <h3>Confirm your online portal training</h3>
                                        <a className={'confirmLink'} onClick={() => {
                                            // confirmMeetingFxn()
                                            setIsModalVisible(true)
                                        }}>
                                            Click to confirm
                                        </a>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {isModalVisible ? (
                  <Modal
                    title='Please choose the countries you are interested in for online portal training?'
                    width={'50%'}
                    visible={isModalVisible}
                    onOk={()=>{
                        confirmMeetingFxn()
                    }}
                    onCancel={()=>{
                        setCountryId([])
                        setIsModalVisible(false)
                    }}
                    okText='Confirm'>
                      <>
                              <div className={'col-md-12'}>
                                  <Select
                                    mode={'multiple'}
                                    placeholder={'Select Multiple Country'}
                                    value={countryId}
                                    onChange={(value) => {
                                        setCountryId(value)
                                    }}
                                    multiple
                                  >
                                      {allCountry && allCountry.length && allCountry.map((item) => {
                                          return (
                                            <Option key={item._id} value={item._id}>
                                                {item.countryName}
                                            </Option>
                                          )
                                      })}
                                  </Select>
                          </div>
                      </>
                  </Modal>
                ) : null}
            </div>

        </React.Fragment>
    )
}
export default TrainingRequestComponent
