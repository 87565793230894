import { apiUrl } from '../../../settings'

export const addScheduleMeetingUrl = () => {
  return apiUrl + '/scheduleMeeting'
}

export const listScheduleMeetingUrl = () => {
  return apiUrl + '/scheduleMeeting'
}

export const singleScheduleMeetingUrl = (id) => {
  return apiUrl + '/scheduleMeeting/' + id
}

export const scheduleMeetingUsersListUrl = () => {
  return apiUrl + '/scheduleMeetingUsersList'
}

export const checkMeetingTokenUrl = () => {
  return apiUrl + '/api/checkMeetingToken'
}
export const updateMeetingStatusUrl = () => {
  return apiUrl + '/api/updateMeetingStatus'
}

export const newAddScheduleMeetingUrl = () => {
  return apiUrl + '/newScheduleMeeting'
}

export const addNewAddScheduleMeetingUrl = () => {
  return apiUrl + '/newScheduleMeetingWithPhoto'
}

export const addFollowupUrl = () => {
  return apiUrl + '/api/meeting-followups'
}

