const initialState = {
    currentUser: {
        email: null
    },
    token: null,
    buttonLoading: false,
    pageLoading: false,
    joinRoom: false,
    leaveRoom: false,
    reloadNotification: false,
    reloadDashboard: false,
    userId: null,
    reloadPrivateMessage: false,
    privateMessage: {},
    socketData: {
        userId: null,
        uuid: null,
        obj: null,
      noOfNotification: 0
    },
  currentUserRights: {},
  loadCurrentUser: false,
  reloadOnlineAgent: false,
  reloadOnlineAgentCount: false,
  activeUser: [],
  menuRefresh: '',
  totalOnlineAgent: 0,
  totalFootfall: 0,
  isRefreshAgentCount: false,
  unreadLatestUpdate: 0
}

export default (state = initialState, action) => {
    switch (action.type) {

        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.user
            }

        case 'SET_CURRENT_USER_RIGHT':
            return {
                ...state,
                currentUserRights: action.rights
            }

        case 'LOGOUT':
            return {
                ...state,
                currentUser: {}
            }

        case 'SHOW_BTN_LOADING':
            return {
                ...state,
                buttonLoading: true
            }

        case 'HIDE_BTN_LOADING':
            return {
                ...state,
                buttonLoading: false
            }

        case 'SET_AUTH_TOKEN':
            return {
                ...state,
                token: action.token
            }

        case 'SHOW_PAGE_LOADING':
            return {
                ...state,
                pageLoading: true
            }

        case 'HIDE_PAGE_LOADING':
            return {
                ...state,
                pageLoading: false
            }

        case 'JOIN_SOCKET':
            return {
                ...state,
                joinRoom: action.value,
                userId: action.userId
            }

        case 'LEAVE_SOCKET':
            return {
                ...state,
                leaveRoom: action.value,
                userId: action.userId
            }

        case 'RELOAD_NOTIFICATION':
            return {
                ...state,
                reloadNotification: action.value,
                socketData: action.socketData
            }

        case 'RELOAD_DASHBOARD':
            return {
                ...state,
                reloadDashboard: action.value,
                userId: action.userId
            }

        case 'PRIVATE_MESSAGE':
            return {
                ...state,
                reloadPrivateMessage: action.value,
                socketData: action.socketData
            }
        case 'START_LOAD_CURRENT_USER':
            return {
                ...state,
                loadCurrentUser: true
            }
        case 'STOP_LOAD_CURRENT_USER':
            return {
                ...state,
                loadCurrentUser: false
            }
        case 'ACTIVE_USERS':
            return {
                ...state,
                activeUser: action.activeUser
            }

        case 'RELOAD_ONLINE_AGENT':
            return {
                ...state,
                reloadOnlineAgent: action.value,
            }

        case 'RELOAD_ONLINE_COUNT':
            return {
                ...state,
                reloadOnlineAgentCount: action.value,
            }
        case 'REFRESH_MENU':
            return {
              ...state,
              menuRefresh: action.value
            }
      case 'REFRESH_ONLINE_AGENTS':
        return {
          ...state,
          totalFootfall: action.totalFootfall,
          totalOnlineAgent: action.totalOnlineAgent,
          isRefreshAgentCount: action.value
        }
      case 'UNREAD_LATEST_UPDATE':
        return {
          ...state,
          unreadLatestUpdate: action.unreadUpdates
        }
      default:
        return state
    }
}
