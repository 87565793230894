import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, Form, notification, Row } from 'antd'
import {useDispatch} from "react-redux";
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { LoadState } from '../../components/_utils/countryUtil'
import { getCitiesFxn, getPlaceFxn, getStatesFxn } from '../dailyExpense/actions/dailyExpense'
import { updateTravelPlanFxn } from './actions'
import moment from 'moment'

let initialState = {
  allStates: [],
  allCities: []
}

const EditTravelPlanDrawer = (props) => {
  const {
    form: { getFieldDecorator, getFieldValue },
    record,
    visible,
    onClose,
    travelPlanId,
    onSubmit
  } = props
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)
  const [indianStates, setIndianStates] = useState([])
  const [indianCities, setIndianCities] = useState([])
  const [fromPlace, setFromPlace] = useState([])
  const [toState, setToState] = useState([])
  const [toCities, setToCity] = useState([])
  const [toPlace, setToPlace] = useState([])
  const [stops, setStops] = useState([])

  useEffect(
    () => {
      setState({
        ...state,
        allStates: 101 ? LoadState(101) : []
      })
    },
    []
  )

  useEffect(() => {
    getIndianStates()
    getToStates()
  }, [])

  useEffect(() => {
    if (getFieldValue('state')  || record.state) {
      getIndianCities()
    }
  }, [getFieldValue('state') || record.state])

  useEffect(() => {
    if (getFieldValue('toState' || record.toState)) {
      getToCities()
    }
  }, [getFieldValue('toState') || record.toState])

  useEffect(() => {
    if (getFieldValue('city')  || record.city) {
      getFromPlace()
    }
  }, [getFieldValue('city')  || record.city])

  useEffect(() => {
    if (getFieldValue('toCity')  || record.toCity) {
      getToPlace()
    }
  }, [getFieldValue('toCity')  || record.toCity])

  useEffect(() => {
    getStops()
  }, [])
  useEffect(() => {
    if (record) {
      const date = moment(record.date)
      setTimeout(() => {
          props.form.setFieldsValue({
            date: date,
            state: record.state,
            city: record.city,
            toState: record.toState,
            toCity: record.toCity,
            fromPlace: record.fromPlace,
            toPlace: record.toPlace,
            stops: record.stops,
          })
        }, 1000)
    }
  }, [record])

  const getIndianStates = async () => {
    let data = await dispatch(getStatesFxn({ results: 1000 }))
    setIndianStates(data)
  }
  const getIndianCities = async (params = {}) => {
    if (getFieldValue('state')) {
      params.state = getFieldValue('state')
    }else if (record.state){
      params.state = record.state
    }
    let data = await dispatch(getCitiesFxn({ results: 1000, ...params }))
    setIndianCities(data)
  }

  const getToStates = async () => {
    let data = await dispatch(getStatesFxn({ results: 1000 }))
    setToState(data)
  }

  const getToCities = async (params = {}) => {
    if (getFieldValue('toState')) {
      params.state = getFieldValue('toState')
    }else if (record.toState){
      params.state = record.toState
    }
    let data = await dispatch(getCitiesFxn({ results: 1000, ...params }))
    setToCity(data)
  }

  const getFromPlace = async (params = {}) => {
    if (getFieldValue('city')) {
      params.city = getFieldValue('city')
    }else if (record.city){
      params.city = record.city
    }
    let data = await dispatch(getPlaceFxn({ results: 1000, ...params }))
    setFromPlace(data)
  }

  const getToPlace = async (params = {}) => {
    if (getFieldValue('toCity')) {
      params.city = getFieldValue('toCity')
    }else if (record.toCity){
      params.city = record.toCity
    }
    let data = await dispatch(getPlaceFxn({ results: 1000, ...params }))
    setToPlace(data)
  }

  const getStops = async (params = {}) => {
    let data = await dispatch(getCitiesFxn({ results: 1000, ...params }))
    setStops(data)
  }

  let events = {
    updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data
        }
      })
    },
    handleSubmitFxn: e => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        if (!err) {
          if (travelPlanId){
            valData.travelPlanId = travelPlanId
          }
          let data = await dispatch(updateTravelPlanFxn(valData))
          if (data && !data.error) {
            onSubmit()
          }
        } else {
          notification.warning({
            message: 'Fill All Required Fields'
          })
        }
      })
    }
  }

  const inputTypes = {
    dateField: [
      {
        key: 'date',
        label: 'Choose Travel Date',
        type: 'date',
        required: true,
        span: 8,
        placeholder: 'Choose Travel Date'
      },
    ],
    fields: [
      {
        key: 'state',
        required: true,
        label: 'From State',
        placeholder: 'State Name',
        type: 'select',
        showSearch: true,
        allowClear: true,
        options: indianStates,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: '',
            city: '',
            state: x
          })
        },
        span: 8,
        disabled: true
      },
      {
        key: 'city',
        allowClear: true,
        label: 'From City',
        required: true,
        placeholder: 'City Name',
        showSearch: true,
        type: 'select',
        options: indianCities,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: '',
            city: x
          })
        },
        span: 8,
        disabled: true
      },
      {
        key: 'fromPlace',
        allowClear: true,
        label: 'From Place',
        placeholder: 'From Place',
        type: 'select',
        showSearch: true,
        options: fromPlace,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            fromPlace: x
          })
        },
        span: 8,
        disabled: true
      },
      {
        key: 'toState',
        allowClear: true,
        label: 'To State',
        required: true,
        placeholder: 'State Name',
        type: 'select',
        showSearch: true,
        options: toState,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            toPlace: '',
            toCity: '',
            toState: x
          })
        },
        span: 8,
        disabled: true
      },
      {
        key: 'toCity',
        allowClear: true,
        label: 'To City',
        required: true,
        placeholder: 'City Name',
        type: 'select',
        showSearch: true,
        options: toCities,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            toCity: x,
            toPlace: ''
          })
        },
        span: 8,
        disabled: true
      },
      {
        key: 'toPlace',
        allowClear: true,
        label: 'To Place',
        placeholder: 'To Place',
        type: 'select',
        showSearch: true,
        options: toPlace,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            toPlace: x
          })
        },
        span: 8,
        disabled: true
      },
      {
        key: 'stops',
        allowClear: true,
        label: 'Add Multiple Stop',
        placeholder: 'Stops',
        type: 'select',
        showSearch: true,
        options: stops,
        mode: 'multiple',
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          props.form.setFieldsValue({
            stops: x
          })
        },
        span: 8
      }


    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <Drawer
      visible={visible}
      title={`Edit Travel Plan`}
      onClose={onClose}
      width={'80%'}
    >
      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={events.handleSubmitFxn}>
            <div className='form-box'>
              <div className='card unizportal'>
                <Row gutter={24}>
                  {inputTypes.dateField.map((item, key) => {
                    return (
                      <Col span={item.span ? item.span : 8}
                           key={key}
                           className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    )
                  })}
                </Row>
                <Row gutter={24} className={'wrapBox'}>
                  {inputTypes.fields.map((item, key) => {
                    return (
                      <Col span={item.span ? item.span : 8}
                           md={item.span ? item.span : 8}
                           sm={12}
                           xs={24}
                           key={key}
                           className={'mb10'}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      </Col>
                    )
                  })}
                </Row>
                <Form.Item>
                  <Button type='primary' htmlType='submit' className='btn'>
                    SAVE
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}

const WrappedEditTravelPlan = Form.create()(EditTravelPlanDrawer)
export default WrappedEditTravelPlan
