import React, {Suspense, useEffect, useState} from "react"
import {countryWiseDashboard} from "../actions/dashboardAction";
import {useDispatch} from "react-redux";

const MarketingManagerDashboard = React.lazy(() =>
    import('./marketingDashboardComponent').then(module => ({
        default: module.MarketingManagerDashboard
    }))
)
const AchievedTargetsPieChart = React.lazy(() =>
    import('../components/targetAchieved')
)
const initialState = {
    ukData: {},
    canadaData: {},
    australiaData: {},
    australiaL1Data: {},
    usaData: {},
    germanyData: {},
    singaporeData: {},
    canadaOnshoreData: {}
}

const MarketingDashboard = (props) => {
    let { currentUserRights, user } = props
    let dispatch = useDispatch()
    let [state, setState] = useState(initialState)
    let [countryManager, setCountryManager] = useState({})
    let [userCountryName, setUserCountryName] = useState(null)
    let [userData, setUserData] = useState({})

    const events = {}
    useEffect(() => {
        loadUserCountry()
        loadCountryWiseData()
    }, [])
    const loadCountryWiseData = async () => {
        let resp = await dispatch(countryWiseDashboard())
        let {
            UK,
            Canada,
            Australia,
            USA,
            Germany,
            countryManager,
            Australia_L1,
            Singapore,
        } = resp
        setCountryManager(countryManager)
        setState({
            ...state,
            ukData: UK ? UK : {},
            canadaData: Canada ? Canada : {},
            australiaData: Australia ? Australia : {},
            usaData: USA ? USA : {},
            germanyData: Germany ? Germany : {},
            australiaL1Data: Australia_L1 ? Australia_L1 : {},
            singaporeData: Singapore ? Singapore : {},
            canadaOnshoreData: resp['Canada Onshore'] ? resp['Canada Onshore'] : {}
        })
    }

    const loadUserCountry = async () => {
        setUserData(user)
    }
    return (
        <>
            <div className="card mt-4">
                <div className="card-body p-0">
                    {userData && userData._id ? (
                        <Suspense fallback={<></>}>
                            <MarketingManagerDashboard
                              userData={userData}
                              currentUserRights={currentUserRights}
                              userCountryName={userCountryName}
                              countryManager={countryManager}
                              events={events}
                              state={state}
                            />
                        </Suspense>
                    ) : null}
                </div>
            </div>

        </>
    )
}


export default MarketingDashboard
