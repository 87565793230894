import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Icon, Spin, Tooltip, Drawer } from 'antd'
import {
  InputBox,
  countryIds
} from '../../../components/_utils/appUtils'
import { campusIntakesListFxn } from '../actions'
import { connect, useDispatch } from 'react-redux'
import Styles from './styles.less'
import _ from 'lodash'
import TableComp from '../../../components/_utils/table'
import moment from 'moment'
import { UniversityEligibility } from '../../university/drawers/universityEligibility'
import { getSingleCourse } from '../../course/actions/courseAction'
import AddApplications from '../../applications/views/add'

const antIcon = <Icon type='loading' style={{ fontSize: 40 }} spin />
let initState = {
  totalCourses: 0,
  isLoading: true,
  currentPage: 1,
  count: 50,
  status: 'Open'
}

const useDebouncedApiCall = (apiCall, delay) => {
  const [debouncedApiCall, setDebouncedApiCall] = useState(null)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedApiCall(apiCall)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [apiCall, delay])

  return debouncedApiCall
}

let ProgramListComponent = (props) => {
  let { countryName } = props
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  let dispatch = useDispatch()
  let [state, setState] = useState(initState)
  let [countryId, setCountryId] = useState(props.countryId)
  let tableRef = useRef()

  let [eligDrawer, setEligDrawer] = useState({
    universityId: '',
    universityName: '',
    visible: false
  })
  let [courseState, setCourseState] = useState({
    visible: false,
    selectedCourse: {}
  })

  useEffect(() => {
    events.refreshTable()
  }, [countryId])

  let events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data,
          currentPage: 1
        }
      })
    },
    onShowSizeChange: (current, pageSize) => {
      events._updateState({
        currentPage: current
      })
    },
    refreshTable: () => {
      if (tableRef && tableRef.current && tableRef.current.reload) {
        tableRef.current.reload()
      }
    },
    debounceFetchData: () => {
      events._updateState({ isLoading: true })
      let dataFetch = _.debounce(() => {
        events.refreshTable()
        console.log('rin')
      }, 1500)
      dataFetch()
    },
    debounceRefresh: () => {
      const debouncedLogHi = _.debounce(() => {
        events.refreshTable()
        console.log('run')
      }, 1000, true)
      debouncedLogHi()


      /*            const getData = setTimeout(() => {
                      this.tableRef.current.reload()
                  }, 1500)

                  return () => clearTimeout(getData)*/

    },
    hideEligDrawer: () => {
      setEligDrawer({
        universityId: '',
        universityName: '',
        visible: false
      })
    },
    addApplicationDrawer: async (obj) => {
      let { data } = await dispatch(getSingleCourse(obj._id))
      let selectedCourse = {
        '_id': data._id,
        'tuitionFee': data.tuitionFee,
        'courseUniversity': data.courseUniversity._id,
        'courseName': data.courseName,
        'courseDuration': data.courseDuration,
        'courseLevel': data.courseLevel,
        'universityCountry': data.universityCountry._id,
        'universityState': data.universityState._id,
        'universityCity': data.universityCity,
        'commission': 0,
        'applicationFee': data.courseUniversity && data.courseUniversity.applicationFee ? data.courseUniversity.applicationFee : 0,
        'universityName': data.courseUniversity.universityName,
        'countryName': data.universityCountry.countryName,
        'courseId': data._id,
        'campusIntakes': obj.campusIntakes,
        'campusId': obj.campusIntakes.campusId,
        'month': obj.campusIntakes.month,
        'year': obj.campusIntakes.year
      }
      setCourseState({
        visible: true,
        selectedCourse: selectedCourse
      })
    },
    hideStudentDrawer: () => {
      setCourseState({
        visible: false,
        selectedCourse: {}
      })
    }
  }
  /*
    const debouncedApiCall = useDebouncedApiCall(() => {
      // Make the API call here
      events.refreshTable()
    }, 1000)
  */

  useEffect(() => {
    events._updateState({ isLoading: true })
    const timer = setTimeout(() => {
      events.refreshTable()
    }, 1000)
    return () => clearTimeout(timer)
  }, [state.courseName, state.universityName, state.campusName, state.intake, state.status])


  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let obj = {
        courseName: state.courseName,
        universityName: state.universityName,
        campusName: state.campusName,
        intake: state.intake,
        status: state.status,
        countryId: countryId
        // page: state.currentPage
      }
      params = { ...params, ...obj }
      let resp1 = await campusIntakesListFxn({
        ...params,
        regExFilters: ['courseName', 'courseDuration', 'courseLevel', 'tuitionFee']
      })
      resolve(resp1)
      setTimeout(() => {
        events._updateState({
          totalCourses: resp1.total ? resp1.total : 0,
          currentPage: params.page,
          isLoading: false
        })
      }, 500)
    })
  }

  useEffect(() => {
  }, [state.courseName, state.universityName, state.campusName, state.intake, state.status])


  let columns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <>
            {state.count * (state.currentPage - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      key: 'courseName',
      title: 'Program',
      dataIndex: 'courseName'
    },
    {
      key: 'universityName',
      title: 'University',
      dataIndex: 'universityName',
      render: (item, record) => {
        let { campusIntakes } = record
        return (
          <>
            {item}
            {campusIntakes && campusIntakes.updateDate ? <div className={'mt5'}>
              <label className={'label label-default label-xs'} style={{ fontSize: 11 }}>
                Last Update : {moment(campusIntakes.updateDate).fromNow()}
              </label>
            </div> : null}
          </>
        )
      }
    },
    {
      key: 'campusIntakes.campusName',
      title: 'Campus',
      dataIndex: 'campusIntakes.campusName'
    },
    {
      key: 'campusIntakes',
      title: 'Program',
      dataIndex: 'campusIntakes',
      render: (item) => {
        return (
          <>
            {item.month ? item.month : ''}
            {item.year ? `, ${item.year}` : ''}
          </>
        )
      }
    },
    {
      key: 'campusIntakes.status',
      title: 'status',
      dataIndex: 'campusIntakes.status',
      render: (item) => {
        return (
          <div style={{ textTransform: 'uppercase' }}>
            {item}
          </div>
        )
      }
    },
    {
      key: 'action',
      title: 'Action',
      dataIndex: 'action',
      render: (item, record) => {
        let { courseUniversity, universityName } = record
        return (
          <div style={{ width: 150 }}>
            <div>
              <Tooltip title={'View Eligibility'}>
                <a className={'btn btn-default btn-xs'}
                   onClick={() => {
                     setEligDrawer({
                       universityId: courseUniversity,
                       universityName: universityName,
                       visible: true
                     })
                   }}>
                  <i className={'fa fa-eye'}></i> Eligibility
                </a>
              </Tooltip>
            </div>
            <div className={'mt5'}>
              {user.userType !== 'student' && (
                <Tooltip title={'Add Application'}>
                  <a className={'btn btn-default btn-xs'} onClick={() => events.addApplicationDrawer(record)}>
                    <i className={'fa fa-plus'}></i> Add Application
                  </a>
                </Tooltip>
              )}
            </div>

          </div>
        )
      }
    }
  ]


  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <Button onClick={() => {

              }}
                      className={'roundGoBackBtn'} icon={'arrow-left'}>
                Back
              </Button>

              <h5>{countryName} Open / Close Courses </h5>
              <div className='search-box-table round'>

              </div>
              <div className='sort-box-table mark-btn'>

              </div>

            </div>

            <div className={`card-body table-responsive ${Styles.table}`}>

              <table className={'table table-bordered'}>
                <tr>
                  <th>#</th>
                  <th>Program</th>
                  <th>University</th>
                  <th>Campus</th>
                  <th>Intake</th>
                  <th>Status</th>
                  <th>
                    <div style={{ width: 150 }}>
                      Action
                    </div>
                  </th>
                </tr>
                <tr className={Styles.bgRow}>
                  <td></td>
                  <td>
                    <InputBox customClass={Styles.single_column}
                              title={'Search by course name'}>
                      <Input value={state.courseName}
                             placeholder={'Course Name'}
                             onChange={({ target }) => {
                               events._updateState({ courseName: target.value })
                             }} />
                    </InputBox>
                  </td>
                  <td>
                    <InputBox customClass={Styles.single_column}
                              title={'Search by university name'}>
                      <Input value={state.universityName}
                             placeholder={'University Name'}
                             onChange={({ target }) => {
                               events._updateState({ universityName: target.value })
                             }} />
                    </InputBox>
                  </td>
                  <td>
                    <InputBox customClass={Styles.single_column} title={'Search by campus'}>
                      <Input value={state.campusName}
                             placeholder={'Campus'}
                             onChange={({ target }) => {
                               events._updateState({ campusName: target.value })
                             }} />
                    </InputBox>
                  </td>
                  <td>
                    <InputBox customClass={Styles.single_column} title={'Search by intake'}>
                      <Input value={state.intake}
                             placeholder={'Intake'}
                             onChange={({ target }) => {
                               events._updateState({ intake: target.value })
                             }} />
                    </InputBox>
                  </td>
                  <td>
                    <InputBox customClass={Styles.single_column} title={'Search by status'}>
                      <select value={state.status} className={'ant-input'}
                              onChange={({ target }) => {
                                events._updateState({ status: target.value })
                              }}>
                        <option value={'Open'}>Open</option>
                        <option value={'Close'}>Close</option>
                      </select>
                    </InputBox>
                  </td>
                  <td style={{ width: 200 }}></td>
                </tr>
              </table>

              {state.isLoading ? <div className={Styles.loader}>
                <Spin indicator={antIcon} />
                <br />
                &nbsp;&nbsp;<h3>Loading</h3>
              </div> : null}

              <div className={Styles.hideHead} key={state.totalCourses}>
                <TableComp
                  bordered={true}
                  rowKey={(item, index) => {
                    return item._id
                  }}
                  apiRequest={apiRequest}
                  ref={tableRef}
                  columns={columns}
                  pagination={{
                    position: 'top',
                    defaultPageSize: 20,
                    pageSizeOptions: ['20', '50', '100']
                    // position: "bottom"
                  }} />
              </div>


            </div>
          </div>
        </div>
      </div>

      {eligDrawer.visible ?
        <UniversityEligibility visible={eligDrawer.visible}
                               onClose={() => events.hideEligDrawer()}
                               universityId={eligDrawer.universityId}
        />
        : null}

      {courseState.visible ? <AddApplications
        visible={courseState.visible}
        showAddStudent={false}
        selectedCourse={courseState.selectedCourse}
        closeStudentDrawer={events.hideStudentDrawer} /> : null}
      {/*
            <Drawer visible={state.addStuDrawer}
                    title={'Add New Student'}
                    width={window.innerWidth > 1800 ? 1500 : '60%'}
                    closable={false}
                    onClose={()=>{

                    }}>
                <AddStudentContent callBackFxn={studentCallback} pageType={'drawer'}
                                   selectedCourse={selectedCourse}/>
            </Drawer>
            */}


    </>
  )
}

export default ProgramListComponent
