import React from 'react'
import {
    departmentObj,
    universityListForMarketing,
    ukUniversityListForMarketing
} from '../../../components/_utils/appUtils'
import {LazyLoadImage} from "react-lazy-load-image-component";

const InnerBlockNew = ({statusList, callback, data}) => {
    let {countryId = '', countArr = {}} = data
    return (
        <div className='card-pane-right card-pane-new listing-section w-100' style={{border: 'none'}}>
            <ul className={'count'}>
                {statusList.map((item, key) => {
                    return (
                        item.name !== 'New Student' &&
                        <li onClick={() => callback(countryId, item.name)} className={'pointerCls mb20'} key={key}>
                            <h4 className={'linkText'}>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h4>
                            <p style={{
                                color: 'black',
                                fontSize: 15
                            }}>{item.name == 'Case Close' ? 'Case Closed' : item.name}</p>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const UkInnerBlock = ({statusList, callback, data, directUniversityCount, openDirectUni}) => {
    let {countryId = '', countArr = {}} = data;

    return (
        <>
            <div className='card-pane-right card-pane-new listing-section w-100' style={{border: 'none'}}>
                <ul className={'count'}>
                    {statusList.map((item, key) => {
                        return (
                            item.name !== 'New Student' &&
                            <li onClick={() => callback(countryId, item.name)} className={'pointerCls mb20'} key={key}>
                                <h4 className={'linkText'}>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h4>
                                <p className={'statusLabel'}>{item.name == 'Case Close' ? 'Case Closed' : item.name}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className='card-pane-right card-pane-new listing-section w-100' style={{border: 'none'}}>
                <ul className={'count'}>
                    {ukUniversityListForMarketing.map((item) => {
                        let obj = directUniversityCount[item.id];
                        return (
                            <li key={item.id}
                                className={'marketingDirectUniCount'}>
                                <div className={'uni_box'}>
                                    <LazyLoadImage src={item.image}/>

                                    <div className={'uniCountBox'}>
                                        <div className={'countBox'}
                                             onClick={() => {
                                                 openDirectUni({universityId: item.id, status: 'Offer Received'})
                                             }}>
                                            <div className={'label'}>New</div>
                                            <div
                                                className={'count'}>{obj && obj['Offer Received'] ? obj['Offer Received'] : 0}</div>
                                        </div>
                                        <div className={'countBox'}
                                             onClick={() => {
                                                 openDirectUni({universityId: item.id, status: 'Visa Approved'})
                                             }}>
                                            <div className={'label'}>Visa</div>
                                            <div
                                                className={'count'}>{obj && obj['Visa Approved'] ? obj['Visa Approved'] : 0}</div>
                                        </div>
                                    </div>
                                </div>

                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

const CanadaInnerBlock = ({statusList, callback, data, directUniversityCount, openDirectUni}) => {
    let {countryId = '', countArr = {}} = data;

    return (
        <>
            <div className='card-pane-right card-pane-new listing-section w-100' style={{border: 'none'}}>
                <ul className={'count'}>
                    {statusList.map((item, key) => {
                        return (
                            item.name !== 'New Student' &&
                            <li onClick={() => callback(countryId, item.name)} className={'pointerCls mb20'} key={key}>
                                <h4 className={'linkText'}>{countryId && countArr[item.name] ? countArr[item.name] : 0}</h4>
                                <p className={'statusLabel'}>{item.name == 'Case Close' ? 'Case Closed' : item.name}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className='card-pane-right card-pane-new listing-section w-100' style={{border: 'none'}}>
                <ul className={'count'}>
                    {universityListForMarketing.map((item) => {
                        let obj = directUniversityCount[item.id];
                        return (
                            <li key={item.id}
                                className={'marketingDirectUniCount'}>
                                <div className={'uni_box'}>
                                    <LazyLoadImage src={item.image}/>

                                    <div className={'uniCountBox'}>
                                        <div className={'countBox'}
                                             onClick={() => {
                                                 openDirectUni({universityId: item.id, status: 'Offer Received'})
                                             }}>
                                            <div className={'label'}>New</div>
                                            <div
                                                className={'count'}>{obj && obj['Offer Received'] ? obj['Offer Received'] : 0}</div>
                                        </div>
                                        <div className={'countBox'}
                                             onClick={() => {
                                                 openDirectUni({universityId: item.id, status: 'Visa Approved'})
                                             }}>
                                            <div className={'label'}>Visa</div>
                                            <div
                                                className={'count'}>{obj && obj['Visa Approved'] ? obj['Visa Approved'] : 0}</div>
                                        </div>
                                    </div>
                                </div>

                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export {InnerBlockNew, CanadaInnerBlock, UkInnerBlock}
