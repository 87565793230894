import React, {Component} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import { Button, Row, Col, Select, Tooltip } from 'antd'
import {connect} from 'react-redux'
import {
  dealUpdateByUser,
  directUniversityList,
  fcmtUniversity,
  filterOption
} from '../../components/_utils/appUtils'
import {ColumnWidth} from '../WebComponent/columnWidth'
import {getUrlPushWrapper} from '../../routes'
import {LoadAllCountry, LoadCity, LoadState} from '../../components/_utils/countryUtil'
import _ from 'lodash'
import moment from 'moment'
import { getAgentListByDirectUniversityFxn } from './action'
import MobileEmailInfo from '../../components/commonComponents/mobileEmailInfo'
import CloseDeal from '../closeDeal/drawer/closeDeal'
import UpdateDealClose from '../closeDeal/drawer/updateDealClose'
import RequestForConsent from '../closeDeal/drawer/requestForConsent'
import { listAllCountries } from '../countries/actions/countries'
import CloseDealForUniWiseAssignedAgent from './drawers/uniWiseAssignAgentsCloseDealDrawer'

const {Option} = Select

class AgentListByDirectUniversity extends Component {

  events = {
    showMarketingDrawer: (data) => {
      this.setState({
        marketingState: {
          agentMarketing: data.agentMarketing,
          armUserData: data.armUsers,
          visible: true,
          agentId: data._id,
          email: data.email,
        }
      })
    },

    hideMarketingDrawer: () => {
      this.setState({
        marketingState: {
          agentMarketing: {},
          armUserData: [],
          visible: false,
          agentId: "",
          email: "",
        }
      })
    },
    chooseCountry: (name) => {
      let countryName = _.find(this.state.allCountries, (item) => {
        return item.name == name
      })
      if (countryName) {
        this.setState({
          countryName: countryName.name ? countryName.name : '',
          stateName: undefined,
          cityName: undefined,
          pincode: undefined,
          allStates: countryName.id ? LoadState(countryName.id) : []
        })
      }
    },
    chooseState: (name) => {
      let stateName = _.find(this.state.allStates, (item) => {
        return item.name == name
      })
      if (stateName) {
        this.setState({
          stateName: stateName.name ? stateName.name : '',
          cityName: undefined,
          pincode: undefined,
          allCities: stateName.id ? LoadCity(stateName.id) : []
        })
      }
    },
    chooseCity: (name) => {
      this.setState({
        cityName: name ? name : ''
      })
    },
    showDealClose: data => {
      this.setState({
        dealCloseState: {
          visible: true,
          agentId: data._id,
          dealClose: data.dealClose
        }
      })
    },
    showCloseDealUpdate: data => {
      this.setState({
        dealUpdateState: {
          visible: true,
          agentId: data._id,
          dealClose: data.dealClose
        }
      })
    },
    showRequestConsentDeal: data => {
      this.setState({
        requestState: {
          visible: true,
          agentId: data._id,
          dealClose: data.dealClose
        }
      })
    },
    hideDealClose: () => {
      this.setState({
        dealCloseState: {
          visible: false,
          agentId: '',
          dealClose: []
        }
      })
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: false,
      selectedRow: {},
      queryData: {},
      visibleUserRightDrawer: false,
      approvedAgentRightsUser: [],
      zeroRecordAgent: false,
      filters: {},
      total: 0,
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      allCountries: LoadAllCountry(),
      allStates: [],
      allCities: [],
      keyMoment: moment(),
      countryList: [],
      armUserData: [],
      marketingState: {
        visible: false,
        agentId: "",
        agentMarketing: {},
        email: {},
      },
      universityId:fcmtUniversity,
      dealCloseState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      dealUpdateState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      requestState: {
        visible: false,
        agentId: '',
        dealClose: []
      },
      universityFoDealClose:[],
      assignUniversity:[]
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.setFieldByParams()
    this.loadCountry()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp1 = await this.getParamsForApplicationList()

      if (resp1.countryName) {
        params.countryName = resp1.countryName
      }
      if (resp1.stateName) {
        params.stateName = resp1.stateName
      }
      if (resp1.cityName) {
        params.cityName = resp1.cityName
      }
      if (resp1.pincode) {
        params.pincode = resp1.pincode
      }
      if (resp1.email) {
        params.email = resp1.email
      }
      if (resp1.mobile) {
        params.mobile = resp1.mobile
      }
      if (resp1.companyName) {
        params.companyName = resp1.companyName
      }
      if (resp1.name) {
        params.name = resp1.name
      }
      if (resp1.universityId) {
        params.universityId = resp1.universityId ? resp1.universityId :'5ff6d1ac3fdb3f5fa68e9ff3'
      }

      let finalFilter = {
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email', 'cityName', 'companyName']
      }
      this.setState({filters: finalFilter})
      let { data } = await getAgentListByDirectUniversityFxn(finalFilter)
      console.log(data,"data")
      this.setState({total: data.total,assignUniversity: data.assignUniversity})
      resolve(data)
    })
  }

  getParamsForApplicationList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryName = searchParams.get('countryName')
      let stateName = searchParams.get('stateName')
      let cityName = searchParams.get('cityName')
      let pincode = searchParams.get('pincode')
      let agentWiseManagerId = searchParams.get('agentWiseManagerId')
      let name = searchParams.get('name')
      let email = searchParams.get('email')
      let companyName = searchParams.get('companyName')
      let mobile = searchParams.get('mobile')
      let marketingManagerId = searchParams.get('marketingManagerId')
      let universityId = searchParams.get('universityId')? searchParams.get('universityId'): fcmtUniversity

      let obj = {}
      if (countryName) {
        obj.countryName = countryName
      }
      if (stateName) {
        obj.stateName = stateName
      }
      if (cityName) {
        obj.cityName = cityName
      }
      if (pincode) {
        obj.pincode = pincode
      }
      if (agentWiseManagerId) {
        obj.agentWiseManagerId = agentWiseManagerId
      }
      if (marketingManagerId) {
        obj.marketingManagerId = marketingManagerId
      }
      if (name) {
        obj.name = name
      }
      if (companyName) {
        obj.companyName = companyName
      }
      if (mobile) {
        obj.mobile = mobile
      }
      if (email) {
        obj.email = email
      }
      if (universityId) {
        obj.universityId = universityId
      }
      resolve(obj)
    })
  }

  setFieldByParams = async () => {
    let resp = await this.getParamsForApplicationList()
    let obj = {}
    if (resp.countryName) {
      obj.countryName = resp.countryName
    }
    if (resp.stateName) {
      obj.stateName = resp.stateName
    }
    if (resp.cityName) {
      obj.cityName = resp.cityName
    }
    if (resp.pincode) {
      obj.pincode = resp.pincode
    }
    if (resp.agentWiseManagerId) {
      obj.agentWiseManagerId = resp.agentWiseManagerId
    }
    if (resp.marketingManagerId) {
      obj.marketingManagerId = resp.marketingManagerId
    }
    if (resp.name) {
      obj.name = resp.name
    }
    if (resp.email) {
      obj.email = resp.email
    }
    if (resp.companyName) {
      obj.companyName = resp.companyName
    }
    if (resp.mobile) {
      obj.mobile = resp.mobile
    }
    if (resp.universityId) {
      obj.universityId = resp.universityId
    }else{
      obj.universityId = fcmtUniversity
    }
    this.setState({...obj})
  }


  searchFxn = async () => {
    let {dispatch} = this.props
    let obj = {}
    if (this.state.countryName) {
      obj.countryName = this.state.countryName
    }
    if (this.state.stateName) {
      obj.stateName = this.state.stateName
    }
    if (this.state.cityName) {
      obj.cityName = this.state.cityName
    }
    if (this.state.pincode) {
      obj.pincode = this.state.pincode
    }
    if (this.state.agentWiseManagerId) {
      obj.agentWiseManagerId = this.state.agentWiseManagerId
    }
    if (this.state.marketingManagerId) {
      obj.marketingManagerId = this.state.marketingManagerId
    }
    if (this.state.name) {
      obj.name = this.state.name
    }
    if (this.state.email) {
      obj.email = this.state.email
    }
    if (this.state.companyName) {
      obj.companyName = this.state.companyName
    }
    if (this.state.mobile) {
      obj.mobile = this.state.mobile
    }
    if (this.state.universityId) {
      obj.universityId = this.state.universityId
    }
    dispatch(
      getUrlPushWrapper('universityWiseAssignedAgents', {
        ...obj
      }))
    setTimeout(() => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }
  clearFxn = async () => {
    let {dispatch} = this.props
    let obj = {}
    this.setState({
      countryName: '',
      stateName: '',
      cityName: '',
      pincode: '',
      agentWiseManagerId: "",
      marketingManagerId: "",
      name: "",
      email: "",
      companyName: "",
      mobile: "",
      universityId: "",
    })
    dispatch(
      getUrlPushWrapper('universityWiseAssignedAgents', {
        ...obj
      }))
    setTimeout(() => {
      this.tableRef.current.reload()
    }, 200)
  }

  loadCountry = async () => {
    let { dispatch } = this.props
    let countryFilter = {
      results: 10,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(countryFilter))
    this.setState({
      countryList: data
    })
  }

  render() {
    let {
      countryList,
      countryName,
      dealCloseState,
      dealUpdateState,
      requestState
    } = this.state

    let { currentUser } = this.props

    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        render: (item, record) => {
          return (
            <ColumnWidth width={150}>
              {item}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Company',
        key: 'companyName',
        sorter: true,
        dataIndex: 'companyName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (item, record) => {
          return (
            <div>
              <MobileEmailInfo data={record} type={'email'} value={item} />
            </div>
          )
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        render: (item, record) => {
          return (
            <div>
              <MobileEmailInfo data={record} type={'email'} value={item} />
            </div>
          )
        }
      },
      {
        title: 'Country',
        dataIndex: 'countryName',
        key: 'countryName',
        searchTextName: 'countryName',
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'State',
        dataIndex: 'stateName',
        key: 'stateName',
        searchTextName: 'stateName',
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'City',
        dataIndex: 'cityName',
        key: 'cityName',
        searchTextName: 'cityName',
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Pin Code',
        dataIndex: 'pincode',
        key: 'pincode',
        searchTextName: 'pincode',
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'DealClose Information',
        key: 'dealClose',
        render: (dealClose, record) => (
          <ColumnWidth width={230}>
            <ul className={'countList'}>
              {record.dealClose && record.dealClose ? record.dealClose.map((deal, key) => {
                return (
                  this.showDeals(deal.universityId._id) ? <li>
                    {deal && deal.universityId ? deal.universityId.universityName : ''}
                    {deal && deal.amount ? <>&nbsp; <label
                      className={'label label-success-green sm'}><b> Deal : (
                      CAD {deal.amount} )</b></label></> : null}
                  </li> : null
                )
              }) : null}
            </ul>
          </ColumnWidth>
        )
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        render: (val, record) => {
          return (
            <div>
              <React.Fragment>
                {<Tooltip title={'Close Deal'}>
                  <button className='btn' onClick={() => {
                    this.events.showDealClose(record)
                  }}>
                    <img
                      src='../../dist/img/plus.svg'
                      alt=''
                      className={'crossBtn'}
                    />
                  </button>
                </Tooltip>}
              </React.Fragment>
              {dealUpdateByUser(currentUser) && record.dealClose && record.dealClose.length ? <React.Fragment>
                {<Tooltip title={'Deal Update'}>
                  <button className='btn' onClick={() => {
                    this.events.showCloseDealUpdate(record)
                  }}>
                    <img
                      src='../../dist/img/refresh.png'
                      alt=''
                      className={'crossBtn'}
                    />
                  </button>
                </Tooltip>
                }
              </React.Fragment> : null}


              {record.dealClose && record.dealClose.length ? <React.Fragment>
                {<Tooltip title={'Close Deal'}>
                  <button className='btn' onClick={() => {
                    this.events.showRequestConsentDeal(record)
                  }}>
                    <img style={{ height: 28 }}
                         src='../../dist/img/consentRequest.png'
                    />
                  </button>
                </Tooltip>}
              </React.Fragment> : null}
            </div>
          )
        }
      }
    ]

    const filterBlock = (
      <Row gutter={16} className={'mt10'}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Search By Country'
                    onChange={(item) => this.events.chooseCountry(item)}
                    filterOption={filterOption}
                    className={'antSelect'}
                    placeholder='Country'
                    allowClear={true}
                    showSearch={true}
                    value={this.state.countryName || undefined}>
              {this.state.allCountries && this.state.allCountries.length ? this.state.allCountries.map((item, key) => {
                return (
                  <Option value={item.name} key={key}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Search By State'
                    onChange={(item) => this.events.chooseState(item)}
                    filterOption={filterOption}
                    className={'antSelect'}
                    placeholder='State'
                    allowClear={true}
                    showSearch={true}
                    value={this.state.stateName || undefined}>
              {this.state.allStates && this.state.allStates.length ? this.state.allStates.map((item, key) => {
                return (
                  <Option value={item.name} key={key}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          {!countryName || countryName == 'India' ?
            <div className='sort-box-table custom-sort-box-new'>
              <Select name='Search By City'
                      onChange={(item) => this.events.chooseCity(item)}
                      filterOption={filterOption}
                      className={'antSelect'}
                      placeholder='City'
                      allowClear={true}
                      showSearch={true}
                      value={this.state.cityName || undefined}>
                {this.state.allCities && this.state.allCities.length ? this.state.allCities.map((item, key) => {
                  return (
                    <Option value={item.name} key={key}>{item.name}</Option>
                  )
                }) : null}
              </Select>
            </div> :
            <div className='search-box-table round ml0'>
              <input name='Search By City' className='form-control'
                     onChange={(e) => {
                       this.setState({cityName: e.target.value})
                     }}
                     placeholder='City'
                     value={this.state.cityName}/>
              <img src='/dist/img/search.png' alt=''/>

            </div>}
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select name='Search By University'
                    onChange={(e) => {
                      this.setState({universityId: e})
                    }}
                    filterOption={filterOption}
                    className={'antSelect'}
                    placeholder='University'
                    allowClear={true}
                    showSearch={true}
                    value={this.state.universityId || undefined}>
              {this.state.assignUniversity ? this.state.assignUniversity.map((item, key) => {
                return (
                  <Option value={item.university} key={key}>{item.universityName}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input className='form-control form-control' type='search'
                   placeholder='Pincode' value={this.state.pincode} onChange={(e) => {
              this.setState({pincode: e.target.value})
            }}/>
            <img src='/dist/img/search.png' alt=''/>
          </div>
        </Col>


        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input className='form-control form-control' type='search'
                   placeholder='Name' value={this.state.name} onChange={(e) => {
              this.setState({name: e.target.value})
            }}/>
            <img src='/dist/img/search.png' alt=''/>
          </div>
        </Col>


        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input className='form-control form-control' type='search'
                   placeholder='Company Name' value={this.state.companyName} onChange={(e) => {
              this.setState({companyName: e.target.value})
            }}/>
            <img src='/dist/img/search.png' alt=''/>
          </div>
        </Col>


        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input className='form-control form-control' type='search'
                   placeholder='Email' value={this.state.email} onChange={(e) => {
              this.setState({email: e.target.value})
            }}/>
            <img src='/dist/img/search.png' alt=''/>
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input className='form-control form-control' type='search'
                   placeholder='Mobile No' value={this.state.mobile} onChange={(e) => {
              this.setState({mobile: e.target.value})
            }}/>
            <img src='/dist/img/search.png' alt=''/>
          </div>
        </Col>


        <Col md={8} sm={8} xs={12} lg={4}>
          <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
          <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>


      </Row>
    )
    return (
      <div>
        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>University Wise Assigned Agents({this.state.total})</h5>
                <div className='search-box-table'>
                </div>
                <div className='sort-box-table mark-btn'>
                </div>
              </div>
              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp columns={columns}
                           ref={this.tableRef}
                           pagination={{
                             defaultPageSize: 10,
                             pageSizeOptions: ['10', '20', '50']
                           }}
                           apiRequest={this.apiRequest}
                />
              </div>
            </div>
          </div>
        </div>
        {dealCloseState.visible ? (
          <CloseDealForUniWiseAssignedAgent
            {...dealCloseState}
            countryList={countryList}
            universityId={this.state.universityId}
            onClose={() => this.events.hideDealClose()}
            onSubmit={() => {
              this.events.hideDealClose()
              this.tableRef.current.reload()
            }}
          />
        ) : null}

        {dealUpdateState.visible ? (
          <UpdateDealClose
            {...dealUpdateState}
            countryList={countryList}
            onClose={() => this.events.hideCloseDealUpdate()}
            onSubmit={() => {
              this.events.hideCloseDealUpdate()
              this.tableRef.current.reload()
            }}
          />
        ) : null}

        {requestState.visible ? (
          <RequestForConsent
            {...requestState}
            onClose={() => this.events.hideRequestConsentDeal()}
            onSubmit={() => {
              this.events.hideRequestConsentDeal()
              this.tableRef.current.reload()
            }}
          />
        ) : null}
      </div>
    )

  }
}


const mapStateToProps = ({global}) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentListByDirectUniversity)
