import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {CanadaInnerBlock, InnerBlockNew, UkInnerBlock} from './reportCountryWise'
import moment from 'moment'
import {GetCountryCountRight} from "../views/DashboardUserWise";
import {departmentObj} from "../../../components/_utils/appUtils";
import {
    canadaApplicationSteps,
    ukApplicationSteps,
    australiaApplicationSteps,
    usaApplicationSteps
} from "../../../components/_utils/appUtils";

const $ = window.$
const ReportingComponent = (props) => {
    let {
        userCountryName,
        userData,
        department,
        userId,
        toDate,
        fromDate,
        userType,
        directUniversityCount
    } = props;
    let [showMenu, setSHowMenu] = useState(false)
    let [country, setCountry] = useState('')
    let countryName = country.toLowerCase()
    let dispatch = useDispatch()
    let chooseCountry = (value) => {
        setCountry(value)
        setSHowMenu(false)
    }
    useEffect(() => {
        setCountry(userCountryName)
    }, [userCountryName])

    let openApplicationList = (data) => {
        let params = {
            countryId: data.countryId,
            userId: userId,
            department: departmentObj.marketing,
            userType: userType,
            status: data.status == 'Draft Applications' ? 'Waiting To Apply' : data.status
        }
        if (fromDate) {
            params.fromDate = moment(fromDate).format('MM-DD-YYYY');
            params.toDate = moment(toDate).format('MM-DD-YYYY');
        }


        dispatch(
            getUrlPushWrapper('marketingReportingApplicationList', params))
    }

    if (countryName == 'uk') {
        countryName = 'kingdom'
    }

    let openDirectUniversity = ({universityId, status}) => {
        let params = {
            userId: userId,
            department: departmentObj.marketing,
            userType: userType,
            appUniversityId: universityId
        }
        if (status) {
            params.status = status;
        }
        if (fromDate) {
            params.fromDate = moment(fromDate).format('MM-DD-YYYY');
            params.toDate = moment(toDate).format('MM-DD-YYYY');
        }
        dispatch(getUrlPushWrapper('directUniversityMarketingReport', params))
    }

    return (
        <React.Fragment>
            <div className='d-md-flex pattern-img align-items-center'>
                <div className='flex-fill map-section' style={{overflow: 'hidden'}}>
                    <div className='tab-content' id='v-pills-tabContent'>
                        <div className='tab-pane fade show active' id='v-pills-canada' role='tabpanel'
                             aria-labelledby='v-pills-canada-tab'>
                            <div className='p-1 flex-fill map-section' style={{overflow: 'hidden'}}>
                                <img src={`/dist/img/${countryName}-map.png`} alt=''/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-pane-right listing-section' style={{width: '35%'}}>
                    <div className='nav flex-column nav-pills w-100' id='v-pills-tab' role='tablist'
                         aria-orientation='vertical'>
                        {countryName == 'canada' ?
                            <a className={`nav-link ${countryName == 'canada' ? 'active' : ''}`} id='v-pills-canada-tab'
                               data-toggle='pill' href='#v-pills-canada' role='tab'
                               onClick={() => chooseCountry('Canada')}
                               aria-controls='v-pills-canada' aria-selected='true'>canada</a> : null}

                        {countryName == 'australia' ?
                            <a className={`nav-link ${countryName == 'australia' ? 'active' : ''}`}
                               id='v-pills-australia-tab'
                               data-toggle='pill' href='#v-pills-australia' role='tab'
                               onClick={() => chooseCountry('Australia')}
                               aria-controls='v-pills-australia' aria-selected='false'>australia</a> : null}

                        {countryName == 'kingdom' ?
                            <a className={`nav-link ${countryName == 'kingdom' ? 'active' : ''}`}
                               id='v-pills-kingdom-tab'
                               data-toggle='pill' href='#v-pills-kingdom' role='tab'
                               onClick={() => chooseCountry('UK')}
                               aria-controls='v-pills-kingdom' aria-selected='false'>united
                                kingdom</a> : null}

                        {countryName == 'usa' ?
                            <a className={`nav-link ${countryName == 'usa' ? 'active' : ''}`} id='v-pills-kingdom-tab'
                               data-toggle='pill' href='#v-pills-kingdom' role='tab'
                               onClick={() => chooseCountry('USA')}
                               aria-controls='v-pills-kingdom' aria-selected='false'>USA</a> : null}


                    </div>
                </div>
            </div>

            {userData ?
                <div>
                    {country == 'UK' && userData && userData.UK && userData.UK.countryId ?
                        <GetCountryCountRight user={userData} country={country}
                                              userCountryName={userCountryName}
                                              countryName={'uk'}>
                            <UkInnerBlock data={userData.UK} statusList={ukApplicationSteps}
                                          userData={userData}
                                          userType={userType}
                                          department={department}
                                          directUniversityCount={directUniversityCount}
                                          userCountryName={country}
                                          callback={(countryId, status) => {
                                              openApplicationList({countryId: userData.UK.countryId, status})
                                          }}/>
                        </GetCountryCountRight> : ''}

                    {country == 'USA' && userData && userData.USA && userData.USA.countryId ?
                        <GetCountryCountRight user={userData} country={country}
                                              userCountryName={userCountryName}
                                              countryName={'usa'}>
                            <InnerBlockNew data={userData.USA} statusList={usaApplicationSteps}
                                           userData={userData}
                                           userType={userType}
                                           department={department}
                                           userCountryName={country}
                                           callback={(countryId, status) => {
                                               openApplicationList({countryId: userData.USA.countryId, status})
                                           }}/>
                        </GetCountryCountRight> : ''}


                    {country == 'Canada' && userData && userData.Canada && userData.Canada.countryId ?
                        <GetCountryCountRight user={userData}
                                              country={country}
                                              userCountryName={userCountryName}
                                              countryName={'canada'}>
                            <CanadaInnerBlock data={userData.Canada} statusList={canadaApplicationSteps}
                                              userData={userData}
                                              userType={userType}
                                              department={department}
                                              directUniversityCount={directUniversityCount}
                                              userCountryName={country}
                                              openDirectUni={(data) => {
                                                  openDirectUniversity(data)
                                              }}
                                              callback={(countryId, status) => {
                                                  openApplicationList({countryId: userData.Canada.countryId, status})
                                              }}/>
                        </GetCountryCountRight> : ''}

                    {country == 'Australia' && userData && userData.Australia && userData.Australia.countryId ?
                        <GetCountryCountRight user={userData} country={country}
                                              userCountryName={userCountryName}
                                              countryName={'australia'}>
                            <InnerBlockNew data={userData.Australia}
                                           userData={userData}
                                           userType={userType}
                                           department={department}
                                           userCountryName={country}
                                           statusList={australiaApplicationSteps}
                                           callback={(countryId, status) => {
                                               openApplicationList({countryId: userData.Australia.countryId, status})
                                           }}/>
                        </GetCountryCountRight> : ''}


                </div>
                : ''}


        </React.Fragment>
    )
}

export default ReportingComponent
