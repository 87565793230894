import React, { useEffect, useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import {
  Tooltip,
  Button,
  Drawer,
  Popconfirm,
  Form,
  Select, Input, notification
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  displayDate,
  filterOption,
  InputBox,
  longDisplayDate, DefaultTablePagination
} from '../../../components/_utils/appUtils'
import {
  listFeedback,
  deleteFeedback,
  assignToUserFxn,
  closeFeedbackFxn,
  singleFeedbackFxn
} from '../actions/feedbackActions'
import { listAllUsers } from '../../users/actions/user'
import _ from 'lodash'
import AddCommentDrawer from './addCommentDrawer'
import WrappedEditComplaintDrawer from './editFeedbackDrawer'

const RenderComment = props => {
  let { item } = props
  let { resolveBy } = item
  let [hideV, setHideV] = useState(true)
  return (
    item && item.details ?
      <div>
        {resolveBy && resolveBy.name ? (
          <div className={'alignRight colorPrimary font12'}>
            (Resolve By : {resolveBy.name} )
          </div>
        ) : null}

        <div className={'mb10'}>{longDisplayDate(resolveBy.time)}</div>

        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.details ? item.details : ''}
        </div>
        {item && item.details.length && item.details.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : null}
      </div> : null
  )
}
const RenderComplaint = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && item.feedback ?
      <div>
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.feedback ? item.feedback : ''}
        </div>
        {item && item.feedback.length && item.feedback.length > 70 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a className={'btn-link'}
                 onClick={() => {
                   setHideV(false)
                 }}>
                Show More
              </a>
            ) : (
              <a className={'btn-link'}
                 onClick={() => {
                   setHideV(true)
                 }}>
                Show Less
              </a>
            )}
          </div>
        ) : null}
      </div> : null
  )
}
const AllFeedBacks = () => {
  const { Option } = Select
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  // let [countryId, setCountryId] = useState('')
  let [isModalOpen, setIsModalOpen] = useState(false)
  let [drawerOpen, setDrawerOpen] = useState(false)
  let [singleFeedback, setSingleFeedback] = useState({})
  let [compliantId, setComplaintId] = useState('')
  let [userData, setUserData] = useState([])
  let [total, setTotal] = useState(0)
  let initialState = {
    assignedTo: '',
    feedbackId: '',
    details: ''
  }
  const [state, setState] = useState(initialState)
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let resp = await dispatch(listFeedback({ ...params, regExFilters: ['feedback'] }))
      setTotal(resp.total)
      resolve(resp)
    })
  }
  const getSingleComplaint = async () => {
    const singleComplaint = await dispatch(singleFeedbackFxn({ feedbackId: compliantId }))
    setSingleFeedback(singleComplaint.data)
  }

  useEffect(() => {
    if (compliantId != null) {
      getSingleComplaint()
    }
  }, [compliantId])

  const AllbranchUsers = async () => {
    let resp = await dispatch(listAllUsers({
      userType: ['branchUser', 'branchManager'],
      results: 500,
      count: 500,
      block: false,
      select: ['name', 'email']
    }))
    setUserData(resp.data)
  }
  useEffect(() => {
    AllbranchUsers()
  }, [])

  const [updateEventSlot, setUpdateEventSlot] = useState({
    visible: false,
    feedbackId: ''
  })

  const [updateFeedBack, setUpdateFeedback] = useState({
    visible: false,
    feedbackId: ''
  })

  const events = {
    showUpdateState: (feedbackId) => {
      setUpdateEventSlot({
        visible: true,
        feedbackId: feedbackId
      })
    },
    hideUpdateState: async () => {
      tableRef.current.reload()
      setUpdateEventSlot({
        visible: false,
        eventId: ''
      })
    },
    submitUpdateState: () => {
      tableRef.current.reload()
      setUpdateEventSlot({
        visible: false,
        eventId: ''
      })
      setUpdateFeedback({
        visible: false,
        eventId: ''
      })
    },
    showUpdateDrawer: (feedbackId) => {
      setUpdateFeedback({
        visible: true,
        feedbackId: feedbackId
      })
    },
    hideUpdateDrawer: async () => {
      tableRef.current.reload()
      setUpdateFeedback({
        visible: false,
        eventId: ''
      })
    }
  }


  let columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Feedback & Complaints',
      key: 'feedback',
      dataIndex: 'feedback',
      width: 250,
      searchTextName: 'feedback',
      render: (item, record) => {
        return (
          <RenderComplaint item={record} />
        )
      }
    },

    {
      title: 'Department',
      key: 'department',
      dataIndex: 'department',
      width: 200,
      render: (item, record) => {
        return (
          item && item.department ? <React.Fragment>
            {item.department}
          </React.Fragment> : <React.Fragment>
            {record && record.country && record.country.countryName ? `${record.country.countryName} - Department` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Person Name',
      key: 'personName',
      dataIndex: 'personName',
      searchTextName: 'personName',
      width: 120
    },
    {
      title: 'Student',
      key: 'studentId',
      dataIndex: 'studentId',
      width: 120,
      render: (item) => {
        return item && item.name ? <>
          {item.name} (ID:{item.studentId})
        </> : ''
      }
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? displayDate(item) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Agent',
      dataIndex: 'userId.name',
      key: 'userId.name',
      width: 150
    },

    {
      title: 'Assigned To',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      hidden: !(user && (user.userType == 'admin' || user.userType == 'userManager' || user.userType == 'hr')),
      width: 100,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.name ? item.name : null}
          </React.Fragment>
        )
      }
    },
    /*
        {
          title: 'Detail',
          dataIndex: 'details',
          key: 'details',
          width: 250,
          render: (item, record) => {
            return (
              <>
                <RenderComment item={record} />
              </>
            )
          }
        },*/
    {
      title: 'Agent Feedback',
      dataIndex: 'agentFeedbackStatus',
      key: 'agentFeedbackStatus',
      hidden: !(user && (user.userType == 'admin' || user.userType == 'userManager' || user.userType == 'hr')),
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? <div className={'alignCenter'}>
              {item == 'unsatisfied' ? <a className={'btn btn-danger roundNew sm'}>Agent is Not Satisfied</a> : ''}
              {item == 'satisfied' ? <a className={'btn btn-success-green roundNew sm'}>Agent is Satisfied</a> : ''}
              <br />
              {record.agentFeedbackDate ? displayDate(record.agentFeedbackDate) : ''}

            </div> : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (item) => {
        return (
          <>
            {item == 'Resolve' ?
              <label className={'label label-success label-sm'}>Resolved</label> :
              <label className={'label label-warning label-sm'}>Pending</label>}
          </>
        )
      }
    },
    {
      title: 'Complaint By',
      dataIndex: 'complaintBy',
      key: 'complaintBy',
      width: 250,
      render: (item, record) => {
        let { name, designation, contactNo } = record
        return (
          name ? <>
            Name : {name}<br />
            Designation : {designation}<br />
            Contact No : {contactNo}
          </> : null
        )
      }
    },

    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 180,
      hidden: (user && (user.userType == 'agent' || user.userType == 'subAgent')),
      render: (val, record) => {
        let { assignedTo } = record
        return (
          <React.Fragment>
            {
              user && (user.userType === 'admin') ? (
                <Tooltip title='Edit'>
                  <Button
                    shape='circle'
                    icon='edit'
                    onClick={() => {
                      events.showUpdateDrawer(record._id)
                      setComplaintId(record._id)
                    }}
                  />
                </Tooltip>
              ) : null
            }

            {user && (user.userType == 'admin' || user.userType == 'hr') ? <>
              {!assignedTo || (assignedTo && !assignedTo._id) ?
                <Button
                  shape='circle'
                  icon='export'
                  onClick={() => {
                    setIsModalOpen(true)
                    setState({ feedbackId: record._id })
                  }}
                /> : null
              }

              <Tooltip title='Delete'>
                <Popconfirm
                  title={'Are your sure, you want to delete feedback?'}
                  onConfirm={() => {
                    deleteFeedbackFxn(val)
                  }}
                  okText='Yes' cancelText='No'>
                  <Button shape='circle' icon='delete' />
                </Popconfirm>
              </Tooltip>
            </> : null}
            <Tooltip title={'View Comment'}>
              <a className={'btn btn-sm btn-default'} onClick={() => {
                events.showUpdateState(record._id)
                setComplaintId(record._id)
              }}>
                <img src='/dist/img/view.svg' alt='' /> View
              </a>
            </Tooltip>


          </React.Fragment>
        )
      }
    }
  ]


  const deleteFeedbackFxn = async (_id) => {
    await dispatch(deleteFeedback(_id))
    tableRef.current.reload()
  }

  const assignToFxn = async () => {
    if (!state.assignedTo) {
      notification.warning({ message: 'Please choose user' })
      return
    }
    if (state.assignedTo && state.feedbackId) {
      let data = await dispatch(assignToUserFxn(state))
      if (data.success) {
        setState(initialState)
        setIsModalOpen(false)
        tableRef.current.reload()
      }
    }
  }

  const chooseUserFxn = (value) => {
    setState({
      ...state,
      assignedTo: value
    })
  }

  const handleDrawerSubmit = async () => {
    if (!state.details) {
      notification.warning({ message: 'Enter details' })
      return
    }
    await dispatch(closeFeedbackFxn({ feedbackId: state.feedbackId, details: state.details }))
    tableRef.current.reload()
    setDrawerOpen(false)
    setUpdateEventSlot(
      {
        visible: false,
        feedbackId: ''
      }
    )
    setComplaintId('')

  }
  useEffect(() => {
    if (!drawerOpen) {
      tableRef.current.reload()
    }
  }, [drawerOpen])

  const getColumns = () => {
    let newCol = _.filter(columns, (item) => {
      return !item.hidden
    })
    return newCol
  }

  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Feedback & Complaints (Total : {total})</h5>
              <div className='search-box-table'>

              </div>

              <div className='sort-box-table mark-btn'>

              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp columns={getColumns()}
                         pagination={DefaultTablePagination({}, 20)}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         extraProps={{ scroll: { x: 1800 } }}
              />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title={'Assign To'}
        visible={isModalOpen}
        placement='right'
        closable={true}
        onClose={() => {
          setIsModalOpen(false)
        }}
        width={'40%'}>

        <div className='form-box'>
          <div className='card unizportal'>
            <Form
              className={'vertical-form'}>
              <InputBox title={'Select User'}>
                <Select
                  showSearch={true}
                  autoClearSearchValue={true}
                  allowClear={true}
                  showArrow={true}
                  value={state.assignedTo}
                  onChange={chooseUserFxn}
                  filterOption={filterOption}
                  placeholder='Choose User'>
                  {userData && userData.length && userData.map((item, index) => {
                    return (
                      <Option key={item._id} value={item._id}>
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </InputBox>
              <Form.Item>
                <Button type='primary' htmlType='submit' className={'btn'} onClick={
                  () => {
                    assignToFxn(state)
                  }
                }> SAVE </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

      </Drawer>
      <Drawer
        title={'Resolve Complaint'}
        visible={drawerOpen}
        placement='right'
        closable={true}
        onClose={() => {
          setDrawerOpen(false)
        }}
        width={'40%'}
      >
        <div className='form-box'>
          <div className='card unizportal'>
            <Form className={'vertical-form'}>
              <InputBox title={'Details'}>
                <Form.Item name='details'>
                  <Input.TextArea
                    rows={4}
                    placeholder='Enter details'
                    value={state.details}
                    onChange={(e) => setState({ ...state, details: e.target.value })}
                  />
                </Form.Item>
              </InputBox>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className={'btn'}
                  onClick={handleDrawerSubmit}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>

      {updateEventSlot.visible ? <AddCommentDrawer
        {...updateEventSlot}
        dispatch={dispatch}
        onSubmit={events.submitUpdateState}
        onClose={() => {
          events.hideUpdateState()
        }}
        currentUser={user}
        singleFeedback={singleFeedback}
        feedbackId={compliantId}
        setComplaintId={setComplaintId}
        setState={setState}
        setDrawerOpen={setDrawerOpen}
        setUpdateEventSlot={setUpdateEventSlot}
      /> : null}

      {updateFeedBack.visible ?
        <WrappedEditComplaintDrawer
          {...updateFeedBack}
          dispatch={dispatch}
          onSubmit={events.submitUpdateState}
          onClose={() => {
            events.hideUpdateDrawer()
          }}
          currentUser={user}
          singleFeedback={singleFeedback}
          feedbackId={compliantId}
          setComplaintId={setComplaintId}
          setState={setState}
          setDrawerOpen={setDrawerOpen}
          setUpdateEventSlot={setUpdateEventSlot}
        /> : null}
    </div>

  )
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllFeedBacks)
