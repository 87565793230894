import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Popconfirm,
  Tooltip, Tag, Avatar, Icon, Row, Col, Select, Switch
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector, connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getUrlPushWrapper } from '../../../routes'
import {
  listAllUniversities,
  UniversityIsFeatured,
  deleteUniversity,
  exportUniversityList, starUniversity, updateUniversity, updateTopUniversityFxn
} from '../actions/university'
import moment from 'moment'
import {
  UniversityRefArr,
  UniversityTypes,
  ConditionOptions, filterOption, departmentObj
} from '../../../components/_utils/appUtils'
import { listAllStates } from '../../state/actions/state'
import { listAllCountries } from '../../countries/actions/countries'
import { push } from 'connected-react-router'
import UniversityCampus from '../../campus/views/universityCampus'
import { UniversityEligibility } from '../drawers/universityEligibility'
import ListAllCourseDrawer from '../drawers/UniversityCourseDrawer'
import { listAllUsers } from '../../users/actions/user'
import { UniversityBranchUserComponent } from '../../../components/_utils/AgentBranchManagerComponent'
import { listAllCities } from '../../city/actions/city'
import InputBox from '../../../components/_utils/InputBox'

const { Option } = Select

const ListAllUniversities = (props) => {
  let { currentUser } = props
  let [allStates, setAllState] = useState([])
  let [allCountries, setAllCountries] = useState([])
  let [allCity, setAllCity] = useState([])
  let [stateId, setStateId] = useState('')
  let [cityId, setCityId] = useState('')
  let [countryId, setCountryId] = useState('')
  let [branchUserId, setBranchUserId] = useState('')
  let [universityName, setUniversityName] = useState('')
  let [filters, setFilters] = useState({})
  let [visibleCampus, setVisibleCampus] = useState(false)
  let [visibleCourses, setVisibleCourses] = useState(false)
  let [branchUserList, setBranchUserList] = useState([])

  let [campusUniversity, setCampusUniversity] = useState({
    id: '',
    universityName: ''
  })
  let [universityCourse, setUniversityCourse] = useState({
    id: '',
    universityId: '',
    universityName: ''
  })
  let [eligDrawer, setEligDrawer] = useState({
    universityId: '',
    universityName: '',
    visible: false
  })


  let [tableSearch, setTableSearch] = useState(false)
  const { totalUniversities } = useSelector((state) => ({
    totalUniversities: state.universityReducers.totalUniversities
  }))

  const tableRef = useRef()
  const dispatch = useDispatch()

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {

      params.sortField = 'universityName'
      params.sortOrder = 'ascend'
      let resp = await getParamsForFilter()

      if (resp.countryId) {
        params.universityCountry = resp.countryId
      }
      if (resp.stateId) {
        params.universityState = resp.stateId
      }
      if (resp.cityId) {
        params.universityCity = resp.cityId
      }
      if (resp.universityName) {
        params.universityName = resp.universityName
      }
      if (resp.branchUserId) {
        params.branchUserId = resp.branchUserId
      }

      let page = params.page

      if (currentUser.userType == 'branchManager' && !currentUser.branchManagerType) {
        params.universityCountry = currentUser.countryId
      }

      if (tableSearch) {
        params.page = 1
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      params.showUniversity = [true, false]
      setFilters(params)
      let respData = await dispatch(listAllUniversities({
        ...params,
        page: page,
        regExFilters: ['universityType', 'universityName']
      }))
      resolve(respData)
      setTableSearch(false)
    })
  }

  const loadBranchUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.offerDepartment,
      customQuery: {
        'universities.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'email', 'mobile', 'universities']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setBranchUserList(data)
  }

  const [itemNo, setItemNo] = useState(1)
  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteUniversity(id))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }


  const exportUniversityFxn = async () => {
    let params = { ...filters }
    params.regExFilters = ['universityType', 'universityName']
    params.results = totalUniversities
    params.count = totalUniversities
    let respE = await dispatch(exportUniversityList(params))
    if (respE && respE.filePath) {
      window.open(respE.filePath, '_blank')
    } else {
      notification.warning({ message: 'Error, please try again' })
      return
    }
  }

  useEffect(() => {
    loadAllCountry()
    loadBranchUsers()
    events.setFieldByParams()
  }, [])


  const loadAllState = async (id) => {
    let { data } = await dispatch(listAllStates({
      results: 1000,
      customQuery: {
        'country': id
      }
    }))
    setAllState(data)
  }

  const loadAllCities = async (id) => {
    let { data } = await dispatch(listAllCities({ results: 10000, customQuery: { 'state': id } }))
    setAllCity(data)
  }
  const loadAllCountry = async () => {
    let filterObj = {
      results: 100,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    if (currentUser.userType == 'branchManager' && !currentUser.branchManagerType) {
      filterObj.customQuery = {
        '_id': currentUser.countryId
      }
    }
    let { data } = await dispatch(listAllCountries(filterObj))
    setAllCountries(data)
  }


  const updateUniversityFeatureStatus = async (id) => {
    let resp = await dispatch(UniversityIsFeatured({ _id: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }
  const updateTopUniversity = async (id) => {
    let resp = await dispatch(updateTopUniversityFxn({ _id: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }
  const updateStarUniversityStatus = async (id) => {
    let resp = await dispatch(starUniversity({ _id: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }

  const getParamsForFilter = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let stateId = searchParams.get('stateId')
      let cityId = searchParams.get('cityId')
      let universityName = searchParams.get('universityName')
      let branchUserId = searchParams.get('branchUserId')
      let obj = {}
      if (countryId) {
        obj.countryId = countryId
        loadAllState(countryId)
      }
      if (cityId) {
        obj.cityId = cityId
      }
      if (stateId) {
        obj.stateId = stateId
        loadAllCities(stateId)
      }
      if (universityName) {
        obj.universityName = universityName
      }
      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      resolve(obj)
    })
  }

  const events = {
    searchData: async () => {
      let obj = {}
      let searchParams = new URLSearchParams(window.location.search)
      let showUni = searchParams.get('showUni')
      if (countryId) {
        obj.countryId = countryId
      }
      if (universityName) {
        obj.universityName = universityName
      }
      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (stateId) {
        obj.stateId = stateId
      }
      if (cityId) {
        obj.cityId = cityId
      }

      if (showUni) {
        obj.showUni = showUni
      }

      dispatch(
        getUrlPushWrapper('masters.allUniversities', {
          ...obj
        }))
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)
    },
    setFieldByParams: async () => {
      let resp = await getParamsForFilter()
      if (resp.universityName) {
        setUniversityName(resp.universityName)
      }
      if (resp.countryId) {
        setCountryId(resp.countryId)
      }
      if (resp.stateId) {
        setStateId(resp.stateId)
      }
      if (resp.cityId) {
        setCityId(resp.cityId)
      }
      if (resp.branchUserId) {
        setBranchUserId(resp.branchUserId)
      }
    },
    goBack: () => {
      dispatch(getUrlPushWrapper('dashboard'))
    },
    clearFxn: () => {
      setUniversityName('')
      setCountryId('')
      setStateId('')
      setCityId('')
      setBranchUserId('')
      dispatch(
        getUrlPushWrapper('masters.allUniversities'))
      setTimeout(() => {
        tableRef.current.reload()
      }, 200)

    },
    hideVisibleCampus: () => {
      setVisibleCampus(false)
      setCampusUniversity({
        id: '',
        universityName: ''
      })
    },
    hideVisibleCourse: () => {
      setVisibleCourses(false)
      setUniversityCourse({
        id: '',
        universityId: '',
        universityName: ''
      })
    },
    hideEligDrawer: () => {
      setEligDrawer({
        universityId: '',
        universityName: '',
        visible: false
      })
    },
    updateVisibilityOfUniversity: async (uniObj) => {
      let universityId = uniObj._id
      let obj = {
        showUniversity: !uniObj.showUniversity
      }
      let fd = new FormData()
      fd.append('obj', JSON.stringify(obj))
      let resp = await dispatch(updateUniversity(fd, universityId))
      if (resp && resp.success) {
        tableRef.current.reload()
      }
    }
  }

  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editUniversity', {
                id: record._id
              }))
          }}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }
    },

    {
      key: 'actions',
      title: 'Actions',
      width: 90,
      render: (text, record) => {
        let { universityCountry: { countryName } } = record
        return (
          <div className={'pa-0'}>
            <Tooltip title='Edit'>
              <button
                className={'btn'}
                style={{ marginRight: 6 }}
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('masters.editUniversity', {
                      id: record._id
                    }))
                }}
              >
                <Icon type={'edit'} />
              </button>
            </Tooltip>
            {(currentUser && (currentUser.userType == 'admin' || currentUser.userType == 'master')) ?
              <>
                <Tooltip title='Delete'>
                  <Popconfirm title={'Are your sure, you want to delete university?'}
                              onConfirm={() => {
                                confirmDelete(record._id)
                              }}
                              okText='Yes' cancelText='No'>
                    <button
                      className={'btn'}
                      style={{ marginRight: 6 }}>
                      <Icon type={'delete'} />
                    </button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip title='Campus'>
                  <button
                    className={'btn'}
                    onClick={() => {
                      setCampusUniversity({
                        id: record._id,
                        universityName: record.universityName
                      })
                      setVisibleCampus(true)
                    }}
                    style={{ marginRight: 6 }}>
                    <Icon type={'eye'} />
                  </button>
                </Tooltip>
                <Tooltip title='Course'>
                  <button
                    className={'btn'}
                    onClick={() => {
                      setUniversityCourse({
                        id: record._id,
                        universityId: record._id,
                        universityName: record.universityName
                      })
                      setVisibleCourses(true)
                    }}
                    style={{ marginRight: 6 }}>
                    <Icon type={'read'} />
                  </button>
                </Tooltip>
              </> : null}

            {(countryName.toLowerCase() == 'uk' || countryName.toLowerCase() == 'canada' || countryName.toLowerCase() == 'usa' || countryName.toLowerCase() == 'australia') &&
            <Tooltip title='View Eligibility'>
              <button
                className={'btn'}
                onClick={() => {
                  setEligDrawer({
                    universityId: record._id,
                    universityName: record.universityName,
                    visible: true
                  })
                }}
                style={{ marginRight: 6 }}>
                <img src={'../dist/img/research.png'} />
              </button>
            </Tooltip>
            }
          </div>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'universityName',
      width: 150,
      key: 'universityName',
      // searchTextName: 'universityName',
      render: (item, record) => {
        return (
          <>
            {record.universityReference == 'Direct with Ryan' ?
              <img src={'/assets/letter-r.png'} style={{ height: 25, marginInline: 5 }} /> : null}
            {record.universityReference == 'Direct with Unizportal' ?
              <img src={'/assets/letter-u.png'} style={{ height: 25, marginInline: 5 }} /> : null}
            {record.universityReference == 'Indirect' ?
              <img src={'/assets/letter-i.png'} style={{ height: 25, marginInline: 5 }} /> : null}
            <a className={'linkPointer'} onClick={() => {
              dispatch(
                getUrlPushWrapper('masters.editUniversity', {
                  id: record._id
                }))
            }}>
              {item}
            </a>
          </>
        )
      }
    },
    {
      title: 'Branch User',
      dataIndex: 'branchUser',
      width: 120,
      key: 'branchUser',
      // filters: branchUserList.map(x => ({ value: x._id, text: x.name })),
      render: (item, record) => {
        return (
          <React.Fragment>
            {branchUserList && branchUserList.length ?
              <UniversityBranchUserComponent universityId={record._id}
                                             branchUsers={branchUserList} /> : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Top University',
      dataIndex: 'topUniversity',
      width: 120,
      key: 'topUniversity',
      filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
      render: (item, record) => {
        return (
          <div>
            <Popconfirm
              title={`Are your sure, you want to ${item ? 'Remove from' : 'Add in'} top university?`}
              onConfirm={() => {
                updateTopUniversity(record._id)
              }}
              okText='Yes' cancelText='No'>
              {item ? <div className={'label label-success labelNew'}>Yes</div> :
                <div className={'label label-warning labelNew'}>No</div>}
            </Popconfirm>
          </div>
        )
      }
    },
    {
      title: 'Featured',
      dataIndex: 'isFeatured',
      width: 120,
      key: 'isFeatured',
      filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
      render: (item, record) => {
        return (
          <div>
            <Popconfirm
              title={`Are your sure, you want to ${item ? 'Non-Featured' : 'Featured'} this university?`}
              onConfirm={() => {
                updateUniversityFeatureStatus(record._id)
              }}
              okText='Yes' cancelText='No'>
              {item ? <div className={'label label-success labelNew'}>Yes</div> :
                <div className={'label label-warning labelNew'}>No</div>}
            </Popconfirm>
          </div>
        )
      }
    },
    // {
    //     title: 'Star University',
    //     dataIndex: 'starUniversity',
    //     width: 120,
    //     key: 'starUniversity',
    //     className: {display: 'none'},
    //     filters: ConditionOptions.map(x => ({value: x.value, text: x.name})),
    //     render: (item, record) => {
    //         return (
    //             <div>
    //                 <Popconfirm title={`Are your sure, you want to ${item ? 'Non-Star' : 'Star'} this university?`}
    //                             onConfirm={() => {
    //                                 updateStarUniversityStatus(record._id)
    //                             }}
    //                             okText='Yes' cancelText='No'>
    //                     {item ? <div className={'label label-success labelNew'}>Yes</div> :
    //                         <div className={'label label-warning labelNew'}>No</div>}
    //                 </Popconfirm>
    //             </div>
    //         )
    //     }
    // },
    /*{
      title: 'Show University',
      dataIndex: 'showUniversity',
      width: 120,
      key: 'showUniversity',
      className: { display: 'none' },
      filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
      render: (item, record) => {
        return (
          <div>
            <Switch
              checked={item}
              checkedChildren={'Show'}
              unCheckedChildren={'Hide'}
              onClick={(e) => {
                events.updateVisibilityOfUniversity(record)
              }} />
            {/!*<span className={'ml10'}>Show Zero Case Branch User</span>*!/}
          </div>
        )
      }
    },*/
    {
      title: 'Logo',
      dataIndex: 'logo',
      width: 140,
      key: 'logo',
      render: (item) => {
        return item && item.url ? <img src={item.url} style={{ width: 120, height: 40 }} /> : null
      }
    },
    {
      title: 'Icon',
      dataIndex: 'smallLogo',
      width: 80,
      key: 'smallLogo',
      render: (item) => {
        return item && item.url ? <Avatar src={item.url} size={30} /> : null
      }
    },
    {
      title: 'Reference',
      dataIndex: 'universityReference',
      width: 150,
      key: 'universityReference',
      filters: UniversityRefArr.map(x => ({ value: x, text: x }))
    },
    {
      title: 'Country',
      key: 'universityCountry',
      width: 150,
      dataIndex: 'universityCountry.countryName'
      // filters: allCountries.map(x => ({ value: x._id, text: x.countryName }))
      // searchTextName: 'universityCountry',
    },
    {
      title: 'State',
      key: 'universityState',
      width: 150,
      dataIndex: 'universityState.stateName'
      // filters: allStates.map(x => ({ value: x._id, text: x.stateName }))
      // searchTextName: 'universityState',
    },
    {
      title: 'City',
      dataIndex: 'universityCity.cityName',
      width: 150,
      key: 'universityCity'
      // searchTextName: 'universityCity',

    },
    {
      title: 'Website',
      dataIndex: 'universityWebsite',
      width: 150,
      key: 'universityWebsite'
      // searchTextName: 'universityWebsite',

    },
    {
      title: 'Type',
      dataIndex: 'universityType',
      width: 150,
      key: 'universityType',
      filters: UniversityTypes.map(x => ({ value: x, text: x }))

    },
    /*{
      title: 'Partnership',
      dataIndex: 'universityPartnership',
      width: 150,
      key: 'universityPartnership'
      // searchTextName: 'universityPartnership',
    },
    {
      title: 'Elicos',
      dataIndex: 'elicos',
      width: 150,
      key: 'elicos'
      // searchTextName: 'elicos',
    },*/
    {
      title: 'Contact Address',
      dataIndex: 'contactAddress',
      width: 150,
      key: 'contactAddress'
      // searchTextName: 'contactAddress',
    },
    {
      title: 'Application Fee',
      dataIndex: 'applicationFee',
      width: 150,
      key: 'applicationFee'
      // searchTextName: 'applicationFee',
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      width: 150,
      key: 'contactName'
      // searchTextName: 'contactName',
    },
    {
      title: 'Contact Phone',
      dataIndex: 'contactPhone',
      width: 150,
      key: 'contactPhone'
      // searchTextName: 'contactPhone',
    },
    {
      title: 'Contact Email',
      dataIndex: 'contactEmail',
      width: 150,
      key: 'contactEmail'
      // searchTextName: 'contactEmail',
    },
    /* {
       title: 'Enrollment Documents',
       dataIndex: 'enrollmentDocuments',
       width: 150,
       key: 'enrollmentDocuments'
       // searchTextName: 'enrollmentDocuments',
     },
     {
       title: 'Enrollment Procedure',
       dataIndex: 'enrollmentProcedure',
       width: 150,
       key: 'enrollmentProcedure'
       // searchTextName: 'enrollmentProcedure',
     },
     {
       title: 'Enrollment Admission Officer',
       dataIndex: 'enrollmentAdmissionOfficer',
       width: 150,
       key: 'enrollmentAdmissionOfficer'
       // searchTextName: 'enrollmentAdmissionOfficer',
     },
     {
       title: 'Enrollment Confirmation',
       dataIndex: 'enrollmentConfirmation',
       width: 150,
       key: 'enrollmentConfirmation'
       // searchTextName: 'enrollmentConfirmation',
     },*/
    {
      title: 'Agreement Signing Date',
      dataIndex: 'agreementSigningDate',
      width: 150,
      key: 'agreementSigningDate',
      // searchTextName: 'agreementSigningDate',
      render: (value) => value ? moment(value).format('ll') : ''

    }
    /*{
      title: 'Enrollment Expiry',
      dataIndex: 'enrollmentExpiry',
      width: 150,
      key: 'enrollmentExpiry',
      // searchTextName: 'enrollmentExpiry',
      render: (value) => value ? moment(value).format('ll') : ''
    },
    {
      title: 'Enrollment Renewal',
      dataIndex: 'enrollmentRenewal',
      width: 150,
      key: 'enrollmentRenewal',
      // searchTextName: 'enrollmentRenewal',
      render: (value) => value ? moment(value).format('ll') : ''


    },*/

    /* {
       title: 'Payment Terms',
       dataIndex: 'paymentTerms',
       width: 150,
       key: 'paymentTerms'
       // searchTextName: 'paymentTerms',
     },*/


  ]

  const getColumns = () => {
    let searchParams = new URLSearchParams(window.location.search)
    let showUni = searchParams.get('showUni')
    if (showUni && currentUser && (currentUser.userType == 'admin')) {
      columns.push({
        title: 'Show University',
        dataIndex: 'showUniversity',
        width: 120,
        key: 'showUniversity',
        className: { display: 'none' },
        filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
        render: (item, record) => {
          return (
            <div>
              <Switch
                checked={item}
                checkedChildren={'Show'}
                unCheckedChildren={'Hide'}
                onClick={(e) => {
                  events.updateVisibilityOfUniversity(record)
                }} />
              {/*<span className={'ml10'}>Show Zero Case Branch User</span>*/}
            </div>
          )
        }
      })
    }
    if (currentUser && (currentUser.userType == 'admin' || currentUser.userType == 'branchManager')) {
      columns.push({
          title: 'Commission Type',
          dataIndex: 'commissionType',
          width: 150,
          key: 'commissionType'
          // searchTextName: 'commission',
        },
        {
          title: 'UG Commission',
          dataIndex: 'ugCommission',
          width: 150,
          key: 'ugCommission',
          render: (item, record) => {
            return (
              <React.Fragment>
                {item && !_.isEmpty(item) ?
                  Object.keys(item).map((key, index) => {
                    return (
                      <React.Fragment>
                        <Tag className={'mb5'}>
                          {key} : {item[key]}
                        </Tag>
                        <br />
                      </React.Fragment>
                    )
                  }) :
                  ''}
              </React.Fragment>
            )
          }

        },
        {
          title: 'PG Commission',
          dataIndex: 'pgCommission',
          width: 150,
          key: 'pgCommission',
          render: (item, record) => {
            return (
              <div style={{ width: 150 }}>
                {item && !_.isEmpty(item) ?
                  Object.keys(item).map((key, index) => {
                    return (
                      <React.Fragment>
                        <Tag className={'mb5'}>
                          {key} : {item[key]}
                        </Tag>
                        <br />
                      </React.Fragment>
                    )
                  }) :
                  ''}
              </div>
            )
          }

        })
    }
    return columns
  }
  const chooseCountry = (id) => {
    setCountryId(id)
    setAllCity([])
    setAllState([])
    setStateId('')
    loadAllState(id)
  }
  const chooseState = (id) => {
    loadAllCities(id)
    setStateId(id)
    setAllCity([])
    setCityId('')
    // setAllCity(LoadCity(stateName.id))
  }


  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <Button onClick={() => events.goBack()}
                    className={'roundGoBackBtn'} icon={'arrow-left'}>
              Back
            </Button>

            <h5>All Universities: {totalUniversities || 0}</h5>


            <div className='sort-box-table mark-btn mark-btn40'>
              <div style={{ width: '100%' }}>
                <a className='btn' onClick={() => dispatch(push('/masters/add-university'))}>
                  <img src={'../dist/icons/plus.png'} className={'plus'} />
                  Add university</a>
                {' '}
                {currentUser && currentUser.userType == 'userManager' ? null :
                  <a className='btn' onClick={() => exportUniversityFxn()}> Export</a>}
              </div>
            </div>
          </div>
          <div className='card-body table-responsive'>
            <Row gutter={16} className={'filter_box'}>
              <Col span={4}>
                <InputBox title={'Search by country'}>
                  <Select onChange={(item) => {
                    chooseCountry(item)
                  }}
                          filterOption={filterOption}
                          placeholder='Country'
                          allowClear={true}
                          showSearch={true}
                          value={countryId || undefined}>
                    {/*<Option value={''}>Country</Option>*/}
                    {allCountries && allCountries.length ? allCountries.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>{item.countryName}</Option>
                      )
                    }) : null}
                  </Select>
                </InputBox>
              </Col>
              <Col span={4}>
                <InputBox title={'Search by university'}>
                  <input type='search'
                         placeholder='university name...'
                         value={universityName} onChange={(e) => {
                    setUniversityName(e.target.value)
                  }} />
                </InputBox>
              </Col>
              <Col span={4}>
                <InputBox title={'Search by branch user'}>
                  <Select onChange={(item) => setBranchUserId(item)} value={branchUserId}
                          filterOption={filterOption}
                          placeholder='Branch User'
                          allowClear={true}
                          showSearch={true}
                          value={branchUserId || undefined}>
                    {/*<Option value={''}>Country</Option>*/}
                    {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>{item.name}</Option>
                      )
                    }) : null}
                  </Select>
                </InputBox>
              </Col>


              <Col md={4}>
                <InputBox title={'Search by state'}>
                  <Select name='Search By State'
                          onChange={(item) => chooseState(item)}
                          filterOption={filterOption}
                          placeholder='State'
                          allowClear={true}
                          showSearch={true}
                          value={stateId || undefined}>
                    {allStates && allStates.length ? allStates.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>{item.stateName}</Option>
                      )
                    }) : null}
                  </Select>
                </InputBox>
              </Col>
              <Col md={4}>
                <InputBox title={'Search by city'}>
                  <Select name='Search By City'
                          onChange={(item) => setCityId(item)}
                          filterOption={filterOption}
                          placeholder='City'
                          allowClear={true}
                          showSearch={true}
                          value={cityId || undefined}>
                    {allCity && allCity.length ? allCity.map((item, key) => {
                      return (
                        <Option value={item._id} key={key}>{item.cityName}</Option>
                      )
                    }) : null}
                  </Select>
                </InputBox>
              </Col>

              <Col span={24}>
                <div className={'btn_group'}>
                  <a onClick={events.searchData} className={'default_btn'}>Search</a>
                  <a onClick={events.clearFxn} className={'default_btn'}>
                    Clear
                  </a>
                </div>
              </Col>
            </Row>
            <TableComp columns={getColumns()}
                       apiRequest={apiRequest}
                       pagination={{
                         position: 'top',
                         pageSizeOptions: ['50', '100', '200', '500'],
                         defaultPageSize: 50,
                         current: filters.page ? filters.page : 1
                       }}
                       ref={tableRef}
                       extraProps={{ scroll: { x: 600 } }}
            />
          </div>

        </div>
      </div>
      {visibleCampus ?
        <UniversityCampus visible={visibleCampus}
                          onClose={() => events.hideVisibleCampus()}
                          campusUniversity={campusUniversity.id}
                          universityName={campusUniversity.universityName}
        />
        : null}
      {visibleCourses ?
        <ListAllCourseDrawer visible={visibleCourses}
                             onClose={() => events.hideVisibleCourse()}
                             universityId={universityCourse.universityId}
                             universityName={universityCourse.universityName}
        />
        : null}
      {eligDrawer.visible ?
        <UniversityEligibility visible={eligDrawer.visible}
                               onClose={() => events.hideEligDrawer()}
                               universityId={eligDrawer.universityId}
        />
        : null}
    </div>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListAllUniversities)
