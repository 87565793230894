import {
  Avatar,
  Button,
  Col,
  Form,
  Icon,
  notification,
  Popconfirm,
  Row,
  Select,
  Tag,
  Tooltip
} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { ShowListOfAgentManager } from '../../../components/_utils/AgentBranchManagerComponent'
import {
  australiaApplicationSteps,
  canadaApplicationSteps,
  ConditionOptions,
  countryIds,
  defaultLogoList,
  departmentObj,
  filterOption,
  germanyApplicationSteps,
  Intakes,
  longDisplayDate,
  newFormatDisplayDate,
  showEnrollmentAction,
  statusColors,
  ukApplicationSteps,
  usaApplicationSteps
} from '../../../components/_utils/appUtils'
import { getPendingPendency } from '../../../components/_utils/utils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import { listAllCountries } from '../../countries/actions/countries'
import {
  CheckUserRight,
  ShowExportOption
} from '../../dashboard/views/DashboardUserWise'
import {
  applicationCloseDirect,
  reCalculateCommission,
  updateApplicationTuitionFees
} from '../../student/actions/student'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import { listAllUniversities } from '../../university/actions/university'
import { listAllUsers } from '../../users/actions/user'
import {
  AllowComponentRightsWise,
  AllowComponentUserWise,
  CheckBranchUserFxn,
  CheckBranchUserRight,
  CheckManagerWiseRight
} from '../../WebComponent/allowComponentRightsWise'
import {
  exportAllApplicationsList,
  getSelectedCountryApplicationsApi
} from '../actions/chooseStudent'
import AddNote from '../drawers/applicationAddNoteComponent'
import ChangeUniversityComponent from '../drawers/changeUniversity'
import Enrollment from '../drawers/enrollment'
import ReActivateApplication from '../drawers/reActivateApplicationDrawer'
import TransferApplicationDrawer from '../views/transferApplication'
// import DraftButtonComponent from './draftButtonComponent'
// import SelectPendency from './selectPendency'
// import StudentOtherApp from './StudentOtherApplication'

import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import { AddTuitionFeeDrawer } from '../drawers/AddTuitionFeeDrawer'
import UpdateManuallyMarketingUserDrawer from '../drawers/updateManuallyMarketingUserDrawer'
import { OshcDrawer, OshcFieldLabel } from '../OSHC/oshcDrawer'
import { draftApplicationListFxn } from './actions.'
import AddGicDraftDrawer from './addGicDrawer'

const managerViewRight = ['admin', 'branchUser']
const managerNewViewRight = ['admin', 'branchUser', 'branchManager']

const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
const commissionObj = {
  commissionAmount: 0,
  tuitionFee: 0,
  studentId: '',
  applicationId: ''
}
const RenderComment = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && (
      <div>
        {item.addedByUserId && item.addedByUserId.name ? (
          <div className={'alignRight colorPrimary font12'}>
            (Added By : {item.addedByUserId.name} )
          </div>
        ) : null}

        <div>{longDisplayDate(item.time)}</div>

        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.text ? item.text : ''}
        </div>
        {item && item.text.length && item.text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}
const DraftApplicationList = props => {
  let { studentObj = {}, currentUserRights } = props
  const [state, setState] = useState(initialState)
  const [transferAppVisible, setTransferAppVisible] = useState(false)
  const [studentData, setStudentData] = useState({})
  const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
  const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
  const [name, setName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [universityList, setUniversityList] = useState([])
  const [appUniversityId, setAppUniversityId] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [visibleFeesModal, setVisibleFeesModal] = useState(false)
  const [feesObj, setFeesObj] = useState(commissionObj)
  const [status, setStatus] = useState('')
  const [courseId, setCourseId] = useState('')
  const [countryId, setCountryId] = useState('')
  const [countryList, setCountryList] = useState([])
  const [branchUserList, setBranchUserList] = useState([])
  const [branchUserId, setBranchUserId] = useState('')
  const [branchManagerId, setBranchManagerId] = useState('')
  const [studentManagerId, setStudentManagerId] = useState('')
  const [draftApplication, setDraftApplication] = useState('')
  const [studentShore, setStudentShore] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [statusList, setStatusList] = useState([])
  let [currentNote, setCurrentNote] = useState('')
  let [intake, setIntake] = useState('')
  let viewOnlyRight = CheckBranchUserFxn() // check branch user right
  const [visibleTuitionFee, setVisibleTuitionFee] = useState(false)
  const [visibleOshcDrawer, setVisibleOshcDrawer] = useState(false)
  let [marketingUserId, setMarketingUserId] = useState('')
  let [selectedApplication, setSelectedApplication] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [enrollmentObj, setEnrollmentObj] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [changeUniversity, setChangeUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  let [branchManagerList, setBranchManagerList] = useState([])
  let [studentManagerList, setStudentManagerList] = useState([])
  let [marketingUserList, setMarketingUserList] = useState([])
  let [marketingManuallyObj, setMarketingManuallyObj] = useState({
    visible: false,
    studentId: '',
    application: {}
  })
  const [visibleAddGicDrawer, setVisibleAddGicDrawer] = useState(false)

  const dispatch = useDispatch()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableAppRef = useRef()

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }

      if (!params.countryId) {
        params.countryId = ''
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      params.type = 'all'
      try {
        setFilters(params)
        let resp = await dispatch(
          draftApplicationListFxn({
            ...params,
            regExFilters: ['name']
          })
        )
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }

  let exportExcel = async () => {
    let params = {}
    let resp = await getParamsForApplicationList()
    params = { ...params, ...resp }
    if (name) {
      params.name = name
    }
    if (dateOfBirth) {
      params.dateOfBirth = moment(dateOfBirth)
    }
    params.results = totalApplication
    params.count = totalApplication
    let { success, filePath, message } = await dispatch(
      exportAllApplicationsList(params)
    )
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }

  useEffect(() => {
    // loadAgentData()
    loadCountryList()
    setFieldByParams()
    // loadBranchManagerAgentList()
    loadBranchManager()
    loadStudentManager()
    // loadAllBranchManager()
    loadMarketingUsers()
    // LoadUserWiseUniversity()
    if (currentUserRights.userType == 'branchManager') {
      setCountryId(currentUserRights.countryId._id)
    }
  }, [])
  useEffect(
    () => {
      loadUniversityNameList()
      loadCountryStatus()
      loadCurrentUserData()
    },
    [countryId, countryList]
  )

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    // setCurrentStatus(resp.status)

    if (user.userType == 'admin') {
      if (resp.countryId) {
        setCountryId(resp.countryId)
      }
    }
    if (resp.name) {
      setName(resp.name)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.courseId) {
      setCourseId(resp.courseId)
    }
    if (resp.appUniversityId) {
      setAppUniversityId(resp.appUniversityId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.branchUserId) {
      setBranchUserId(resp.branchUserId)
    }
    if (resp.branchManagerId) {
      setBranchManagerId(resp.branchManagerId)
    }
    if (resp.studentManagerId) {
      setStudentManagerId(resp.studentManagerId)
    }
    if (resp.draftApplication) {
      setDraftApplication(resp.draftApplication)
    }
    if (resp.studentShore) {
      setStudentShore(resp.studentShore)
    }
    if (resp.status) {
      setStatus(resp.status)
    }
    if (resp.note) {
      setCurrentNote(resp.note)
    }

    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }
  }

  const loadAgentData = async () => {
    let params = {
      results: 15000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }
  const loadCountryStatus = async () => {
    let findCountry = lodash.find(countryList, item => {
      return item._id == countryId
    })
    if (findCountry) {
      if (findCountry.countryName.toLowerCase() == 'uk') {
        let temp = lodash.filter(ukApplicationSteps, item => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'canada') {
        let temp = lodash.filter(canadaApplicationSteps, item => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'australia') {
        let temp = lodash.filter(australiaApplicationSteps, item => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'australia_l1') {
        let temp = lodash.filter(australiaApplicationSteps, item => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'usa') {
        let temp = lodash.filter(usaApplicationSteps, item => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'germany') {
        let temp = lodash.filter(germanyApplicationSteps, item => {
          return item.name !== 'Case Close' && item.name !== 'New Student'
        })
        setStatusList(temp)
      }
    } else {
      setStatusList([])
    }
  }
  const loadCurrentUserData = async () => {
    if (countryId) {
      let params = {
        results: 1000,
        userType: 'branchUser',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'userType', 'mobile', 'email'],
        customQuery: {
          $or: [
            { countryId: countryId },
            { department: 'Visa Approved Department' }
          ]
        }
      }
      let { data } = await dispatch(listAllUsers(params))
      if (data && data.length) {
        setBranchUserList(data)
      }
    } else {
      setBranchUserList([])
    }
  }
  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    params.universityCountry = countryId

    let { data } = await dispatch(listAllUniversities(params))
    setAppUniversityId('')
    if (data && data.length) {
      setUniversityList(data)
    }
  }
  const loadBranchManager = async () => {
    let obj = {
      userType: 'branchManager',
      customQuery: {
        'agents.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'email', 'mobile', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setBranchManagerList(data)
  }
  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }
  const loadStudentManager = async () => {
    let obj = {
      userType: 'branchManager',
      branchManagerType: 'studentWise',
      results: 100,
      select: ['name', 'email', 'mobile', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setStudentManagerList(data)
  }

  const loadCountryList = async () => {
    let params = {
      results: 5000,
      select: ['countryName'],
      sortField: 'countryName',
      sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(params))
    if (data && data.length) {
      setCountryList(data)
    }
  }

  const events = {
    openAddPendencyDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setAddPendencyDrawer(true)
    },
    reloadAndClose: () => {
      tableAppRef.current.reload()
      setAddPendencyDrawer(false)
    },
    closeAddPendencyDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setAddPendencyDrawer(false)
    },
    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    showTransferApp: () => {
      setTransferAppVisible(true)
    },
    hideTransferApp: () => {
      setTransferAppVisible(false)
      tableAppRef.current.reload()
    },
    showAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(true)
    },
    hideAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(false)
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    closeDirectApp: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },

    enterName: value => {
      setName(value)
    },
    openOtherAppDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    closeOtherAppDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleOtherAppDrawer(false)
    },
    openAddCommentDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    },
    hideVisibleFeesModal: () => {
      setVisibleFeesModal(false)
    },
    setCommissionLocal: data => {
      setFeesObj({
        ...feesObj,
        ...data
      })
    },
    reCalculateCommissionFxn: async () => {
      let { commissionAmount } = await dispatch(reCalculateCommission(feesObj))
      if (commissionAmount) {
        events.setCommissionLocal({ commissionAmount })
      }
    },
    updateApplicationTuitionFeesFxn: async () => {
      let { success, message } = await dispatch(
        updateApplicationTuitionFees(feesObj)
      )
      if (success) {
        setFeesObj(commissionObj)
        setVisibleFeesModal(false)
        events.reloadFxn()
      }
    },
    showTuitionFeeDrawer: () => {
      setVisibleTuitionFee(true)
    },
    hideTuitionFeeDrawer: () => {
      setVisibleTuitionFee(false)
      tableAppRef.current.reload()
    },
    showOshcDrawer: () => {
      setVisibleOshcDrawer(true)
    },
    hideOshcDrawer: () => {
      setVisibleOshcDrawer(false)
      tableAppRef.current.reload()
    },
    hideMarketingManuallyDrawer: () => {
      setMarketingManuallyObj({
        visible: false,
        studentId: '',
        application: {}
      })
    },
    showAddGicDrawer: () => {
      setVisibleAddGicDrawer(true)
    },
    hideAddGicDrawer: () => {
      setVisibleAddGicDrawer(false)
      tableAppRef.current.reload()
    }
  }

  const reActivateApp = record => {
    setSelectedApplication({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseReActivate = () => {
    setSelectedApplication({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const enrollmentApp = record => {
    setEnrollmentObj({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseEnrollmentApp = () => {
    setEnrollmentObj({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const onCloseChangeUniversityFxn = () => {
    setChangeUniversity({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let status = searchParams.get('status')
      let agentId = searchParams.get('agentId')
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let courseId = searchParams.get('courseId')
      let note = searchParams.get('note')
      let branchUserId = searchParams.get('branchUserId')
      let branchManagerId = searchParams.get('branchManagerId')
      let studentManagerId = searchParams.get('studentManagerId')
      let draftApplication = searchParams.get('draftApplication')
      let studentShore = searchParams.get('studentShore')
      let intake = searchParams.get('intake')
      let marketingUserId = searchParams.get('marketingUserId')
      let obj = {}
      if (agentId) {
        obj.agentId = agentId
      }
      if (countryId) {
        obj.countryId = countryId
      }
      if (status) {
        obj.status = status
      }
      if (courseId) {
        obj.courseId = courseId
      }
      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }
      if (intake) {
        obj.intake = intake
      }
      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (branchManagerId) {
        obj.branchManagerId = branchManagerId
      }
      if (studentManagerId) {
        obj.studentManagerId = studentManagerId
      }
      if (draftApplication) {
        obj.draftApplication = draftApplication
      }
      if (studentShore) {
        obj.studentShore = studentShore
      }
      if (note) {
        obj.note = note
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    if (countryId) {
      obj.countryId = countryId
    }
    if (courseId) {
      obj.courseId = courseId
    }
    if (status) {
      obj.status = status
    }
    if (name) {
      obj.name = name
    }
    if (agentId) {
      obj.agentId = agentId
    }

    if (appUniversityId) {
      obj.appUniversityId = appUniversityId
    }
    if (currentNote) {
      obj.note = currentNote
    }
    if (branchUserId) {
      obj.branchUserId = branchUserId
    }
    if (intake) {
      obj.intake = intake
    }
    if (branchManagerId) {
      obj.branchManagerId = branchManagerId
    }
    if (studentManagerId) {
      obj.studentManagerId = studentManagerId
    }
    if (draftApplication) {
      obj.draftApplication = draftApplication
    }
    if (studentShore) {
      obj.studentShore = studentShore
    }
    if (marketingUserId) {
      obj.marketingUserId = marketingUserId
    }
    dispatch(
      getUrlPushWrapper('openGicDraftList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    setAppUniversityId('')
    if (currentUserRights.userType == 'branchManager') {
      setCountryId(currentUserRights.countryId)
    } else {
      setCountryId('')
    }
    setStatus('')
    setCurrentNote('')
    setBranchUserId('')
    setAgentId('')
    setName('')
    setBranchManagerId('')
    setStudentManagerId('')
    setDraftApplication('')
    setStudentShore('')
    setMarketingUserId('')
    dispatch(
      getUrlPushWrapper('openGicDraftList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      render: (item, record) => {
        return <div style={{ width: 80 }}>{item}</div>
      },
      width: 80
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 100,
      render: (item, record) => {
        return (item)
      }
    },
    {
      title: 'DOB',
      width: 100,
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      render: val => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
      }
    },
    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ? (
              <Avatar src={item.logo.url} size={20} />
            ) : (
              <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />
            )}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      width: 250,
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      render: item => {
        return <div>{item}</div>
      }
    },
    {
      title: 'Intake',
      width: 150,
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Marketing',
      width: 150,
      dataIndex: 'marketingUser',
      key: 'marketingUser',
      render: (item, record) => {
        let { marketingUserId } = record.applications
        return (
          <React.Fragment>
            {marketingUserId && marketingUserId.name
              ? marketingUserId.name
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      width: 100,
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>
            <Tooltip title={'Complete Your Details & Get CAD $175'}>
              <button
                className='btn btn-default roundNew xs'
                onClick={() => {
                  events.showAddGicDrawer()
                  setStudentData(record)
                }}>
                Complete Your Details<br /> & Get CAD $175
              </button>
            </Tooltip>

          </div>
        )
      }
    }
  ]


  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const filterBlock = (
    <Row gutter={16}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Name...'
            value={name}
            onChange={e => {
              events.enterName(e.target.value)
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>
      <AllowComponentRightsWise rightUserType={['admin', 'userManager']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Country'
              onChange={item => {
                setCountryId(item)
                setStatus('')
                setBranchUserId('')
              }}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder='Country'
              allowClear={true}
              showSearch={true}
              value={countryId || undefined}>
              {countryList && countryList.length
                ? countryList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.countryName}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
      </AllowComponentRightsWise>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By University'
            onChange={item => setAppUniversityId(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'University'}
            value={appUniversityId || undefined}>
            {universityList && universityList.length
              ? universityList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.universityName}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Intake'
            onChange={item => setIntake(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'Intake'}
            value={intake || undefined}>
            {Intakes && Intakes.length
              ? Intakes.map((item, key) => {
                return (
                  <Option value={item} key={key}>
                    {item}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <CheckUserRight
        user={user}
        rightUserType={['admin', 'branchManager', 'branchUser']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <AgentTypeheadComponent
              key={agentId}
              agentId={agentId}
              dispatch={dispatch}
              onSelect={item => {
                setAgentId(item)
              }}
            />
          </div>
        </Col>
      </CheckUserRight>
      <CheckUserRight
        user={user}
        rightUserType={['admin', 'branchUser', 'branchManager']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Marketing User'
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              placeholder={'Marketing User'}
              onChange={item => setMarketingUserId(item)}
              value={marketingUserId || undefined}>
              {marketingUserList && marketingUserList.length
                ? marketingUserList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <CheckUserRight user={user} rightUserType={['admin', 'branchUser']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Search By Student Manager'
              filterOption={filterOption}
              className={'antSelect'}
              allowClear={true}
              placeholder={'Student Manager'}
              showSearch={true}
              onChange={item => setStudentManagerId(item)}
              value={studentManagerId || undefined}>
              {studentManagerList && studentManagerList.length
                ? studentManagerList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>
      </CheckUserRight>
      <Col md={4} sm={4} xs={8} lg={4}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Open GIC Draft List</h5>
                {/*<div
                  className="search-box-table"
                  style={{backgroundColor: 'transparent'}}>
                  (Total - {totalApplication})
                </div>*/}
                <div className='sort-box-table mark-btn'>
                  {/*'branchManager'*/}
                  <ShowExportOption user={user} rightUserType={['admin']}>
                    <span className={'sort-box-table-right'}>
                      <Tooltip title={'Export Excel'}>
                        {' '}
                        <Button
                          onClick={() => exportExcel()}
                          className={'roundBtn'}>
                          Export Excel
                        </Button>
                      </Tooltip>
                    </span>
                  </ShowExportOption>
                </div>
              </div>
              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  // extraProps={{ scroll: { x: 1000 } }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>

        {/*       {addPendencyDrawer ? (
          <SelectPendency
            visible={addPendencyDrawer}
            applicationObj={state.applicationObj}
            onAddPendencyClose={events.closeAddPendencyDrawer}
            onClose={events.reloadAndClose}
            reloadFxn={events.reloadFxn}
          />
        ) : null}*/}
      </div>
      {transferAppVisible ? (
        <TransferApplicationDrawer
          visible={transferAppVisible}
          closeTransferDrawer={() => events.hideTransferApp()}
          studentId={studentData._id}
          studentData={studentData}
          application={studentData.applications}
        />
      ) : null}

      {visibleAddCourseDrawer ? (
        <ApplyCourse
          visible={visibleAddCourseDrawer}
          onClose={() => events.hideAddCourseDrawer()}
          refreshApplication={() => events.refreshApplication()}
          studentId={studentObj._id}
        />
      ) : null}

      {/*      {visibleOtherAppDrawer ? (
        <StudentOtherApp
          visible={visibleOtherAppDrawer}
          applicationObj={state.applicationObj}
          onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
          reloadFxn={events.reloadFxn}
        />
      ) : null}*/}

      {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
        <AddNote
          visible={visibleAddCommentDrawer}
          studentData={applicationObj}
          addedType={'direct'}
          reloadFxn={events.reloadFxn}
          onClose={() => events.closeAddCommentDrawer()}
          applicationId={applicationObj.applications._id}
        />
      ) : null}

      {selectedApplication.visible ? (
        <ReActivateApplication
          {...selectedApplication}
          onClose={onCloseReActivate}
        />
      ) : null}

      {enrollmentObj.visible ? (
        <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp} />
      ) : null}

      {changeUniversity.visible ? (
        <ChangeUniversityComponent
          {...changeUniversity}
          onClose={onCloseChangeUniversityFxn}
        />
      ) : null}

      {visibleTuitionFee ? (
        <AddTuitionFeeDrawer
          visible={visibleTuitionFee}
          studentId={studentData._id}
          studentData={studentData}
          application={studentData.applications}
          reloadTable={() => {
            setTimeout(() => {
              tableAppRef.current.reload()
            }, 1000)
          }}
          onClose={() => events.hideTuitionFeeDrawer()}
        />
      ) : (
        ''
      )}

      {visibleOshcDrawer ? (
        <OshcDrawer
          visible={visibleOshcDrawer}
          studentData={studentData}
          onClose={() => events.hideOshcDrawer()}
        />
      ) : (
        ''
      )}

      {marketingManuallyObj.visible ? (
        <UpdateManuallyMarketingUserDrawer
          onClose={() => {
            events.hideMarketingManuallyDrawer()
          }}
          onSubmit={() => {
            events.hideMarketingManuallyDrawer()
            events.reloadFxn()
          }}
          {...marketingManuallyObj}
        />
      ) : null}
      {visibleAddGicDrawer ? (
        <AddGicDraftDrawer
          visible={visibleAddGicDrawer}
          studentData={studentData}
          onClose={() => events.hideAddGicDrawer()}
          onSubmit={() => events.hideAddGicDrawer()}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
const mapStateToProps = ({ global, router, chooseApplication }) => ({
  currentUserRights: global.currentUserRights
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(DraftApplicationList)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
