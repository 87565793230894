import React, {PureComponent} from 'react'
import {Layout, message, notification} from 'antd'
import Animate from 'rc-animate'
import {connect} from 'react-redux'
import GlobalHeader from '../components/GlobalHeader'
import TopNavHeader from '../components/TopNavHeader'
import styles from './Header.less'
import {push} from 'connected-react-router'
import Request from '../request'
import {resetNotificationCountFxn} from '../containers/users/actions/user'
import {getUrlPushWrapper} from '../routes'
import {LogoutLogs} from '../containers/login/action'
import {Socket} from '../socket'

const {Header} = Layout

class HeaderView extends PureComponent {
    state = {
        visible: true
    }
    events = {
        showAllNotice: () => {
            let {dispatch} = this.props
            dispatch(getUrlPushWrapper('allNotifications'))
        },
        showAllPrivateMessage: () => {
            let {dispatch} = this.props
            dispatch(getUrlPushWrapper('message.allMessage'))
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.autoHideHeader && !state.visible) {
            return {
                visible: true
            }
        }
        return null
    }

    logoutFxn = () => { // mainLogout Button

        const {dispatch} = this.props
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        Socket.disconnect()
        dispatch({
            type: 'LEAVE_SOCKET',
            value: true,
            userId: user._id
        })
        LogoutLogs(user).then(() => {
        })

        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('chatId')
        localStorage.removeItem('dontAskOtpForIds')


        document.body.className = ''
        dispatch({type: 'LOGOUT'})
        dispatch(push('/login'))
    }

    handleMenuClick = ({key}) => {
        const {dispatch} = this.props

        switch (key) {
            case 'logout':
                this.logoutFxn()
                break

            case 'company':
                dispatch(push('/company/all'))
                break
            case 'withdrawals':
                dispatch(push('/withdrawal'))
                break
            case 'feedback':
                dispatch(push('/feedback/all'))
                break
            case 'changePassword':
                dispatch(push('/change-password'))
                break
            case 'updateProfile':
                dispatch(push('/updateProfile'))
                break
            default:
                console.log(key, ' this is working')
        }
    }

    handScroll = () => {
        const {autoHideHeader} = this.props
        const {visible} = this.state
        if (!autoHideHeader) {
            return
        }
        const scrollTop =
            document.body.scrollTop + document.documentElement.scrollTop
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => {
                if (this.oldScrollTop > scrollTop) {
                    this.setState({
                        visible: true
                    })
                } else if (scrollTop > 300 && visible) {
                    this.setState({
                        visible: false
                    })
                } else if (scrollTop < 300 && !visible) {
                    this.setState({
                        visible: true
                    })
                }
                this.oldScrollTop = scrollTop
                this.ticking = false
            })
        }
    }

    getHeadWidth = () => {
        const {isMobile, collapsed, setting} = this.props
        const {fixedHeader, layout} = {}
        if (isMobile || !fixedHeader || layout === 'topmenu') {
            return '100%'
        }
        return collapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)'
    }

    handleNoticeClear = type => {
        // message.success('this is ttest message')
        const {dispatch} = this.props

        console.log(type, ' this is working here')
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handScroll)
    }

    handleNoticeVisibleChange = visible => {
        console.log(visible, ' this is worign here ')
    }

    handleMessageVisibleChange = visible => {
        console.log(visible, ' this is message visible here ')
    }


    componentDidMount() {
        document.addEventListener('scroll', this.handScroll, {passive: true})

        let {dispatch} = this.props
        let user = localStorage.getItem('user')
        user = JSON.parse(user)
        dispatch({
            type: 'SET_CURRENT_USER',
            user
        })
    }

    render() {
        const {isMobile, handleMenuCollapse, theme} = this.props
        const {navTheme, layout, fixedHeader} = theme
        const {visible} = this.state
        const isTop = layout === 'topmenu'
        const width = this.getHeadWidth()
        const HeaderDom = visible ? (
            <Header
                style={{padding: 0, width}}
                className={fixedHeader ? styles.fixedHeader : ''}>
                {isTop && !isMobile ? (
                    <TopNavHeader
                        theme={navTheme}
                        mode='horizontal'
                        onCollapse={handleMenuCollapse}
                        onNoticeClear={this.handleNoticeClear}
                        showAllNotice={this.events.showAllNotice}
                        showAllPrivateMessage={this.events.showAllPrivateMessage}
                        onMenuClick={this.handleMenuClick}
                        onNoticeVisibleChange={this.handleNoticeVisibleChange}
                        onMessageVisibleChange={this.handleMessageVisibleChange}
                        {...this.props}
                    />
                ) : (
                    <GlobalHeader
                        onCollapse={handleMenuCollapse}
                        onNoticeClear={this.handleNoticeClear}
                        showAllNotice={this.events.showAllNotice}
                        showAllPrivateMessage={this.events.showAllPrivateMessage}
                        onMenuClick={this.handleMenuClick}
                        onNoticeVisibleChange={this.handleNoticeVisibleChange}
                        onMessageVisibleChange={this.handleMessageVisibleChange}
                        {...this.props}
                    />
                )}
            </Header>
        ) : null
        return (
            <Animate component='' transitionName='fade'>
                {HeaderDom}
            </Animate>
        )
    }
}

const mapStateToProps = ({global, theme}) => {
    return {
        currentUser: global.currentUser,
        notices: global.notices,
        theme
    }
}

export default connect(
    mapStateToProps,
    dispatch => {
        return {
            dispatch
        }
    }
)(HeaderView)
