import React, { useState, useEffect } from 'react'
import user from '../users/reducers/user'
import { departmentObj, countryIds, adminRightUser } from '../../components/_utils/appUtils'
import { getLoginUser } from '../../request'

const AllowComponentRightsWise = props => {
  let { rightUserType } = props
  let [userType, setUserType] = useState({})
  useEffect(() => {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null
    if (user && user.userType) {
      setUserType(user.userType)
    }
  }, [])

  return (
    <React.Fragment>
      {rightUserType.includes(userType) ? props.children : null}
    </React.Fragment>
  )
}

const AllowComponentUserWise = props => {
  let { rightUserType } = props
  let [userType, setUserType] = useState({})
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

  useEffect(() => {
    if (user && user.userType) {
      setUserType(user.userType)
    }
  }, [])

  let checkManagementViewOnly = () => {
    if (user && (user.userType == 'branchManager' || user.userType == 'branchUser')) {
      if (user.managerViewOnly) {
        return null // check for branch manager but have only view rights
      } else {
        return props.children // check for branch manager
      }
    } else {
      return rightUserType.includes(userType) ? props.children : null // check for admin, branch user and extra
    }
  }

  return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckReOpenRight = props => {
  let { applications } = props
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

  let checkManagementViewOnly = () => {
    if (user && user.userType == 'agent') {
      if (applications.universityCountry._id == countryIds.uk) {
        return props.children
      } else {
        return null
      }
    } else {
      return null
    }
  }

  return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckBranchUserRight = props => {
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  let checkManagementViewOnly = () => {
    if (
      user &&
      (user.userType == 'branchManager' || user.userType == 'branchUser')
    ) {
      if (user.managerViewOnly) {
        return null // check for branch manager but have only view rights
      } else {
        return props.children // check for branch manager
      }
    } else {
      return props.children // check for admin, branch user and extra
    }
  }
  return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckManagerWiseRight = props => {
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  let checkManagementViewOnly = () => {
    if (
      user &&
      (user.userType == 'branchManager' || adminRightUser.includes(user.userType))
    ) {
      if (user.managerViewOnly) {
        return null // check for branch manager but have only view rights
      } else if (
        user.branchManagerType &&
        user.branchManagerType != 'universityWise'
      ) {
        return props.children // check for branch manager
      } else {
        return props.children // check for admin
      }
    } else {
      return null // check for admin, branch user and extra
    }
  }
  return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const CheckCaseCloseRight = props => {
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  let checkManagementViewOnly = () => {
    if (
      user &&
      (user.userType == 'branchManager' || adminRightUser.includes(user.userType))
    ) {
      if (user.managerViewOnly) {
        return null // check for branch manager but have only view rights
      } else if (
        user.branchManagerType &&
        user.branchManagerType != 'universityWise'
      ) {
        return props.children // check for branch manager
      } else {
        return props.children // check for admin
      }
    } else if (
      user &&
      (user.userType == 'branchUser' &&
        user.department == departmentObj.marketing)
    ) {
      return props.children // check for marketing user
    } else {
      return null // check for admin, branch user and extra
    }
  }
  return <React.Fragment>{checkManagementViewOnly()}</React.Fragment>
}

const ValidateUserRight = props => {
  let {
    user: { userType, department, branchManagerType },
    rightUserType,
    branchManagerTypeWise,
    departmentWise,
    children
  } = props
  let showComponent = false
  if (rightUserType && rightUserType.length && userType) {
    if (rightUserType.includes(userType)) {
      showComponent = true
    } else {
      showComponent = false
    }
  }
  if (
    branchManagerTypeWise &&
    branchManagerTypeWise.length &&
    branchManagerType
  ) {
    if (branchManagerTypeWise.includes(branchManagerType)) {
      showComponent = true
    } else {
      showComponent = false
    }
  }
  if (departmentWise && departmentWise.length && department) {
    if (departmentWise.includes(department)) {
      showComponent = true
    } else {
      showComponent = false
    }
  }
  return showComponent === true ? children : null
}

const CheckBranchUserFxn = () => {
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  if (user) {
    if (
      user &&
      (user.userType == 'branchManager' || user.userType == 'branchUser')
    ) {
      if (user.managerViewOnly) {
        return false // check for branch manager but have only view rights
      } else {
        return true // check for branch manager
      }
    } else if (user && user.userType == 'branchUser') {
      if (user.department == departmentObj.interviewer) {
        return false
      } else {
        return true
      }
    } else if (user.userType == 'agent' || user.userType == 'subAgent') {
      return true // check for agent and sub agents
    } else {
      return true // check for admin, branch user and extra
    }
  } else {
    return false
  }
}

export let ciraOfferAllowedToAgentList = [
  '64f03bf4cd4db938aa65d4e8', //info@learnwitheducademy.com
  '655dd8c1ac76b0256e642f9c', //reruimmigration@gmail.com
  '655f11173382f508f83e731b', //info@smarttalk.in
  '64ff478c355c7a4efff519be', //hariom.aggarwal@visavide.com

  '60c74ded365ecf5f0828fddb', // admission.thevisaworld@gmail.com
  '624567ebeb4a43769774ffdd', // harvinderkaur3492@gmail.com
  '6565d85e9eca951e49d5de01', // manchesterimmigration1@gmail.com
  '64005094fc1fed07ce0bb271', // indeedimmigration01@gmail.com
  '60c74d9a365ecf5f0828fdda', // advanceoverseas2@gmail.com
  '65ee992b026d1a72d8f1f62e', // paliyalimmigration@gmail.com
  '63d9f16d34382745a3ec5bae', // vimanoverseas@gmail.com


  '614c51f68f096473eb3e235c', // dinesh.malhotra@theorangegroup.in
  '65e5584b95f37e01a702236f', // manager@vaniielts.in
  '60b77076db880e1991b2bdde', // payal@ryanconsultants.com
  '5fec641fe0fd4551cb5ae505', // rahul@ryanconsultants.com
  '60c88241365ecf5f0829006a', // thebritish_karnal@yahoo.co.in


  '6336852f22a951248216aa2a', // director@globivoimmigration.com

  '652a43c71c7ccf3f79d2829e', // 61avis@gmail.com
  '60c74d9a365ecf5f0828fdda', // advanceoverseas2@gmail.com
  '61f9084aeb18bf101d18ad42', // anilsharma.aryans@gmail.com
  '610b78e4831a8c1702a17aa7', // graceconsult.9@gmail.com
  '645b7bf4905d7d2c2f585338', // rekha.thakur@p3overseaseducation.com
  '64d497bcdca058257b5cd3fc', // vridhioverseas@gmail.com

  '653f43b93dd4cc1b6d16d171', // p3studyabroad@gmail.com
  '65ee898a026d1a72d8f1e651', // farwaystudies@gmail.com
  '651544607fb9a056ad363803', // canezimmigrationadm@gmail.com

  '630742dfffdbea44b667f0e8', // sharmaimanshu5@gmail.com
  '657882a3ca1a437c661cabad' // blueoceancameroon@gmail.com
]

const directUniLetterRights = {
  offerLetter: () => {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null
    if (user) {
      if (user.userType == 'agent' || user.userType == 'subAgent') {
        return false
      } else {
        return true // check for admin, branch user and extra
      }
    } else {
      return false
    }
  },
  ciraOfferLetter: (ciraAgentList) => {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null

    if (user) {
      if (user.userType == 'agent' || user.userType == 'subAgent') {
        if (ciraAgentList.includes(user._id)) {
          return true
        } else {
          // return false
          return true // todo change after requirement
        }
      } else {
        return true // check for admin, branch user and extra
      }
    } else {
      return false
    }
  },
  ciraReCreateOfferLetterRight: (application, ciraAgentList) => {
    let { offerReceiveLetter } = application
    let agentId = application.agentId._id
    let currentUser = getLoginUser()
    if (currentUser) {
      if (adminRightUser.includes(currentUser.userType) || currentUser.email == 'parul@unizportal.com' || currentUser.email == 'tarun@unizportal.com') {
        if (ciraAgentList.includes(agentId)) {
          return true
        } else if (offerReceiveLetter && offerReceiveLetter.path) {
          return true
        } else {
          return false
        }
      } else {
        return false // check for admin, branch user and extra
      }
    } else {
      return false
    }
  },
  cornerStoneOfferLetter: (ciraAgentList) => {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null

    if (user) {
      if (user.userType == 'agent' || user.userType == 'subAgent') {
        if (ciraAgentList.includes(user._id)) {
          return true
        } else {
          // return false
          return true // todo change after requirement
        }
      } else {
        return true // check for admin, branch user and extra
      }
    } else {
      return false
    }
  },
  cornerStoneReCreateOfferLetterRight: (application, ciraAgentList) => {
    let { offerReceiveLetter } = application
    let agentId = application.agentId._id
    let currentUser = getLoginUser()
    if (currentUser) {
      if (currentUser.userType == 'admin'
        || currentUser.email == 'khushi@unizportal.com'
        || currentUser.email == 'tarun@unizportal.com'
      ) {
        if (ciraAgentList.includes(agentId)) {
          return true
        } else if (offerReceiveLetter && offerReceiveLetter.path) {
          return true
        } else {
          return false
        }
      } else {
        return false // check for admin, branch user and extra
      }
    } else {
      return false
    }
  },
  nationalCollegeOfferLetter: (agentList) => {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null

    if (user) {
      if (user.userType == 'agent' || user.userType == 'subAgent') {
        if (agentList.includes(user._id)) {
          return true
        } else {
          // return false
          return true // todo change after requirement
        }
      } else {
        return true // check for admin, branch user and extra
      }
    } else {
      return false
    }
  },
  nationalCollegeReCreateOfferLetterRight: (application, agentList) => {
    let { offerReceiveLetter } = application
    let agentId = application.agentId._id
    let currentUser = getLoginUser()
    if (currentUser) {
      if (currentUser.userType == 'admin' ||
        currentUser.email == 'richa@unizportal.com' ||
        currentUser.email == 'tarun@unizportal.com'
      ) {
        if (agentList.includes(agentId)) {
          return true
        } else if (offerReceiveLetter && offerReceiveLetter.path) {
          return true
        } else {
          return false
        }
      } else {
        return false // check for admin, branch user and extra
      }
    } else {
      return false
    }
  },
  statusUpdateRights: (user) => {
    let { userType } = user
    if (userType !== 'agent' && userType !== 'subAgent') {
      return true
    } else {
      return false
    }
  }
}
const fcmtUniRights = {
  offerRights: (user) => {
    if (user && (adminRightUser.includes(user.userType) || user.email == 'marketing@fcmtcollege.com' || user.email == 'tarun@unizportal.com')) {
      return true
    } else {
      return false
    }
  },
  loaRights: (user) => {
    if (user && (adminRightUser.includes(user.userType) || user.email == 'marketing@fcmtcollege.com' || user.email == 'tarun@unizportal.com' || user.email == 'sandeep@unizportal.com')) {
      return true
    } else {
      return false
    }
  },
  receiptRights: (user) => {
    if (user && (adminRightUser.includes(user.userType) || user.email == 'marketing@fcmtcollege.com' || user.email == 'tarun@unizportal.com')) {
      return true
    } else {
      return false
    }
  },
  loaActionRight: (user) => {
    if (adminRightUser.includes(user.userType) || user.email == 'marketing@fcmtcollege.com' || user.email == 'tarun@unizportal.com') {
      return true
    } else {
      return false
    }
  }
}


const ShowEnrollmentComponent = props => {
  let { rightUserType } = props
  let [userType, setUserType] = useState({})
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  useEffect(() => {
    if (user && user.userType) {
      setUserType(user.userType)
    }
  }, [])

  const checkVisaApp = () => {
    if (user && user.userType == 'branchUser') {
      if (
        user.department == departmentObj.visaApproved ||
        user.email == 'onshoreaustralia@unizportal.com'
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <React.Fragment>
      {rightUserType.includes(userType)
        ? checkVisaApp()
          ? props.children
          : null
        : null}
    </React.Fragment>
  )
}
export {
  AllowComponentRightsWise,
  AllowComponentUserWise,
  CheckBranchUserRight,
  CheckBranchUserFxn,
  ShowEnrollmentComponent,
  CheckManagerWiseRight,
  ValidateUserRight,
  CheckCaseCloseRight,
  CheckReOpenRight,
  directUniLetterRights,
  fcmtUniRights
}
