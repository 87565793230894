import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon, notification,
  Popconfirm,
  Row,
  Select,
  Tooltip
} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  Intakes,
  departmentObj,
  CornerStoneCollege,
  filterOption,
  longDisplayDate,
  newFormatDisplayDate, fcmtRevokeStatus, showEnrollmentAction
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes'
import {
  AllowComponentRightsWise,
  CheckBranchUserFxn,
  CheckManagerWiseRight,
  directUniLetterRights, ShowEnrollmentComponent
} from '../../WebComponent/allowComponentRightsWise'
import { listAllCampus } from '../../campus/actions/campus'
import { CheckUserRight, ShowExportOption } from '../../dashboard/views/DashboardUserWise'
import {
  applicationCloseDirect,
  moveToDraftApplication,
  reCalculateCommission
} from '../../student/actions/student'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import { listAllUsers } from '../../users/actions/user'
import {
  ReCreateFcmtLoaLetter,
  ReCreateFcmtOfferLetter,
  ReCreateFcmtPaymentReceipt,
  fcmtApplicationSendToApprovalFxn,
  fcmtSendMailFxn,
  fcmtSendOfferMailFxn,
  fcmtSendReceiptMailFxn, getCornerstoneAgentsFxn, exportStudentCornerStoneCourseFxn
} from '../../applications/actions/chooseStudent'
import ChangeNameDrawer from '../../applications/drawers/changeNameDrawer'
import ChangeUniversityComponent from '../../applications/drawers/changeUniversity'
import AddNote from '../../applications/drawers/createNoteComponent'
import FcmtStatusComponent from '../../applications/drawers/fcmtStatus'
import LoaRequest from '../../applications/drawers/loaRequest'
import ReActivateApplication from '../../applications/drawers/reActivateApplicationDrawer'
import TransferApplicationDrawer from '../../applications/views/transferApplication'
import {
  cornerStoneStatusFilterList,
  cornerStoneStatusList,
  fcmtCollegeStatusFilterList
} from '../../../components/_utils/ApplicationStatusSteps'
import { getCornerStoneApplicationListFxn } from '../actions/cornerStone'
import StudentOtherApplication from '../../applications/views/StudentOtherApplication'
import LoaReqMessage from './components/loaReqMessage'
import AddNotes from '../../applications/drawers/addNoteComponents'
import GcKeyComponent from '../components/gcKeyComponent'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import EditUser from '../../users/views/edit'

let { showGcKeyColumn } = GcKeyComponent

const { RangePicker } = DatePicker

const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}
const commissionObj = {
  commissionAmount: 0,
  tuitionFee: 0,
  studentId: '',
  applicationId: ''
}
let viewOnlyRight = CheckBranchUserFxn() // check branch user right

const RenderComment = props => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && (
      <div>
        {item.addedByUserId && item.addedByUserId.name ? (
          <div className={'alignRight colorPrimary font12'}>
            (Added By : {item.addedByUserId.name} )
          </div>
        ) : null}
        <div className={hideV ? 'appCommentDotsReplace' : ''}>
          {item && item.text ? item.text : ''}
        </div>
        {item && item.text.length && item.text.length > 50 ? (
          <div className={'alignRight'}>
            {hideV ? (
              <a
                onClick={() => {
                  setHideV(false)
                }}>
                Show More
              </a>
            ) : (
              <a
                onClick={() => {
                  setHideV(true)
                }}>
                Show Less
              </a>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  )
}
const OfferActionComponent = props => {
  let { file, record, events, agentList } = props
  let { applications } = record
  return (
    <div>
      {directUniLetterRights.cornerStoneOfferLetter(agentList) ? <div>
        {file && file.path ? (
          <Tooltip title={'Download Offer Letter'}>
            <a download={file.fileName} href={file.path}>
              <img src={'/assets/icons/cloud-computing-download.png'} />
            </a>
          </Tooltip>
        ) : (
          ''
        )}
      </div> : null}
      <div className={'sprBox'}>
        {directUniLetterRights.offerLetter() ? (
          <>
            {directUniLetterRights.cornerStoneReCreateOfferLetterRight(applications, agentList) ? <Popconfirm
              title={'Are your sure, you want to Re-Create Offer Letter?'}
              onConfirm={() => events.reCreateOfferLetter(record)}
              okText='Yes'
              cancelText='No'>
              <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
            </Popconfirm> : null}

          </>
        ) : null}
        <div className={'mt10'}>
          {applications && applications.offerSendDate
            ? `Send On : ${newFormatDisplayDate(
              applications.offerReSendDate
                ? applications.offerReSendDate
                : applications.offerSendDate
            )}`
            : null}
        </div>
      </div>
    </div>
  )
}
const LoaRequestActionComponent = props => {
  let { record, events } = props
  let { applications } = record
  return (
    <div>
      {viewOnlyRight ? (
        <>
          {record.applications &&
          record.applications.status == 'Offer Received' ? (
            <Tooltip title={'Rqst LOA'}>
              <a onClick={() => events.openLoaDrawer(record, false)}>
                <img src={'/assets/icons/cloud-computing-upload.png'} />
              </a>
            </Tooltip>
          ) : null}
        </>
      ) : null}

      {applications && applications.loaDocument && applications.loaDocument.length ? (
        <>
          {applications.loaDocument.map((item) => {
            return (
              item && item.status ? <div className={'mb5'}>
                <Tooltip title={`Download ${item.status}`}>
                  <a className={'btn btn-default btn-xs'} href={item.url} target={'_blank'}>
                    {item.status}
                  </a>
                </Tooltip>
              </div> : null
            )
          })}
        </>
      ) : null}
    </div>
  )
}

const LoaActionComponent = props => {
  let { record, val } = props
  let { applications } = record
  return val && val.path ? (
    <>
      <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
        {applications && applications.mailSent ? (
          <>
            <Tooltip title={'Download LOA'}>
              <a download={val.fileName} href={val.path}>
                <img src={'/assets/icons/cloud-computing-download.png'} />
              </a>
            </Tooltip>
          </>
        ) : (
          ''
        )}
      </AllowComponentRightsWise>

      {viewOnlyRight ? (
        <div className={'sprBox'}>
          <AllowComponentRightsWise
            rightUserType={['admin', 'branchManager', 'branchUser']}>
            <Tooltip title={'Download LOA'}>
              <a download={val.fileName} href={val.path}>
                <img src={'/assets/icons/cloud-computing-download.png'} />
              </a>
            </Tooltip>
            <br />
          </AllowComponentRightsWise>

        </div>
      ) : null}
    </>
  ) : (
    ''
  )
}
const PalActionComponent = props => {
  let { record, val } = props
  let { applications } = record
  return val && val.path ? (
    <>
      <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
        {applications && applications.mailSent ? (
          <>
            <Tooltip title={'Download PAL'}>
              <a download={val.fileName} href={val.path}>
                <img src={'/assets/icons/cloud-computing-download.png'} />
              </a>
            </Tooltip>
          </>
        ) : (
          ''
        )}
      </AllowComponentRightsWise>

      {viewOnlyRight ? (
        <div className={'sprBox'}>
          <AllowComponentRightsWise
            rightUserType={['admin', 'branchManager', 'branchUser']}>
            <Tooltip title={'Download PAL'}>
              <a download={val.fileName} href={val.path}>
                <img src={'/assets/icons/cloud-computing-download.png'} />
              </a>
            </Tooltip>
            <br />
          </AllowComponentRightsWise>

        </div>
      ) : null}
    </>
  ) : (
    ''
  )
}

const CornerStoneApplicationList = props => {
  let { studentObj = {}, user } = props
  const [state, setState] = useState(initialState)
  const [transferAppVisible, setTransferAppVisible] = useState(false)
  const [studentData, setStudentData] = useState({})
  const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
  const [name, setName] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [visibleAddNoteDrawer, setVisibleAddNoteDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [feesObj, setFeesObj] = useState(commissionObj)
  const [status, setStatus] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [visibleLoaReq, setVisibleLoaReq] = useState(false)
  let [visibleFcmtStatus, setVisibleFcmtStatus] = useState(false)
  let [visibleChangeNameDrawer, setVisibleChangeNameDrawer] = useState(false)
  let [isEditAble, setIsEditAble] = useState(false)
  let [campusList, setCampusList] = useState([])
  const [campusId, setCampusId] = useState('')
  const [branchUserId, setBranchUserId] = useState('')
  const [intake, setIntake] = useState('')
  let [courseName, setCourseName] = useState('')
  let [studentAppId, setStudentAppId] = useState('')
  let [studentShore, setStudentShore] = useState('')
  let [id, setId] = useState('')
  let [marketingUserList, setMarketingUserList] = useState([])
  let [marketingUserId, setMarketingUserId] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [agentList, setAgentList] = useState([])
  const [loaReqState, setLoaReqState] = useState({
    visible: false
  })

  const [selectedUserState, setSelectedUserState] = useState({
    userData: {},
    visible: false
  })

  let [selectedApplication, setSelectedApplication] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })

  let [changeUniversity, setChangeUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  const dispatch = useDispatch()

  const tableAppRef = useRef()

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      if (resp && resp.id) {
        resp['applications.id'] = [resp.id]
        delete resp['id']
      }
      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await dispatch(getCornerStoneApplicationListFxn({ ...params }))
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }


  useEffect(() => {
    loadCampusList()
    setFieldByParams()
    loadFcmtCourse()
    loadMarketingUsers()
    loadAgentList()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()

    if (resp.campusId) {
      setCampusId(resp.campusId)
    }
    if (resp.status) {
      setStatus(resp.status)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.courseName) {
      setCourseName(resp.courseName)
    }
    if (resp.studentAppId) {
      setStudentAppId(resp.studentAppId)
    }
    if (resp.studentShore) {
      setStudentShore(resp.studentShore)
    }
    if (resp.branchUserId) {
      setBranchUserId(resp.branchUserId)
    }
    if (resp.id) {
      setId(resp.id)
    }
    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }
    if (resp.toDate) {
      setToDate(moment(resp.toDate, 'MM-DD-YYYY'))
    }
    if (resp.fromDate) {
      setFromDate(moment(resp.fromDate, 'MM-DD-YYYY'))
    }
  }

  const loadCampusList = async () => {
    let params = {}
    params.sortField = 'campusName'
    params.sortOrder = 'ascend'
    params.campusUniversity = CornerStoneCollege
    params.results = 50
    params.count = 50
    params.select = ['campusName']
    let { data } = await dispatch(
      listAllCampus({
        ...params,
        regExFilters: ['campusAddress', 'campusName']
      })
    )
    setCampusList(data)
  }
  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }
  const loadFcmtCourse = async () => {
    let params = {}
    params.select = ['campusName']
    let { data } = await dispatch(
      listAllCampus({
        ...params,
        regExFilters: ['campusAddress', 'campusName']
      })
    )
  }
  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    showTransferApp: () => {
      setTransferAppVisible(true)
    },
    hideTransferApp: () => {
      setTransferAppVisible(false)
      tableAppRef.current.reload()
    },
    showAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(true)
    },
    hideAddCourseDrawer: () => {
      setVisibleAddCourseDrawer(false)
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    closeDirectApp: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },

    enterName: value => {
      setName(value)
    },
    moveToDraftApplicationFxn: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(moveToDraftApplication(obj))
      tableAppRef.current.reload()
    },

    openOtherAppDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    closeOtherAppDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleOtherAppDrawer(false)
    },
    openAddCommentDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    },
    openAddNoteDrawer: value => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddNoteDrawer(true)
    },
    closeAddNoteDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddNoteDrawer(false)
    },

    setCommissionLocal: data => {
      setFeesObj({
        ...feesObj,
        ...data
      })
    },
    reCalculateCommissionFxn: async () => {
      let { commissionAmount } = await dispatch(reCalculateCommission(feesObj))
      if (commissionAmount) {
        events.setCommissionLocal({ commissionAmount })
      }
    },
    sendFcmtMail: async (record, type) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      if (type == 'receipt') {
        let { success } = await dispatch(fcmtSendReceiptMailFxn(obj))
        if (success) {
          events.reloadFxn()
        }
      } else if (type == 'offer') {
        let { success } = await dispatch(fcmtSendOfferMailFxn(obj))
        if (success) {
          events.reloadFxn()
        }
      } else {
        let { success } = await dispatch(fcmtSendMailFxn(obj))
        if (success) {
          events.reloadFxn()
        }
      }
    },
    openLoaDrawer: (record, editVal) => {
      let { applications } = record
      let agentId = applications && applications.agentId && applications.agentId._id ? applications.agentId._id : ''
      if (agentList.includes(agentId)) {
        setStudentData(record)
        setVisibleLoaReq(true)
        setIsEditAble(editVal)
      } else {
        events.showLoaReqMessage()
      }
    },
    closeLoaDrawer: () => {
      setStudentData({})
      setVisibleLoaReq(false)
    },

    changeNameOpen: async record => {
      setStudentData(record)
      setVisibleChangeNameDrawer(true)
    },
    closeChangeName: async record => {
      setStudentData({})
      setVisibleChangeNameDrawer(false)
    },
    openFcmtStatusDrawer: record => {
      if (directUniLetterRights.statusUpdateRights(user)) {
        setStudentData(record)
        setVisibleFcmtStatus(true)
      }
    },
    closeFcmtStatusDrawer: () => {
      setStudentData({})
      setVisibleFcmtStatus(false)
    },
    reCreateOfferLetter: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    reCreateLoaLetter: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtLoaLetter(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    reCreatePaymentReceipt: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(ReCreateFcmtPaymentReceipt(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    sendForApproval: async record => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(fcmtApplicationSendToApprovalFxn(obj))
      if (resp && resp.success) {
        tableAppRef.current.reload()
      }
    },
    showLoaReqMessage: () => {
      setLoaReqState({
        visible: true
      })
    },
    hideLoaReqMessage: () => {
      setLoaReqState({
        visible: false
      })
    }
  }
  const reActivateApp = record => {
    setSelectedApplication({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseReActivate = () => {
    setSelectedApplication({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }
  const changeUniversityFxn = record => {
    setChangeUniversity({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseChangeUniversityFxn = () => {
    setChangeUniversity({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }

  const loadAgentList = async () => {
    let { data } = await getCornerstoneAgentsFxn()
    setAgentList(data)
  }
  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let campusId = searchParams.get('campusId')
      let status = searchParams.get('status')
      let agentId = searchParams.get('agentId')
      let branchUserId = searchParams.get('branchUserId')
      let intake = searchParams.get('intake')
      let courseName = searchParams.get('courseName')
      let studentShore = searchParams.get('studentShore')
      let marketingUserId = searchParams.get('marketingUserId')
      let fromDate = searchParams.get('fromDate')
      let toDate = searchParams.get('toDate')
      let id = searchParams.get('id')
      let studentAppId = searchParams.get('studentAppId')
      let obj = {}

      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }
      if (id) {
        obj.id = id
      }

      if (campusId) {
        obj.campusId = campusId
      }
      if (status) {
        obj.status = status
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (intake) {
        obj.intake = intake
      }
      if (courseName) {
        obj.courseName = courseName
      }
      if (studentAppId) {
        obj.studentAppId = studentAppId
      }
      if (studentShore) {
        obj.studentShore = studentShore
      }

      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      if (fromDate) {
        obj.fromDate = fromDate
      }
      if (toDate) {
        obj.toDate = toDate
      }

      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    if (name) {
      obj.name = name
    }
    if (campusId) {
      obj.campusId = campusId
    }
    if (status) {
      obj.status = status
    }
    if (agentId) {
      obj.agentId = agentId
    }
    if (intake) {
      obj.intake = intake
    }
    if (courseName) {
      obj.courseName = courseName
    }
    if (studentAppId) {
      obj.studentAppId = studentAppId
    }
    if (studentShore) {
      obj.studentShore = studentShore
    }
    if (branchUserId) {
      obj.branchUserId = branchUserId
    }
    if (marketingUserId) {
      obj.marketingUserId = marketingUserId
    }
    if (id) {
      obj['applications.id'] = id
    }
    if (toDate) {
      obj.toDate = moment(toDate).format('MM-DD-YYYY')
    }
    if (fromDate) {
      obj.fromDate = moment(fromDate).format('MM-DD-YYYY')
    }
    dispatch(
      getUrlPushWrapper('application.cornerStoneApplicationList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let obj = {}
    setName('')
    setCampusId('')
    setStatus('')
    setAgentId('')
    setIntake('')
    setCourseName('')
    setStudentAppId('')
    setStudentShore('')
    setBranchUserId('')
    setMarketingUserId('')
    setId('')
    setToDate('')
    setFromDate('')
    dispatch(
      getUrlPushWrapper('application.cornerStoneApplicationList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const agentEvents = {
    closeEditUser: () => {
      setSelectedUserState({
        userData: {},
        visible: false
      })
    },
    editAgent: agent => {
      setSelectedUserState({
        userData: agent,
        visible: true
      })
    }
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      // searchTextName: 'applications.id',
      width: 60,
      render: item => {
        return <div style={{ width: 60 }}>{item}</div>
      }
    },
    {
      title: 'Student ID',
      key: 'applications.studentIdNo',
      dataIndex: 'applications.studentIdNo',
      width: 80,
      render: item => {
        return <div style={{ width: 80 }}>{item}</div>
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 120 }}>
            <Tooltip title={'Edit Student'}>
              <a
                className={'linkAA'}
                onClick={() =>
                  dispatch(
                    getPushPathWrapper('student.editStudent', {
                      id: record._id
                    })
                  )
                }>
                {item} <Icon type={'edit'} />
              </a>
            </Tooltip>
            <br />
            {newFormatDisplayDate(record.dateOfBirth)}
            <label className={'label label-tag'}>
              {record.studentShore ? `(${record.studentShore})` : null}
            </label>
          </div>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: item => {
        return <div style={{ width: 150 }}>{item}</div>
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </div>
        )
      }
    },
    {
      title: 'Campus',
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      width: 85,
      render: (item, record) => {
        return (
          <div style={{ width: 85 }}>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },
    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 200,
      render: (val, record) => {
        let { agentId, statusList, enrollment } = record.applications
        return (
          <div style={{ width: 200 }}>
            {val && val.name ? val.name : ''}
            <br />
            <div>
              Agent :{' '}
              {agentId && agentId.companyName ? agentId.companyName : ''}
              {!enrollment && showEnrollmentAction(statusList) ? (
                <ShowEnrollmentComponent
                  rightUserType={['admin', 'branchUser']}>
                  <a onClick={() => agentEvents.editAgent(agentId)}>
                    <Icon type={'edit'} />
                  </a>
                </ShowEnrollmentComponent>
              ) : null}
              <br />
              {agentId && agentId.mobile ? (
                <>
                  {' '}
                  <Icon
                    type={'phone'}
                    style={{
                      position: 'relative',
                      bottom: 3
                    }}
                  />{' '}
                  {agentId.mobile}
                </>
              ) : (
                ''
              )}
            </div>
            <div>{longDisplayDate(statusList.date)}</div>
          </div>
        )
      }
    },
    {
      title: 'Family / Given Name',
      key: 'applications.familyName',
      dataIndex: 'applications.familyName',
      width: 100,
      render: (item, record) => {
        return (
          <div style={{ width: 100 }}>
            {item && item ? (
              <p>
                Family Name : <p style={{ fontWeight: 'bold' }}>{item}</p>
              </p>
            ) : (
              ''
            )}
            {record && record.applications && record.applications.givenName ? (
              <p>
                Given Name :{' '}
                <p style={{ fontWeight: 'bold' }}>
                  {record.applications.givenName}
                </p>
              </p>
            ) : (
              ''
            )}
            {record &&
            record.applications &&
            record.applications.loaRequested === 'Approved' ? (
              <Icon
                type={'edit'}
                onClick={() => events.changeNameOpen(record)}
              />
            ) : null}
          </div>
        )
      }
    },
    {
      title: 'Conditional Offer Letter',
      key: 'applications.offerReceiveLetter',
      dataIndex: 'applications.offerReceiveLetter',
      width: 100,
      render: (val, record) => {
        return (
          <div style={{ width: 100 }} className={'fcmtDiv'}>
            <OfferActionComponent file={val} record={record} events={events} agentList={agentList} />
          </div>
        )
      }
    },

    {
      title: 'Rqst LOA',
      key: 'sendToNextUser',
      dataIndex: 'sendToNextUser',
      width: 150,
      render: (val, record) => {
        let { applications } = record
        let { loaRequested } = applications
        return (
          <div style={{ width: 150 }} className={'fcmtDiv'}>
            <LoaRequestActionComponent
              val={loaRequested}
              record={record}
              events={events}
              user={user}
            />
          </div>
        )
      }
    },
    {
      title: 'Download LOA',
      key: 'applications.loaLetter',
      dataIndex: 'applications.loaLetter',
      hidden: user && (user.userType == 'agent' || user.userType == 'subAgent'),
      width: 100,
      render: (val, record) => {
        return (
          <div style={{ width: 100 }} className={'fcmtDiv'}>
            <LoaActionComponent val={val} record={record} events={events} />
          </div>
        )
      }
    },

    {
      title: 'Download PAL',
      key: 'applications.palLetter',
      dataIndex: 'applications.palLetter',
      width: 100,
      hidden: user && (user.userType == 'agent' || user.userType == 'subAgent'),
      render: (val, record) => {
        return (
          <div style={{ width: 100 }} className={'fcmtDiv'}>
            <PalActionComponent val={val} record={record} events={events} />
          </div>
        )
      }
    },


    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 150,
      render: (item, record) => {
        let { applications } = record
        return (
          <div className={'fcmtDiv'} style={{ width: 150 }}>
            {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? (
              <div>
                {item}
                <br />
                {applications.statusList && applications.statusList.date ? (
                  <div>
                    Date : {newFormatDisplayDate(applications.statusList.date)}
                    <br />
                  </div>
                ) : null}
              </div>
            ) : null}

            <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
              {applications && applications.loaRequested && applications.status !== fcmtRevokeStatus ? (
                <Tooltip title={'Update Status'}>
                  <a onClick={() => events.openFcmtStatusDrawer(record)}>
                    <img src={'/assets/icons/updated.png'} className={'sm'} />
                  </a>
                </Tooltip>
              ) : null}
            </AllowComponentRightsWise>

            {applications && applications.status == fcmtRevokeStatus ?
              <div className={'mt10'}>
                <label className={'label label-danger sm'}>
                  {fcmtRevokeStatus}
                </label>
              </div> : null
            }

          </div>
        )
      }
    },
    {
      title: 'GCKey',
      key: 'applications.gcKey',
      dataIndex: 'applications.gcKey',
      hidden: !(showGcKeyColumn(user, 'khushi@unizportal.com')),
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ width: 150 }}>
            <GcKeyComponent record={record} refreshTable={events.reloadFxn} />
          </div>
        )
      }
    },
    {
      title: 'Marketing',
      dataIndex: 'marketingUser',
      width: 120,
      key: 'marketingUser',
      render: (item, record) => {
        let { marketingUserId } = record.applications
        return (
          <React.Fragment>

            {marketingUserId && marketingUserId.name ? marketingUserId.name : ''}

          </React.Fragment>
        )
      }
    },

    {
      title: 'Latest Note',
      key: 'applications.latestComment',
      dataIndex: 'applications.latestComment',
      width: 150,
      hidden: user && (user.userType == 'agent' || user.userType == 'subAgent'),
      render: val => {
        return (
          <div style={{ width: 150 }}>
            {val && val.text ? <RenderComment item={val} /> : null}
          </div>
        )
      }
    },

    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>
            {!record.applications.directClosed && (
              <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                <Tooltip title={'View Application'}>
                  <a
                    className='btn'
                    href={`/application/view?appId=${
                      record.applications._id
                    }&studentId=${record._id}`}>
                    <img
                      src='../dist/img/view.svg'
                      alt=''
                      className={'eyeBtn'}
                    />
                  </a>
                </Tooltip>{' '}
              </AllowComponentRightsWise>
            )}

            <div>
              <AllowComponentRightsWise
                rightUserType={['admin', 'branchUser', 'branchManager']}>
                <Tooltip title={'View Application'}>
                  <a
                    className='btn'
                    href={`/student/application?studentId=${record._id}&appId=${
                      record.applications._id
                    }`}>
                    <img src='../dist/img/view.svg' alt='' />
                  </a>
                </Tooltip>

                <Tooltip title={'View Other Application'}>
                  <button
                    className='btn'
                    onClick={() => {
                      events.openOtherAppDrawer(record)
                    }}>
                    <Icon type={'ordered-list'} />
                  </button>
                </Tooltip>

                <Tooltip title={'Add Note'}>
                  <button
                    className='btn'
                    onClick={() => {
                      events.openAddCommentDrawer(record)
                    }}>
                    <Icon type={'plus'} />
                  </button>
                </Tooltip>
                <Tooltip title={'Add New Note'}>
                  <button
                    className='btn'
                    onClick={() => {
                      events.openAddNoteDrawer(record)
                    }}>
                    <Icon type={'file'} />
                  </button>
                </Tooltip>
              </AllowComponentRightsWise>

              <CheckManagerWiseRight>
                {record.applications.status !== 'Case Close' && (
                  <Tooltip title={'Case Closed'}>
                    <Popconfirm
                      title={'Are your sure, you want to Close Case?'}
                      onConfirm={() => {
                        events.closeDirectApp(record)
                      }}
                      okText='Yes'
                      cancelText='No'>
                      <button className='btn'>
                        <img
                          src='../dist/img/cross.svg'
                          alt=''
                          className={'crossBtn'}
                        />
                      </button>
                    </Popconfirm>
                  </Tooltip>
                )}
              </CheckManagerWiseRight>
            </div>

            <AllowComponentRightsWise
              rightUserType={['admin', 'branchManager']}>
              {record.applications.status == 'Case Close' ? (
                <Tooltip title={'Re-Open Case'}>
                  <button className='btn' onClick={() => reActivateApp(record)}>
                    <img src={'/assets/power-button.png'} />
                  </button>
                </Tooltip>
              ) : null}
            </AllowComponentRightsWise>
            <AllowComponentRightsWise
              rightUserType={['admin', 'branchManager']}>
              {record.applications.status !== 'Case Close' ? (
                <Tooltip title={'Change University'}>
                  <button
                    className='btn'
                    onClick={() => changeUniversityFxn(record)}>
                    <img src={'/assets/shuffle-arrows.png'} />
                  </button>
                </Tooltip>
              ) : null}
            </AllowComponentRightsWise>
          </div>
        )
      }
    }
  ]

  const resp = useSelector(state => {
    let { loadNoteRedux } = state.chooseApplication
    if (loadNoteRedux) {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
      dispatch({ type: 'END_LOAD_NOTE' })
    }
  })

  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const extra = (
    <div className={'mt10'}>
      <Row gutter={12} className={'filterBox'}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Search by name...'
              value={name}
              onChange={e => {
                events.enterName(e.target.value)
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Status'
              onChange={item => setStatus(item)}
              filterOption={filterOption}
              className={'antSelect'}
              placeholder={'Status'}
              allowClear={true}
              value={status || undefined}>
              {cornerStoneStatusFilterList && cornerStoneStatusFilterList.length
                ? cornerStoneStatusFilterList.map((item, key) => {
                  return (
                    <Option value={item.name} key={key}>
                      {item.name}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <Select
              name='Status'
              onChange={item => setCampusId(item)}
              className={'antSelect'}
              placeholder={'Campus'}
              allowClear={true}
              value={campusId || undefined}>
              {campusList && campusList.length
                ? campusList.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>
                      {item.campusName}
                    </Option>
                  )
                })
                : null}
            </Select>
          </div>
        </Col>

        <CheckUserRight
          user={user}
          rightUserType={['admin', 'branchManager', 'branchUser']}>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>

              <AgentTypeheadComponent
                key={agentId}
                agentId={agentId}
                dispatch={dispatch}
                onSelect={item => {
                  setAgentId(item)
                }}
              />


            </div>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Search By Marketing User'
                filterOption={filterOption}
                className={'antSelect'}
                allowClear={true}
                placeholder={'Marketing User'}
                onChange={item => setMarketingUserId(item)}
                value={marketingUserId || undefined}>
                {marketingUserList && marketingUserList.length
                  ? marketingUserList.map((item, key) => {
                    return (
                      <Option value={item._id} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col>

        </CheckUserRight>
        <CheckUserRight
          user={user}
          rightUserType={['admin', 'branchManager', 'branchUser']}>
          <Col md={4} sm={4} xs={8} lg={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <Select
                name='Intake'
                onChange={item => setIntake(item)}
                filterOption={filterOption}
                className={'antSelect'}
                allowClear={true}
                showSearch={true}
                placeholder={'Intake'}
                value={intake || undefined}>
                {Intakes && Intakes.length
                  ? Intakes.map((item, key) => {
                    return (
                      <Option value={item} key={key}>
                        {item}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </div>
          </Col>
          <Col span={4}>
            <div className='sort-box-table custom-sort-box-new'>
              <div className='search-box-table round'>
                <input
                  className='form-control form-control'
                  type='search'
                  placeholder='course name...'
                  value={courseName}
                  onChange={e => {
                    setCourseName(e.target.value)
                  }}
                />
                <img src='/dist/img/search.png' alt='' />
              </div>
            </div>
          </Col>


        </CheckUserRight>
        <Col span={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <div className='search-box-table round'>
              <input
                className='form-control form-control'
                type='search'
                placeholder='Student ID'
                value={studentAppId}
                onChange={e => {
                  setStudentAppId(e.target.value)
                }}
              />
              <img src='/dist/img/search.png' alt='' />
            </div>
          </div>
        </Col>
        <Col md={4} sm={4} xs={12} lg={4} key={fromDate}>
          <RangePicker
            defaultValue={[fromDate, toDate]}
            onChange={val => {
              setFromDate(val[0])
              setToDate(val[1])
            }}
          />
        </Col>

        <Col md={4} sm={4} xs={12} lg={4}>
          <Button onClick={() => searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  )


  let exportExcel = async () => {
    let params = {}
    let resp = await getParamsForApplicationList()
    if (resp && resp.id) {
      resp['applications.id'] = [resp.id]
      delete resp['id']
    }
    params = { ...params, ...resp }
    if (name) {
      params.name = name
    }

    params.count = totalApplication
    params.courseUniversity = CornerStoneCollege

    let { success, filePath, message } = await dispatch(
      exportStudentCornerStoneCourseFxn(params)
    )
    if (success) {
      notification.success({ message: message })
    } else {
      notification.error({ message: message })
    }
  }
  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Cornerstone College All Applications </h5>
                <div
                  className='search-box-table'
                  style={{ backgroundColor: 'transparent' }}>
                  (Total - {totalApplication})
                </div>
                <div className='sort-box-table mark-btn'>
                  <ShowExportOption>
                                        <span className={'sort-box-table-right'}>
                                          <Tooltip title={'Export Excel'}>
                                            {' '}
                                            <Button
                                              onClick={() => exportExcel()}
                                              className={'roundBtn'}>
                                              Export Excel
                                            </Button>
                                          </Tooltip>
                                        </span>
                  </ShowExportOption>
                </div>
              </div>
              <div className='card-body table-responsive'>
                {extra}

                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {transferAppVisible ? (
        <TransferApplicationDrawer
          visible={transferAppVisible}
          closeTransferDrawer={() => events.hideTransferApp()}
          studentId={studentData._id}
          studentData={studentData}
          application={studentData.applications}
        />
      ) : null}

      {visibleAddCourseDrawer ? (
        <ApplyCourse
          visible={visibleAddCourseDrawer}
          onClose={() => events.hideAddCourseDrawer()}
          refreshApplication={() => events.refreshApplication()}
          studentId={studentObj._id}
        />
      ) : null}

      {visibleOtherAppDrawer ? (
        <StudentOtherApplication
          visible={visibleOtherAppDrawer}
          applicationObj={state.applicationObj}
          onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
          reloadFxn={events.reloadFxn}
        />
      ) : null}

      {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
        <AddNote
          visible={visibleAddCommentDrawer}
          studentData={applicationObj}
          addedType={'direct'}
          onClose={() => events.closeAddCommentDrawer()}
          applicationId={applicationObj.applications._id}
        />
      ) : null}
      {visibleAddNoteDrawer && applicationObj && applicationObj._id ? (
        <AddNotes
          visible={visibleAddNoteDrawer}
          studentData={applicationObj}
          addedType={'direct'}
          onClose={() => events.closeAddNoteDrawer()}
          applicationId={applicationObj.applications._id}
        />
      ) : null}


      {selectedApplication.visible ? (
        <ReActivateApplication
          {...selectedApplication}
          onClose={onCloseReActivate}
        />
      ) : null}


      {visibleLoaReq &&
      studentData.applications &&
      studentData.applications._id ? (
        <LoaRequest
          onClose={events.closeLoaDrawer}
          reloadTable={events.reloadFxn}
          editAble={isEditAble}
          applicationId={studentData.applications._id}
          studentId={studentData._id}
          studentData={studentData}
          visible={visibleLoaReq}
        />
      ) : null}

      {visibleFcmtStatus &&
      studentData.applications &&
      studentData.applications._id ? (
        <FcmtStatusComponent
          onClose={events.closeFcmtStatusDrawer}
          reloadTable={events.reloadFxn}
          university={'Cornerstone College'}
          applicationId={studentData.applications._id}
          studentId={studentData._id}
          studentData={studentData}
          visible={visibleFcmtStatus}
        />
      ) : null}


      {visibleChangeNameDrawer ? (
        <ChangeNameDrawer
          onClose={events.closeChangeName}
          reloadTable={events.reloadFxn}
          studentData={studentData}
          applicationId={
            studentData.applications ? studentData.applications._id : null
          }
          studentId={studentData._id}
          visible={visibleChangeNameDrawer}
        />
      ) : null}

      {changeUniversity.visible ? (
        <ChangeUniversityComponent
          {...changeUniversity}
          onClose={onCloseChangeUniversityFxn}
        />
      ) : null}

      {loaReqState ?
        <LoaReqMessage
          {...loaReqState}
          onClose={() => {
            events.hideLoaReqMessage()
          }} /> : null}

      {selectedUserState.visible ? (
        <EditUser
          {...selectedUserState}
          reloadTable={() => {
            setTimeout(() => {
              tableAppRef.current.reload()
            }, 1000)
          }}
          onClose={() => agentEvents.closeEditUser()}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedCornerStoneApplicationList = Form.create()(CornerStoneApplicationList)
export default connect(
  null,
  mapDispatchToProps
)(WrappedCornerStoneApplicationList)
