import { customAxios as axios, getToken } from '../../../request'
import {
  canadaUniversityRecordsCountUrl,
  directUniversityMarketingManagerReportsUrl,
  directUniversityRecordsCountUrl, directUniversityReportCountForMarketingUrl,
  directUniversityReportingApplicationUrl,
  getMeetingsByIdUrl,
  getMeetingsCountUrl,
  getReportApplicationListUrl,
  getUserReportUrl,
  marketingManagerReportingApplicationUrl,
  marketingManagerReportsUrl, ukBloomsburyRecordCountUrl, ukUniversityRecordsCountUrl
} from '../apis/index'
import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'


export const getUserReport = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getUserReportUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getReportApplicationList = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getReportApplicationListUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getMeetingsCount = (obj) => async (dispatch) => {
  let config = {
    params: obj,
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getMeetingsCountUrl(obj), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const getMeetingsById = (userId) => async (dispatch) => {
  let config = {
    params: userId,
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getMeetingsByIdUrl(userId), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const marketingManagerReportsFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(marketingManagerReportsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const marketingManagerReportingApplicationFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(marketingManagerReportingApplicationUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const directUniversityMarketingManagerReportsFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(directUniversityMarketingManagerReportsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const directUniversityReportingApplicationFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(directUniversityReportingApplicationUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const directUniversityRecordsCountFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(directUniversityRecordsCountUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const canadaUniversityRecordsCountFxn = (valData) => async (dispatch) => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(canadaUniversityRecordsCountUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const ukUniversityRecordsCountFxn = (valData) => async (dispatch) => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(ukUniversityRecordsCountUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const directUniversityReportCountForMarketingFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(directUniversityReportCountForMarketingUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const ukBloomsburyRecordCountFxn = async (valData) => {
  let { data } = await axios.post(ukBloomsburyRecordCountUrl(), valData, getToken())
  return data
}
