import React, {Component} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {Tooltip, Icon, Button, Row, Col, Select} from 'antd'
import {connect} from 'react-redux'
import {listAllUsers, updateInsuranceRight} from '../actions/user'
import {
    DefaultTablePagination, filterOption, getDirectUniversityName
} from '../../../components/_utils/appUtils'
import {ColumnWidth} from '../../WebComponent/columnWidth'
import {longDisplayDate} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {LoadAllCountry, LoadCity, LoadState} from '../../../components/_utils/countryUtil'
import _ from 'lodash'
import moment from 'moment'
import UpdateMarketingUser from './updateMarketingUser'
import {listAllCountries} from "../../countries/actions/countries";
import {agentListForMarketingManagerFxn} from "../actions/user";
import MobileEmailInfo from "../../../components/commonComponents/mobileEmailInfo";

const {Option} = Select

class AllUsers extends Component {

    events = {
        showMarketingDrawer: (data) => {
            this.setState({
                marketingState: {
                    agentMarketing: data.agentMarketing,
                    armUserData: data.armUsers,
                    visible: true,
                    agentId: data._id,
                    email: data.email,
                }
            })
        },

        hideMarketingDrawer: () => {
            this.setState({
                marketingState: {
                    agentMarketing: {},
                    armUserData: [],
                    visible: false,
                    agentId: "",
                    email: "",
                }
            })
        },
        chooseCountry: (name) => {
            let countryName = _.find(this.state.allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                this.setState({
                    countryName: countryName.name ? countryName.name : '',
                    stateName: undefined,
                    cityName: undefined,
                    pincode: undefined,
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(this.state.allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                this.setState({
                    stateName: stateName.name ? stateName.name : '',
                    cityName: undefined,
                    pincode: undefined,
                    allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            this.setState({
                cityName: name ? name : ''
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: false,
            selectedRow: {},
            queryData: {},
            visibleUserRightDrawer: false,
            approvedAgentRightsUser: [],
            zeroRecordAgent: false,
            filters: {},
            total: 0,
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            allCountries: LoadAllCountry(),
            allStates: [],
            allCities: [],
            keyMoment: moment(),
            countryList: [],
            armUserData: [],
            marketingState: {
                visible: false,
                agentId: "",
                agentMarketing: {},
                email: {},
            }
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.loadRightUser()
        this.setFieldByParams()
        this.loadCountry()
    }

    loadCountry = async () => {
        let {dispatch} = this.props;
        let countryFilter = {
            results: 10,
            sortField: 'countryName',
            sortOrder: 'ascend'
        }
        let {data} = await dispatch(listAllCountries(countryFilter))
        this.setState({
            countryList: data
        })
    }


    loadRightUser = async () => {
        let params = {
            results: 200,
            approvedAgent: true,
            select: ['name', 'userType']
        }
        let {data} = await this.props.dispatch(listAllUsers(params))
        this.setState({approvedAgentRightsUser: data})
    }

    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = '_id'
            params.sortOrder = 'descend'
            let resp1 = await this.getParamsForApplicationList()

            if (resp1.countryName) {
                params.countryName = resp1.countryName
            }
            if (resp1.stateName) {
                params.stateName = resp1.stateName
            }
            if (resp1.cityName) {
                params.cityName = resp1.cityName
            }
            if (resp1.pincode) {
                params.pincode = resp1.pincode
            }

            if (resp1.agentWiseManagerId) {
                params.agentWiseManagerId = resp1.agentWiseManagerId
            }
            if (resp1.marketingManagerId) {
                params.marketingManagerId = resp1.marketingManagerId
            }
            if (resp1.email) {
                params.email = resp1.email
            }
            if (resp1.mobile) {
                params.mobile = resp1.mobile
            }
            if (resp1.companyName) {
                params.companyName = resp1.companyName
            }
            if (resp1.name) {
                params.name = resp1.name
            }

            let finalFilter = {
                ...params,
                regExFilters: ['name', 'address', 'mobile', 'email', 'cityName', 'companyName']
            }
            this.setState({filters: finalFilter})
            let resp = await agentListForMarketingManagerFxn({...finalFilter})
            this.setState({total: resp.total})
            resolve(resp)
        })
    }

    getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryName = searchParams.get('countryName')
            let stateName = searchParams.get('stateName')
            let cityName = searchParams.get('cityName')
            let pincode = searchParams.get('pincode')
            let agentWiseManagerId = searchParams.get('agentWiseManagerId')
            let name = searchParams.get('name')
            let email = searchParams.get('email')
            let companyName = searchParams.get('companyName')
            let mobile = searchParams.get('mobile')
            let marketingManagerId = searchParams.get('marketingManagerId')

            let obj = {}
            if (countryName) {
                obj.countryName = countryName
            }
            if (stateName) {
                obj.stateName = stateName
            }
            if (cityName) {
                obj.cityName = cityName
            }
            if (pincode) {
                obj.pincode = pincode
            }
            if (agentWiseManagerId) {
                obj.agentWiseManagerId = agentWiseManagerId
            }
            if (marketingManagerId) {
                obj.marketingManagerId = marketingManagerId
            }
            if (name) {
                obj.name = name
            }
            if (companyName) {
                obj.companyName = companyName
            }
            if (mobile) {
                obj.mobile = mobile
            }
            if (email) {
                obj.email = email
            }
            resolve(obj)
        })
    }

    setFieldByParams = async () => {
        let resp = await this.getParamsForApplicationList()
        let obj = {}
        if (resp.countryName) {
            obj.countryName = resp.countryName
        }
        if (resp.stateName) {
            obj.stateName = resp.stateName
        }
        if (resp.cityName) {
            obj.cityName = resp.cityName
        }
        if (resp.pincode) {
            obj.pincode = resp.pincode
        }
        if (resp.agentWiseManagerId) {
            obj.agentWiseManagerId = resp.agentWiseManagerId
        }
        if (resp.marketingManagerId) {
            obj.marketingManagerId = resp.marketingManagerId
        }
        if (resp.name) {
            obj.name = resp.name
        }
        if (resp.email) {
            obj.email = resp.email
        }
        if (resp.companyName) {
            obj.companyName = resp.companyName
        }
        if (resp.mobile) {
            obj.mobile = resp.mobile
        }
        this.setState({...obj})
    }


    searchFxn = async () => {
        let {dispatch} = this.props
        let obj = {}
        if (this.state.countryName) {
            obj.countryName = this.state.countryName
        }
        if (this.state.stateName) {
            obj.stateName = this.state.stateName
        }
        if (this.state.cityName) {
            obj.cityName = this.state.cityName
        }
        if (this.state.pincode) {
            obj.pincode = this.state.pincode
        }
        if (this.state.agentWiseManagerId) {
            obj.agentWiseManagerId = this.state.agentWiseManagerId
        }
        if (this.state.marketingManagerId) {
            obj.marketingManagerId = this.state.marketingManagerId
        }
        if (this.state.name) {
            obj.name = this.state.name
        }
        if (this.state.email) {
            obj.email = this.state.email
        }
        if (this.state.companyName) {
            obj.companyName = this.state.companyName
        }
        if (this.state.mobile) {
            obj.mobile = this.state.mobile
        }
        dispatch(
            getUrlPushWrapper('agentListForMarketingManagers', {
                ...obj
            }))
        setTimeout(() => {
            if (this.tableRef && this.tableRef.current) {
                this.tableRef.current.reload()
            }
        }, 200)
    }
    clearFxn = async () => {
        let {dispatch} = this.props
        let obj = {}
        this.setState({
            countryName: '',
            stateName: '',
            cityName: '',
            pincode: '',
            agentWiseManagerId: "",
            marketingManagerId: "",
            name: "",
            email: "",
            companyName: "",
            mobile: "",
        })
        dispatch(
            getUrlPushWrapper('agentListForMarketingManagers', {
                ...obj
            }))
        setTimeout(() => {
            this.tableRef.current.reload()
        }, 200)
    }

    UpdateInsuranceRight = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(updateInsuranceRight({userId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    render() {
        let {
            countryList,
            marketingState,
            approvedAgentRightsUser,
            countryName
        } = this.state

        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                // searchTextName: 'name',
                width: 150,
                render: (item, record) => {
                    return (
                        <ColumnWidth width={150}>
                            {item}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Company Name',
                key: 'companyName',
                sorter: true,
                dataIndex: 'companyName',
                // searchTextName: 'companyName',
                width: 150
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                // searchTextName: 'email',
                width: 160,
                render: (item, record) => {
                    return (
                        <div>
                            <MobileEmailInfo data={record} type={'email'} value={item}/>
                        </div>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                // searchTextName: 'mobile',
                width: 100,
                render: (item, record) => {
                    return (
                        <div>
                            <MobileEmailInfo data={record} type={'mobile'} value={item}/>
                        </div>
                    )
                }
            },
            {
                title: 'State',
                dataIndex: 'stateName',
                key: 'stateName',
                searchTextName: 'stateName',
                width: 120,
                render: (item) => {
                    return (
                      <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'City',
                dataIndex: 'cityName',
                key: 'cityName',
                searchTextName: 'cityName',
                width: 120,
                render: (item) => {
                    return (
                      <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                searchTextName: 'address',
                width: 200,
                render: (item) => {
                    return (
                      <ColumnWidth width={200}>{item}</ColumnWidth>
                    )
                }
            },

            {
                title: 'Approved By',
                dataIndex: 'createdByUser.userId',
                key: 'createdByUser.userId',
                width: 200,
                filters: approvedAgentRightsUser.map(x => ({ value: x._id, text: x.name })),
                render: (item, record) => {
                    let val = record.createdByUser
                    return (
                        <ColumnWidth width={200}>
                            {val && val.userId ? <div>
                                User : {val.name}
                                {val.time ? <div>
                                    Date : {longDisplayDate(val.time)}
                                </div> : null}

                            </div> : null}
                        </ColumnWidth>
                    )
                }
            },
            {
                title: 'Managers',
                dataIndex: 'managers',
                width: 120,
                key: 'managers',
                render: (item, record) => {
                    let {armUsers} = record;
                    return (
                        <div key={this.state.keyMoment}>
                            {armUsers && armUsers.length ? <ul>
                                {armUsers.map((item, index) => {
                                    return (
                                        <li key={item._id}>{index + 1}. {item && item.name ? item.name : ""}</li>
                                    )
                                })}
                            </ul> : null}

                        </div>
                    )
                }
            },
            {
                title: 'Marketing User',
                dataIndex: 'marketingUser',
                width: 150,
                key: 'marketingUser',
                render: (item, record) => {
                    let {agentMarketing} = record;
                    return (
                        <div key={this.state.keyMoment}>
                            {agentMarketing && agentMarketing.length ?
                                <ul>
                                    {agentMarketing.map((item, index) => {
                                        let {marketingUserId, countryId} = item
                                        return (
                                            <li key={item._id}>
                                                {index + 1}. {marketingUserId && marketingUserId.name ? marketingUserId.name : null}
                                                {/*({countryId && countryId.countryName ? countryId.countryName : ""})*/}
                                                ({getDirectUniversityName(item.university)})
                                            </li>
                                        )
                                    })}
                                </ul> :
                                null}

                        </div>
                    )
                }
            },

            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                fixed: "right",
                width: 60,
                render: (val, record) => {
                    return (
                        <div className={'tableAction'}>
                            <React.Fragment>
                                <Tooltip title='Assign Marketing User'>
                                    <button
                                        className={'btn'}
                                        onClick={() => {
                                            this.events.showMarketingDrawer(record)
                                        }}>
                                        <Icon type="solution"/>
                                    </button>
                                </Tooltip>
                            </React.Fragment>
                        </div>
                    )
                }
            }
        ]

        const filterBlock = (
            <Row gutter={16} className={'mt10'}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By Country'
                                onChange={(item) => this.events.chooseCountry(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='Country'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.countryName || undefined}>
                            {this.state.allCountries && this.state.allCountries.length ? this.state.allCountries.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By State'
                                onChange={(item) => this.events.chooseState(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder='State'
                                allowClear={true}
                                showSearch={true}
                                value={this.state.stateName || undefined}>
                            {this.state.allStates && this.state.allStates.length ? this.state.allStates.map((item, key) => {
                                return (
                                    <Option value={item.name} key={key}>{item.name}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    {!countryName || countryName == 'India' ?
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By City'
                                    onChange={(item) => this.events.chooseCity(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    placeholder='City'
                                    allowClear={true}
                                    showSearch={true}
                                    value={this.state.cityName || undefined}>
                                {this.state.allCities && this.state.allCities.length ? this.state.allCities.map((item, key) => {
                                    return (
                                        <Option value={item.name} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div> :
                        <div className='search-box-table round ml0'>
                            <input name='Search By City' className='form-control'
                                   onChange={(e) => {
                                       this.setState({cityName: e.target.value})
                                   }}
                                   placeholder='City'
                                   value={this.state.cityName}/>
                            <img src='/dist/img/search.png' alt=''/>

                        </div>}
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Pincode' value={this.state.pincode} onChange={(e) => {
                            this.setState({pincode: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Name' value={this.state.name} onChange={(e) => {
                            this.setState({name: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Company Name' value={this.state.companyName} onChange={(e) => {
                            this.setState({companyName: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Email' value={this.state.email} onChange={(e) => {
                            this.setState({email: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Mobile No' value={this.state.mobile} onChange={(e) => {
                            this.setState({mobile: e.target.value})
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>


                <Col md={8} sm={8} xs={12} lg={4}>
                    <Button onClick={() => this.searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>
        )
        return (
            <div>
                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Agents ({this.state.total})</h5>
                                <div className='search-box-table'>

                                </div>

                                <div className='sort-box-table mark-btn'>

                                </div>
                            </div>

                            <div className='card-body table-responsive'>
                                {filterBlock}
                                <TableComp columns={columns}
                                           pagination={{
                                               defaultPageSize: 10,
                                               pageSizeOptions: ['10', '20', '50']
                                           }}
                                           ref={this.tableRef}
                                           apiRequest={this.apiRequest}
                                  // extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {marketingState.visible ?
                    <UpdateMarketingUser
                        {...marketingState}
                        countryList={countryList}
                        onclose={() => this.events.hideMarketingDrawer()}
                        onSubmit={() => {
                            this.events.hideMarketingDrawer()
                            this.tableRef.current.reload()
                        }}/>
                    : ''}

            </div>
        )

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
