import {notification} from 'antd'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import {customAxios as axios, getToken} from '../../../request'
import {
    calcAreaDistanceUrl,
    calcDistanceUrl, expense_exportUrl, expenseAmountRecordUrl, expenseMonthWiseReportUrl,
    expenseUrl, expenseWithdrawalCanadaUrl,
    expenseWithdrawalUrl, getCitiesUrl, getPlaceUrl, getStatesUrl,
    updateExpenseUrl,
    updateExpenseWithdrawalUrl
} from '../api/dailyExpense'

export const addExpense = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(expenseUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
export const calcDistance = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(calcDistanceUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const listDailyExpense = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(expenseUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}
export const expenseWithdrawal = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(expenseWithdrawalUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}
export const updateStatusExpense = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateExpenseUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
export const updateExpenseWithdrawal = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(
        updateExpenseWithdrawalUrl(),
        valData,
        getToken()
    )
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    } else {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const expenseAmountRecordFxn = async (valData = {}) => {
    let {data} = await axios.post(expenseAmountRecordUrl(), valData, getToken())
    return data
}

export const expenseMonthWiseReportFxn = (valData = {}) => async (dispatch = {}) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(expenseMonthWiseReportUrl(), {}, getToken())
    dispatch(hidePageLoad())
    return data
}
export const expense_exportFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let {data} = await axios.post(expense_exportUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const getStatesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getStatesUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const getCitiesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getCitiesUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const getPlaceFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getPlaceUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const calcAreaDistance = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(calcAreaDistanceUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const expenseWithdrawalCanadaFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: { ...filters },
        ...(await getToken())
    }
    let { data } = await axios.get(expenseWithdrawalCanadaUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}
